import React, { Component } from 'react';
import TripTransportationNewItem from './NewTransportationDetails/TripTransportationNewItem';
import TripTransportationAreaItemMenu from './TripTransportationAreaItemMenu';
import TripTransportationItemContent from './TripTransportationItemContent';

export default class TripTransportationAreaContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowingTransportationAreaItemMenu: true,
			isViewingItemContent: false,
			isAddingNewItem: false,
			isEditingItem: false,
			itemBeingEdited: null,
			transportationItems: [],
			transportationItem: {},
		};
	}

	goToItem = (transportationItem) => {
		this.setState({
			isViewingItemContent: true,
			isShowingTransportationAreaItemMenu: false,
			isAddingNewItem: false,
			isEditingItem: false,
			transportationItem: transportationItem,
		});
	};

	render() {
		return (
			<div
				className={`addLodgingInfoArea ${
					this.props.showingTransportationAreaContent ? 'visible' : 'hiding'
				}`}
			>
				<TripTransportationAreaItemMenu
					isShowingTransportationAreaItemMenu={
						this.state.isShowingTransportationAreaItemMenu
					}
					transportationItems={this.props.transportationItems}
					goToItem={this.goToItem}
					goToNewItem={(e) =>
						this.setState({
							isShowingTransportationAreaItemMenu: false,
							isAddingNewItem: true,
						})
					}
				></TripTransportationAreaItemMenu>
				{this.state.isViewingItemContent && (
					<TripTransportationItemContent
						token={this.props.token}
						tripData={this.props.tripData}
						adventure={this.props.adventure}
						transportationItem={this.state.transportationItem}
						removeTransportationItems={this.props.removeTransportationItems}
						editTransportationItem={() =>
							this.setState({
								isViewingItemContent: false,
								isEditingItem: true,
							})
						}
						viewItems={() => {
							this.setState({
								isShowingTransportationAreaItemMenu: true,
								isViewingItemContent: false,
							});
						}}
					></TripTransportationItemContent>
				)}
				{this.state.isAddingNewItem && (
					<TripTransportationNewItem
						adventure={this.props.adventure}
						tripData={this.props.tripData}
						token={this.props.token}
						goToItem={this.goToItem}
						addToTransportationItems={this.props.addToTransportationItems}
						goBack={(e) =>
							this.setState({
								isShowingTransportationAreaItemMenu: true,
								isAddingNewItem: false,
							})
						}
						existingItem={null}
					></TripTransportationNewItem>
				)}
				{this.state.isEditingItem && (
					<TripTransportationNewItem
						adventure={this.props.adventure}
						tripData={this.props.tripData}
						token={this.props.token}
						goToItem={this.goToItem}
						removeTransportationItems={this.props.removeTransportationItems}
						addToTransportationItems={this.props.addToTransportationItems}
						goBack={(e) => this.goToItem(this.state.transportationItem)}
						existingItem={this.state.transportationItem}
					></TripTransportationNewItem>
				)}
			</div>
		);
	}
}
