import './TripTransportationArea.css';
import React, { Component } from 'react';
import TripTransportationAreaContent from './TripTransportationAreaContent';

export default class TripTransportArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showingTransportationAreaContent: false,
		};
	}

	render() {
		return (
			<div>
				<div
					className={`TripItemArea ${
						this.state.showingTransportationAreaContent
							? 'TripItemAreaSelected'
							: ''
					}`}
					onClick={(e) => {
						this.setState({
							showingTransportationAreaContent:
								!this.state.showingTransportationAreaContent,
						});
					}}
				>
					<div className="TripItemAreaEmoji">🛫</div>
					<div className="TripItemAreaTxt">Transportation</div>
					<div className="TripItemAreaAction">
						{this.state.showingTransportationAreaContent ? 'Hide' : 'View'}
					</div>
				</div>
				<TripTransportationAreaContent
					adventure={this.props.adventure}
					tripData={this.props.tripData}
					transportationItems={this.props.transportationItems}
					addToTransportationItems={this.props.addToTransportationItems}
					removeTransportationItems={this.props.removeTransportationItems}
					token={this.props.token}
					showingTransportationAreaContent={
						this.state.showingTransportationAreaContent
					}
				></TripTransportationAreaContent>
			</div>
		);
	}
}
