import { Grow } from '@mui/material';
import React, { Component } from 'react';
import Question from './../../../TripPlanning/SmallerComponents/Question';

export default class TripLodgingAreaNewItemType extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.lodgingTypes = {
			0: {
				id: 0,
				emoji: '🏨',
				name: 'Hotel',
			},
			1: {
				id: 1,
				emoji: '🛏️',
				name: 'Hostel',
			},
			2: {
				id: 2,
				emoji: '🏡',
				name: 'Airbnb',
			},
			3: {
				id: 3,
				emoji: '👫',
				name: 'Friend',
			},
		};
	}

	render() {
		return (
			<Grow in={this.props.isSelectingLodgingType}>
				<div>
					<br />
					<div
						className={`lodgingQuestion ${
							this.state.shakeLodgingCategorySelection ? 'shake' : ''
						}`}
					>
						<Question
							questionData={{
								id: 1,
								type: 'singleChoice',
								question: '',
								default: this.props.currentType,
								options: Object.values(this.lodgingTypes).map((type) => ({
									id: type.id,
									option: `${type.emoji} ${type.name}`,
								})),
							}}
							updateQuestionAnswerFunc={(e, answerOptions) => {
								for (let key in answerOptions) {
									if (answerOptions.hasOwnProperty(key) && answerOptions[key]) {
										this.setState({ lodgingCategory: this.lodgingTypes[key] });
										this.props.selectLodgingType(this.lodgingTypes[key]);
										return;
									}
									this.setState({ lodgingCategory: null });
								}
							}}
						></Question>
					</div>
				</div>
			</Grow>
		);
	}
}
