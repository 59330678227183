import React, { Component } from 'react';

class TypingEffect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			textToDisplay: '',
			boldReached: false,
		};
		//     this.props.normalText =
		//       ' Ever felt the thrill of a spontaneous road trip, the delight of a hidden café, or the awe atop an unknown peak? ';
		//     this.props.boldText = "  That's the essence of gallivanting!";
		this.fullText = this.props.normalText + this.props.boldText;
		this.index = 0;
		this.interval = null;
		this.pauseDuration = 1200;
	}

	componentDidMount() {
		this.interval = setInterval(this.typeCharacter, 25); // Adjust the number for typing speed
	}

	typeCharacter = () => {
		if (this.index < this.fullText.length) {
			// If we've just finished typing the firstText, introduce a pause before starting the boldText
			if (this.index === this.props.normalText.length) {
				clearInterval(this.interval);
				setTimeout(() => {
					this.interval = setInterval(this.typeCharacter, 25);
				}, this.pauseDuration);
			}

			this.setState((prevState) => ({
				textToDisplay:
					prevState.textToDisplay + this.fullText.charAt(this.index),
				boldReached: this.index >= this.props.normalText.length,
			}));

			this.index++;
		} else {
			clearInterval(this.interval);
			if (this.props.onComplete) {
				this.props.onComplete();
			}
		}
	};

	componentWillUnmount() {
		// Clean up the interval when the component is unmounted
		clearInterval(this.interval);
	}

	render() {
		const boldContent = this.state.boldReached
			? this.state.textToDisplay.substring(this.props.normalText.length)
			: '';

		return (
			<div className="typed-output">
				{this.state.textToDisplay.substring(0, this.props.normalText.length)}
				<b style={{ color: 'var(--accentColorLight)' }}>{boldContent}</b>
			</div>
		);
	}
}

export default TypingEffect;
