import { ArrowBackIos } from '@mui/icons-material';
import React, { Component } from 'react';
import ItineraryDailyDisplay from './DailyDisplay/ItineraryDailyDisplay';
import NewActivity from './NewActivity/NewActivity';

export default class ItineraryDayItems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddingNewItem: false,
			isModifyingActivity: false,
			options: [],
			activityBeingModified: {},
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selected == null && this.props.selected != null) {
			this.setState({ isAddingNewItem: false, isModifyingActivity: false });
		}
	}

	getDaySelectOptions = () => {
		return this.props.dayOptions.map((option) => (
			<option key={option.dayKey} value={this.props.selected}>
				{option.dayString}
			</option>
		));
	};

	modifyActivity = (activity) => {
		this.setState({
			activityBeingModified: {
				activity: activity,
				itineraryDate: this.state.selected,
			},
			isModifyingActivity: true,
		});
	};

	render() {
		let options = this.props.dayOptions.map((option) => (
			<option key={option.dayKey} value={option.dayKey}>
				{option.dayString}
			</option>
		));

		return (
			<div
				className={`ItineraryDay ${
					this.props.selected == null ? '' : 'selected'
				}`}
			>
				<div className="ItineraryDayContent">
					<div
						className="ItineraryDayContentHeader"
						onClick={() => {
							if (this.state.isAddingNewItem) {
								this.setState({
									isAddingNewItem: false,
								});
							}
						}}
					>
						{this.state.isAddingNewItem || this.state.isModifyingActivity ? (
							<ArrowBackIos
								fontSize="x-small"
								onClick={() => {
									if (this.state.isModifyingActivity) {
										this.setState({
											isModifyingActivity: false,
										});
									}
								}}
							></ArrowBackIos>
						) : (
							<div></div>
						)}
						{this.state.isModifyingActivity ? (
							<div className="modifyDaySelect">
								<select
									defaultValue={this.props.selected}
									onChange={(e) =>
										this.props.selectDayNoContentToggle(e.target.value)
									}
								>
									{options}
								</select>
							</div>
						) : (
							<div>{this.props.dateStr}</div>
						)}
					</div>
					<hr />
					<ItineraryDailyDisplay
						dateStr={this.props.dateStr}
						selected={this.props.selected}
						itinerary={this.props.itinerary}
						shouldShowItinerary={
							!this.state.isAddingNewItem && !this.state.isModifyingActivity
						}
						addNewItemBtn={(e) => {
							this.setState({ isAddingNewItem: true });
						}}
						removeFromItinerary={this.props.removeFromItinerary}
						modifyActivity={this.modifyActivity}
						updateDaysSelectedItem={this.props.updateDaysSelectedItem}
						dayToSelectedItem={this.props.dayToSelectedItem}
					></ItineraryDailyDisplay>
					{this.state.isAddingNewItem && (
						<NewActivity
							isAddingNewItem={this.state.isAddingNewItem}
							token={this.props.token}
							adventure={this.props.adventure}
							dateStr={this.props.dateStr}
							selected={this.props.selected}
							backToItems={(e) => {
								this.setState({ isAddingNewItem: false });
							}}
							tripData={this.props.tripData}
							addToItinerary={this.props.addToItinerary}
						></NewActivity>
					)}
					{this.state.isModifyingActivity && (
						<NewActivity
							isAddingNewItem={this.state.isModifyingActivity}
							token={this.props.token}
							adventure={this.props.adventure}
							dateStr={this.props.dateStr}
							selected={this.props.selected}
							backToItems={(e) => {
								this.setState({ isModifyingActivity: false });
							}}
							tripData={this.props.tripData}
							softRemoveFromItinerary={this.props.softRemoveFromItinerary}
							addToItinerary={this.props.addToItinerary}
							removeFromItinerary={this.props.removeFromItinerary}
							existingItem={this.state.activityBeingModified.activity}
						></NewActivity>
					)}
				</div>
			</div>
		);
	}
}
