import React, { Component } from 'react';
import AddressAutocompleteItem from './AddressAutocompleteItem';

class AddressAutocomplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			name: '',
			suggestions: [],
			timeout: null,
			selectedSuggestion: null,
		};
	}

	autocompleteAddress = (event) => {
		const formData = new FormData();
		formData.append('address', event.target.value);

		fetch('https://api.gallivibe.com/autocompleteAddress', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.suggestions) {
					this.setState({
						selectedSuggestion: null,
						suggestions: data.suggestions,
					});
				} else {
					this.setState({ selectedSuggestion: null, suggestions: [] });
				}
			})
			.catch((error) => {
				console.error('Error occurred:', error);
				this.setState({ selectedSuggestion: null, suggestions: [] });
			});
	};

	handleOnKeyUp = (event) => {
		clearTimeout(this.state.timeout);
		this.props.updateAddress(event.target.value, '');
		this.setState({
			// address: event.target.value,
			timeout: setTimeout(() => {
				this.autocompleteAddress(event);
			}, 500),
		});
	};

	selectSuggestion = (selectSuggestionID, selectedAddress, name) => {
		if (selectSuggestionID == this.state.selectedSuggestion) {
			this.setState({ selectedSuggestion: null, name: '' });
		} else {
			this.props.updateAddress(selectedAddress, name);
			this.setState({
				selectedSuggestion: selectSuggestionID,
				suggestions: [
					{ id: selectSuggestionID, address: selectedAddress, name: name },
				],
				// address: selectedAddress,
				// name: name,
			});
		}
	};

	render() {
		return (
			<div className="locationSearch">
				<input
					type="text"
					value={this.props.address}
					onChange={this.handleOnKeyUp}
					placeholder={
						this.props.placeholder ? this.props.placeholder : 'Address...'
					}
					className="mainTxtInput"
					style={{
						textAlign: 'left',
						fontSize: 'medium',
						fontWeight: 'normal',
						marginBottom: '10px',
					}}
				/>

				{this.state.suggestions.length > 0 && (
					<div className="locationSearchResults">
						{this.state.suggestions.map((suggestion, index) => {
							return (
								<AddressAutocompleteItem
									itemIndex={index}
									suggestionId={suggestion.id}
									address={suggestion.address}
									key={suggestion.id}
									name={suggestion.name}
									isSelected={this.state.selectedSuggestion == suggestion.id}
									selectSuggestion={this.selectSuggestion}
								></AddressAutocompleteItem>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default AddressAutocomplete;
