import React, { Component } from 'react';

export default class ItineraryDailyDisplayLeftColumn extends Component {
	render() {
		return (
			<div className="leftColumn">
				<div className="ItineraryDayItem" onClick={this.props.addNewItemBtn}>
					{` + `}
				</div>
				{this.props.displayItems.map((item, index) => (
					<div
						className={`ItineraryDayItem ${
							this.props.viewingItem === index ||
							(index == 0 && this.props.viewingItem === null)
								? 'selectedActivity'
								: ''
						}`}
						key={item.id}
						onClick={(e) => {
							this.props.setViewingItem(index);
						}}
					>
						<div className="ItineraryDayItemEmoji">{item.activity.emoji}</div>
						<div className="ItineraryDayItemTime">{item.start_time}</div>
						<div className="ItineraryDayItemEditBtn"></div>
					</div>
				))}
			</div>
		);
	}
}
