import React, { Component } from 'react';
import NewAdventureDialogue from './NewAdventureDialogue';
import SelectLocation from './SelectLocation';
import SelectTravelDates from './SelectTravelDates';

export default class PlanTrip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromTravelDate: {},
			toTravelDate: {},
			questionnaireLoaded: false,
			questionnaire: [],

			isReadingDialogue: false,
			isPickingBasicDetails: false,
			isSelectingDestination: true,
			isSelectingTravelDates: false,

			destinationName: '',
			destinationEmoji: '',
		};
	}

	setDestination = (destinationName, emoji) => {
		this.setState({
			destinationName: destinationName,
			destinationEmoji: emoji,
		});
		this.goToSelectTravelDates();
	};

	goToSelectTravelDates = () => {
		this.setState({
			questionnaireLoaded: false,
			isSelectingDestination: false,
			isSelectingTravelDates: true,
		});
	};

	goToSelectDestination = () => {
		this.setState({
			isSelectingDestination: true,
			isSelectingTravelDates: false,
		});
	};

	setFromTravelDate = (travelDate) => {
		this.setState({ fromTravelDate: travelDate });
	};
	setToTravelDate = (travelDate) => {
		this.setState({ toTravelDate: travelDate });
	};

	goToShowTrip = (tripData) => {
		this.props.goToShowTrip(tripData);
	};

	render() {
		return (
			<div style={{ display: this.props.isPlanningTrip ? 'block' : 'none' }}>
				{this.state.isReadingDialogue && (
					<NewAdventureDialogue
						themeMode={this.props.themeMode}
						goNext={() =>
							this.setState({
								isReadingDialogue: false,
								isSelectingDestination: true,
							})
						}
						goToMainMenu={this.props.goToMainMenu}
						isReadingDialogue={this.state.isReadingDialogue}
					></NewAdventureDialogue>
				)}
				{this.state.isSelectingDestination && (
					<SelectLocation
						adventure={this.props.adventure}
						token={this.props.token}
						isSelectingDestination={this.state.isSelectingDestination}
						setDestination={this.setDestination}
						goBack={this.props.goBack}
					></SelectLocation>
				)}
				<SelectTravelDates
					token={this.props.token}
					adventure={this.props.adventure}
					isSelectingTravelDates={this.state.isSelectingTravelDates}
					destinationName={this.state.destinationName}
					destinationEmoji={this.state.destinationEmoji}
					goToSelectDestination={this.goToSelectDestination}
					setFromTravelDate={this.setFromTravelDate}
					setToTravelDate={this.setToTravelDate}
					goToShowTrip={this.goToShowTrip}
				></SelectTravelDates>
			</div>
		);
	}
}
