import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { Grow } from '@mui/material';

export default class SigningIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Input Values
			password: '',

			// Actions
			shakeInputs: false,
		};
	}

	handleSignIn = () => {
		const { password } = this.state;
		const formData = new FormData();
		formData.append('phoneNumber', this.props.phoneNumber);
		formData.append('password', password);

		fetch(`https://api.gallivibe.com/login`, { method: 'POST', body: formData })
			.then((response) => response.json())
			.then((data) => {
				if (data.ok == true) {
					if (data.status == 'invalid_credentials') {
						this.props.updateMessage(
							'😧 Invalid credentials, please try again',
						);

						this.setState({ shakeInputs: true, password: '' });
						setTimeout(
							function () {
								this.setState({ shakeInputs: false });
							}.bind(this),
							500,
						);
					}

					if (data.status == 'logged_in') {
						this.setState({ password: '' });
						this.props.updateUserObject(data.user, data.current_adventures);
						Cookies.set('token', data.token, { expires: 2 });
						this.props.updateAuthenticatedStatusFunc(true, data.token);
					}
				} else {
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		return (
			<Grow in={this.props.isSigningIn}>
				<div style={{ display: this.props.isSigningIn ? 'block' : 'none' }}>
					<input
						placeholder="Phone number..."
						type="tel"
						autoComplete="off"
						className={`mainTxtInput ${this.state.shakeInputs ? 'shake' : ''}`}
						onChange={(event) =>
							this.props.updatePhoneNumber(event.target.value)
						}
						value={this.props.phoneNumber}
						style={{ marginBottom: '10px' }}
						autoComplete="username"
					></input>
					<input
						placeholder="Password..."
						type="password"
						autoComplete="off"
						className={`mainTxtInput ${this.state.shakeInputs ? 'shake' : ''}`}
						value={this.state.password}
						onChange={(event) =>
							this.setState({ password: event.target.value })
						}
						onKeyDown={(event) => {
							if (event.key === 'Enter' && this.state.password !== '')
								this.handleSignIn();
						}}
						autoComplete="current-password"
						autoFocus
					></input>
					<button className="mainSubmitBtn" onClick={this.handleSignIn}>
						Sign In
					</button>
				</div>
			</Grow>
		);
	}
}
