import React, { Component } from 'react';
import { fromFulltimestampToDate } from '../../../../utils';
import ItineraryDailyDisplayLeftColumn from './ItineraryDailyDisplayLeftColumn';
import ItineraryDailyDisplayRightColumn from './ItineraryDailyDisplayRightColumn';
import { Grow } from '@mui/material';

export default class ItineraryDailyDisplay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lastChecked: null,
			dayItems: [],
			viewingItem: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (
			(this.props.selected !== null &&
				prevProps.selected != this.props.selected) ||
			prevProps.shouldShowItinerary != this.props.shouldShowItinerary
		) {
			this.updateItems();
		}
	}

	updateItems = () => {
		let thisDate = fromFulltimestampToDate(this.props.selected);
		let items = this.props.itinerary.hasOwnProperty(thisDate)
			? this.props.itinerary[thisDate]
			: [];

		let itemsWithKeys = items.map((item, index) => ({
			...item,
			key: index,
		}));

		this.setState({
			dayItems: itemsWithKeys,
			viewingItem:
				typeof this.props.dayToSelectedItem[thisDate] == 'undefined'
					? 0
					: this.props.dayToSelectedItem[thisDate],
		});
	};

	removeFromItinerary = (date, item, hardDelete = false) => {
		this.props.updateDaysSelectedItem(
			date,
			item.key > 0 ? item.key - 1 : 0,
			() => {
				this.props.removeFromItinerary(date, item, hardDelete, () => {
					this.updateItems();
				});
			},
		);
	};

	render() {
		let thisDate = fromFulltimestampToDate(this.props.selected);
		return (
			<Grow in={this.props.shouldShowItinerary}>
				<div
					className="ItineraryDayDetailContent"
					style={{ display: this.props.shouldShowItinerary ? 'flex' : 'none' }}
				>
					<ItineraryDailyDisplayLeftColumn
						displayItems={this.state.dayItems}
						addNewItemBtn={this.props.addNewItemBtn}
						setViewingItem={(item) => {
							this.props.updateDaysSelectedItem(thisDate, item);
							this.setState({ viewingItem: item });
						}}
						viewingItem={this.state.viewingItem}
					></ItineraryDailyDisplayLeftColumn>
					<ItineraryDailyDisplayRightColumn
						dayItems={this.state.dayItems}
						viewingItem={this.state.viewingItem}
						selected={this.props.selected}
						removeFromItinerary={this.removeFromItinerary}
						modifyActivity={this.props.modifyActivity}
					></ItineraryDailyDisplayRightColumn>
				</div>
			</Grow>
		);
	}
}
