import { Grow, CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ArrowForwardIos } from '@mui/icons-material';
import { Add } from '@mui/icons-material';

export default class AdventureViewCheckInHistory extends Component {
	groupCheckInsByDate = (checkIns) => {
		if (checkIns === null) {
			return null;
		}

		return checkIns.reduce((acc, checkIn) => {
			// Extract the date part from the 'date_create' property
			const date = new Date(checkIn.date_create).toDateString(); // Converts to a string with format "Weekday, Month Day, Year"
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(checkIn);
			return acc;
		}, {});
	};

	render() {
		let groupedCheckIns = this.groupCheckInsByDate(this.props.checkInHistory);
		let adventure = this.props.adventure;

		return (
			<Grow in={this.props.isViewingCheckInHistory}>
				<div
					className="adventureMainPage"
					style={{
						display: this.props.isViewingCheckInHistory ? 'block' : 'none',
					}}
				>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
						</div>
						<div className="userAreaTitle">
							<div className="tripHeader">
								<div className="tripAdventureTitle" onClick={this.props.goBack}>
									{adventure.emoji} {adventure.title}
								</div>
								<div>
									<ArrowForwardIos
										style={{
											fontSize: 'small',
										}}
									></ArrowForwardIos>
								</div>
								<div className="tripHeaderLocation">✍️ Check In History</div>
							</div>
						</div>
						<div></div>
					</div>
					<hr />
					<div className="userAreaContent">
						{this.props.checkInHistory === null ||
						this.props.checkInHistory.length === 0 ? (
							<div
								style={{
									marginTop: '70px',
									marginBottom: '70px',
									textAlign: 'center',
								}}
							>
								{this.props.checkInHistory === null ? (
									<CircularProgress></CircularProgress>
								) : (
									<div>When you add check-ins, they'll show up here!</div>
								)}
							</div>
						) : (
							<div style={{ marginTop: '5px', marginBottom: '25px' }}>
								{Object.entries(groupedCheckIns).map(
									([date, checkInsArray]) => (
										<Grow in={true} key={date}>
											<div className="checkInDateBlock">
												<div className="groupedCheckInDate" key={date}>
													~ {date} ~
												</div>
												<div>
													{checkInsArray.map((checkIn, index) => (
														<Grow
															in={true}
															timeout={
																300 + index * 250 < 1000
																	? 300 + index * 250
																	: 1000
															}
															key={index}
														>
															<div className="adventureCheckIn">
																<div className="checkInHeader">
																	{new Date(
																		checkIn.date_create,
																	).toLocaleTimeString('en-US', {
																		hour: '2-digit',
																		minute: '2-digit',
																		hour12: true,
																	})}
																</div>
																<div className="checkInNote">
																	{checkIn.note}
																</div>
															</div>
														</Grow>
													))}
												</div>
											</div>
										</Grow>
									),
								)}
							</div>
						)}
					</div>
					{this.props.checkInHistory !== null && (
						<Grow in={true}>
							<div
								className={`mainBtn ${
									this.props.checkInHistory.length >= 1 ? '' : 'addLocationBtn'
								}`}
								onClick={this.props.goToAdventureCheckIn}
							>
								<Add></Add> Create New Check-In
							</div>
						</Grow>
					)}

					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
						{adventure.emoji} {adventure.title}
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
