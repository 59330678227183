import './TripLodgingArea.css';
import React, { Component } from 'react';
import TripLodgingAreaContent from './TripLodgingAreaContent';

export default class TripLodgingArea extends Component {
	constructor(props) {
		super(props);
		this.newLodgingItemRef = React.createRef();

		this.defaultEmoji = '🏠';

		this.state = {
			showingAddLodging: false,
			emoji: this.defaultEmoji,
			lodgingItems: [],
		};
	}

	setLodgingEmoji = (newEmoji) => {
		this.setState({ emoji: newEmoji === null ? this.defaultEmoji : newEmoji });
	};

	render() {
		return (
			<div>
				<div
					className={`TripItemArea ${
						this.state.showingAddLodging ? 'TripItemAreaSelected' : ''
					}`}
					onClick={(e) =>
						this.setState({ showingAddLodging: !this.state.showingAddLodging })
					}
				>
					<div className="TripItemAreaEmoji">{this.state.emoji}</div>
					<div className="TripItemAreaTxt">Lodging Info</div>
					<div className="TripItemAreaAction">
						{this.state.showingAddLodging ? 'Hide' : 'View'}
					</div>
				</div>
				<TripLodgingAreaContent
					token={this.props.token}
					adventure={this.props.adventure}
					showingAddLodging={this.state.showingAddLodging}
					tripData={this.props.tripData}
					setLodgingEmoji={this.setLodgingEmoji}
					lodgingItems={this.props.lodgingItems}
					addToLodgingItems={this.props.addToLodgingItems}
					removeFromLodgingItems={this.props.removeFromLodgingItems}
				></TripLodgingAreaContent>
			</div>
		);
	}
}
