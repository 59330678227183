import React, { Component } from 'react';
import './PlanAdventure.css';
import PlanAdventureEmojiAndName from './PlanAdventureEmojiAndName';
import PlanAdventureSelectDates from './PlanAdventureSelectDates';

export default class PlanAdventure extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSelectingDates: false,
			adventureEmoji: '',
			adventureTitle: '',
		};
	}

	render() {
		return (
			<div>
				<PlanAdventureEmojiAndName
					shouldShowSelectEmojiAndName={!this.state.isSelectingDates}
					goToSelectDates={(emoji, adventureTitle) => {
						this.setState({
							isSelectingDates: true,
							adventureEmoji: emoji,
							adventureTitle: adventureTitle,
						});
					}}
					goBack={this.props.goBack}
				></PlanAdventureEmojiAndName>
				<PlanAdventureSelectDates
					token={this.props.token}
					shouldShowSelectDates={this.state.isSelectingDates}
					adventureEmoji={this.state.adventureEmoji}
					adventureTitle={this.state.adventureTitle}
					goToShowAdventure={this.props.goToShowAdventure}
					goBack={() => this.setState({ isSelectingDates: false })}
				></PlanAdventureSelectDates>
			</div>
		);
	}
}
