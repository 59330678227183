import { ArrowForwardIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import {
	transformDateFormat,
	transportationCategories,
} from './../../../utils';

export default class TripTransportationAreaItemMenu extends Component {
	transportationTypeToEmoji = (transportationType) => {
		return transportationCategories[transportationType].emoji;
	};

	render() {
		return (
			<Grow in={this.props.isShowingTransportationAreaItemMenu}>
				<div
					className="tripLodgingAreaItemMenu"
					style={{
						display: this.props.isShowingTransportationAreaItemMenu
							? 'block'
							: 'none',
					}}
				>
					<br />

					<div>
						{this.props.transportationItems.length > 0 ? (
							this.props.transportationItems.map(
								(transportationItem, index) => {
									return (
										<div
											className="scheduledTripsItem"
											onClick={(e) => this.props.goToItem(transportationItem)}
											key={index}
										>
											<div className="scheduledTripsItemEmoji">
												{this.transportationTypeToEmoji(
													transportationItem.type,
												)}
											</div>
											<div className="scheduledTripsItemText">
												<div className="scheduledTripsItemDestination">
													{transportationItem.name.length > 0
														? transportationItem.name
														: transportationCategories[transportationItem.type]
																.name + ' Ride'}
												</div>
												<div className="scheduledTripsItemDates">
													<b>{transportationItem.departure_details.time}</b>
													{' on '}
													{transformDateFormat(
														transportationItem.departure_details.date,
													)}
												</div>
											</div>
											<div></div>
											<div></div>
											<div style={{ textAlign: 'right' }}>
												<ArrowForwardIos fontSize="8px"></ArrowForwardIos>
											</div>
										</div>
									);
								},
							)
						) : (
							<div>
								<br />
								<br />
								<center>No transportation added yet 😔</center>
								<br />
								<br />
							</div>
						)}
					</div>
					<div className="mainBtn" onClick={this.props.goToNewItem}>
						Add Transportation
					</div>
				</div>
			</Grow>
		);
	}
}
