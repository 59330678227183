import React, { Component } from 'react';
import DateSelector from './../../../TripPlanning/SmallerComponents/DateSelector';
import {
	slashesDateFormatToObject,
	transformDateFormat,
} from '../../../../utils';
import { Grow } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default class TripLodgingAreaNewItemDateSelection extends Component {
	constructor(props) {
		super(props);

		const currentDate = new Date();
		this.defaultState = {
			tripFrom: { day: null, month: null, year: null },
			tripTo: { day: null, month: null, year: null },
			lodgingFrom: {
				day: currentDate.getDate().toString().padStart(2, '0'),
				month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
				year: currentDate.getFullYear(),
			},
			lodgingTo: {
				day: currentDate.getDate().toString().padStart(2, '0'),
				month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
				year: currentDate.getFullYear(),
			},
		};

		this.state = this.defaultState;
	}

	componentDidMount() {
		let fromDate = slashesDateFormatToObject(
			transformDateFormat(this.props.tripData.from_date),
		);
		let toDate = slashesDateFormatToObject(
			transformDateFormat(this.props.tripData.to_date),
		);

		if (this.props.lodgingFrom && this.props.lodgingTo) {
			this.setState({
				tripFrom: fromDate,
				tripTo: toDate,
				lodgingFrom: slashesDateFormatToObject(this.props.lodgingFrom),
				lodgingTo: slashesDateFormatToObject(this.props.lodgingTo),
			});
		} else {
			this.setState({
				lodgingFrom: fromDate,
				lodgingTo: toDate,
			});
		}
	}

	updateFromDate = (day, month, year) => {
		this.setState({ lodgingFrom: { day: day, month: month, year: year } }, () =>
			this.props.selectDates(
				`${this.state.lodgingFrom.month}/${this.state.lodgingFrom.day}/${this.state.lodgingFrom.year}`,
				`${this.state.lodgingTo.month}/${this.state.lodgingTo.day}/${this.state.lodgingTo.year}`,
			),
		);
	};
	updateToDate = (day, month, year) => {
		this.setState(
			{
				lodgingTo: { day: day, month: month, year: year },
			},
			() => {
				this.props.selectDates(
					`${this.state.lodgingFrom.month}/${this.state.lodgingFrom.day}/${this.state.lodgingFrom.year}`,
					`${this.state.lodgingTo.month}/${this.state.lodgingTo.day}/${this.state.lodgingTo.year}`,
				);
			},
		);
	};

	render() {
		let lodgingFrom = this.state.lodgingFrom;
		let lodgingTo = this.state.lodgingTo;

		return (
			<Grow in={this.props.isSelectingDates}>
				<div>
					<br />
					<br />
					<div className="fromAndToDateSelector">
						<DateSelector
							type=" From "
							year={this.state.lodgingFrom.year}
							month={this.state.lodgingFrom.month}
							day={this.state.lodgingFrom.day}
							updateTheDate={this.updateFromDate}
							minDate={`${this.state.tripFrom['year']}-${this.state.tripFrom['month']}-${this.state.tripFrom['day']}`}
							maxDate={`${this.state.lodgingTo['year']}-${this.state.lodgingTo['month']}-${this.state.lodgingTo['day']}`}
						></DateSelector>
						 
						<div className="fromToArrowContainer">
							<ArrowForwardIcon></ArrowForwardIcon>
						</div>
						<DateSelector
							type=" To "
							year={this.state.lodgingTo.year}
							month={this.state.lodgingTo.month}
							day={this.state.lodgingTo.day}
							updateTheDate={this.updateToDate}
							minDate={`${this.state.lodgingFrom['year']}-${this.state.lodgingFrom['month']}-${this.state.lodgingFrom['day']}`}
							maxDate={`${this.state.tripTo['year']}-${this.state.tripTo['month']}-${this.state.tripTo['day']}`}
						></DateSelector>
					</div>
					<br />
				</div>
			</Grow>
		);
	}
}
