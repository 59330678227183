import { Grow, LinearProgress } from '@mui/material';
import React, { Component } from 'react';
import axios from 'axios';
import LinearProgressWithLabel from './../../../TripPlanning/SmallerComponents/LinearProgressWithLabel';
import ImageCompressor from 'image-compressor.js';

export default class TripTransportationAreaLoadingFromScreenshot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: null,
			isProcessingImage: false,
			isUploadingImage: false,
			isComplete: false,
			response: '',
			uploadProgress: 0,
		};
	}

	handleProcessedResponse = (response) => {
		// Define the required fields for each flight object
		const requiredFields = [
			'arrival_address',
			'arrival_date',
			'arrival_time',
			'comment',
			'confirmation_code',
			'departure_address',
			'departure_date',
			'departure_time',
			'title',
		];

		let correctFlights = [];
		let brokenFlights = [];

		// Check if the response has an 'ok' field and it is true
		if (response.ok) {
			response.flights.forEach((flight) => {
				// Check if the flight object has all the required fields
				const isFlightValid = requiredFields.every((field) => field in flight);

				// If valid, add to correctFlights, otherwise add to brokenFlights
				if (isFlightValid) {
					correctFlights.push(flight);
				} else {
					brokenFlights.push(flight);
				}
			});

			correctFlights.forEach((flight) => {
				// Extract the details from the flight object
				const departureDetails = {
					date: {
						year: flight.departure_date.split('-')[0],
						month: flight.departure_date.split('-')[1],
						day: flight.departure_date.split('-')[2],
					},
					time: flight.departure_time, // Assume this is in the desired format
					address: flight.departure_address,
				};

				const arrivalDetails = {
					date: {
						year: flight.arrival_date.split('-')[0],
						month: flight.arrival_date.split('-')[1],
						day: flight.arrival_date.split('-')[2],
					},
					time: flight.arrival_time, // Assume this is in the desired format
					address: flight.arrival_address,
				};

				// Construct parameters for saveTransportationItem function
				const transportationType = 0;
				const confirmationCode = flight.confirmation_code;
				const name = flight.title;
				const comments = flight.comment;

				// Call the saveTransportationItem function with shouldGoToItem as false
				this.props.saveTransportationItem(
					departureDetails,
					arrivalDetails,
					transportationType,
					confirmationCode,
					name,
					comments,
					false, // shouldGoToItem is always false
				);
			});
			this.props.goToItems();
		} else {
			console.error('Response is not okay.');
		}

		// Return an object containing the arrays of correct and broken flights
		return { correctFlights, brokenFlights };
	};

	handleImageChange = (e) => {
		this.setState({ image: e.target.files[0] });
	};

	handleUpload = () => {
		// Set state to indicate uploading has started
		this.setState({
			isUploadingImage: true,
			isProcessingImage: false,
			uploadProgress: 0,
		});

		// Create a new instance of the image compressor
		new ImageCompressor(this.state.image, {
			quality: 0.6, // Adjust this value for desired quality, 0.6 is 60% quality
			success: (compressedResult) => {
				const formData = new FormData();
				formData.append('image', compressedResult); // Upload compressed image
				formData.append('token', this.props.token);
				formData.append('adventure_id', this.props.adventure.id);
				formData.append('trip_id', this.props.tripData.id);

				axios
					.post('https://api.gallivibe.com/analyze-image-flight', formData, {
						onUploadProgress: (progressEvent) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total,
							);
							if (percentCompleted === 100) {
								this.setState({
									uploadProgress: percentCompleted,
									isProcessingImage: true,
								});
							} else {
								this.setState({ uploadProgress: percentCompleted });
							}
						},
					})
					.then((response) => {
						this.handleProcessedResponse(response.data);
						this.setState({
							isUploadingImage: false,
							isProcessingImage: false,
							isComplete: true,
							response: JSON.stringify(response.data),
						});
					})
					.catch((error) => {
						console.error('Error uploading image:', error);
						this.setState({
							isUploadingImage: false,
							isProcessingImage: false,
						});
					});
			},
			error(err) {
				console.error('Error during compression:', err.message);
				this.setState({ isUploadingImage: false, isProcessingImage: false });
			},
		});
	};

	render() {
		return (
			<Grow in={this.props.isLoadingFromScreenshot}>
				<div
					style={{
						display: this.props.isLoadingFromScreenshot ? 'block' : 'none',
					}}
				>
					<center>
						<br />
						<div
							style={{
								display:
									!this.state.isProcessingImage &&
									!this.state.isUploadingImage &&
									!this.state.isComplete
										? 'block'
										: 'none',
							}}
						>
							Upload a screenshot of your flight information
							<br />
						</div>

						<br />
						<div>
							{this.state.isUploadingImage && !this.state.isProcessingImage && (
								<div style={{ width: '90%' }}>
									Uploading ...
									<br />
									<br />
									<LinearProgressWithLabel value={this.state.uploadProgress} />
									<br />
									<br />
								</div>
							)}
							{this.state.isProcessingImage && (
								<div style={{ width: '90%' }}>
									Processing ...
									<br />
									<br />
									<LinearProgress
										sx={{
											backgroundColor: 'var(--lowkeyBtnColorLight)',
											'& .MuiLinearProgress-bar': {
												backgroundColor: 'var(--accentColorLight)',
											},
										}}
									/>
									<br />
									<br />
									<br />
								</div>
							)}
							{this.state.isComplete && (
								<div>One second...{/*this.state.response*/}</div>
							)}
							<div
								style={{
									display:
										this.state.isUploadingImage ||
										this.state.isProcessingImage ||
										this.state.isComplete
											? 'none'
											: 'block',
								}}
							>
								<input
									type="file"
									onChange={this.handleImageChange}
									className="fileInput"
								/>
								<br />
								<br />
								<div style={{ fontSize: 'small' }}>
									Note: After saving, ensure all the information is accurate
								</div>

								<br />
								<button className="mainSubmitBtn" onClick={this.handleUpload}>
									Load from Screenshot
								</button>
							</div>
						</div>
					</center>
				</div>
			</Grow>
		);
	}
}
