import './Gallivibe.css';
import React, { Component } from 'react';
import AuthenticateUserFlow from './AuthenticateUserFlow/AuthenticateUserFlow.js';
import UserArea from './UserArea/UserArea.js';
import Cookies from 'js-cookie';

export default class Gallivibe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isUserAuthenticated: false,
			user: {},
			token: '',
			themeMode: 'light',
			currentAdventures: [],
		};
	}

	toggleTheme = () => {
		if (this.state.themeMode === 'light') {
			this.setDarkTheme();
		} else {
			this.setLightTheme();
		}
	};

	setLightTheme = () => {
		const metaThemeColor = document.querySelector('meta[name="theme-color"]');
		if (metaThemeColor) {
			metaThemeColor.setAttribute('content', '#ffffff');
		}

		document.documentElement.style.setProperty(
			'--rgb-accent',
			'74, 169, 110',
		); /* --accentColorLight */
		document.documentElement.style.setProperty(
			'--rgb-background',
			'255, 255, 255',
		); /* --primaryBackgroundColorLight */
		document.documentElement.style.setProperty(
			'--rgb-input',
			'243, 243, 243',
		); /* --secondaryBackgroundColorLight */
		document.documentElement.style.setProperty(
			'--rgb-color',
			'51, 51, 51',
		); /* --primaryTxtColorLight */

		document.documentElement.style.setProperty(
			'--primaryBackgroundColorLight',
			'#ffffff',
		);
		document.documentElement.style.setProperty(
			'--secondaryBackgroundColorLight',
			'#f3f3f3',
		);
		document.documentElement.style.setProperty('--accentColorLight', '#4aa96e');
		document.documentElement.style.setProperty(
			'--primaryTxtColorLight',
			'#333333',
		);
		document.documentElement.style.setProperty(
			'--secondaryTxtColorLight',
			'#666666',
		);
		document.documentElement.style.setProperty(
			'--HighlightBackgroundLight',
			'#75d2a2',
		);
		document.documentElement.style.setProperty(
			'--lowkeyBtnColorLight',
			'#e0e0e0',
		);
		document.documentElement.style.setProperty(
			'--lowkeyBtnColorHoverLight',
			'#d0d0d0',
		);
		this.setState({ themeMode: 'light' });
	};

	setDarkTheme = () => {
		const metaThemeColor = document.querySelector('meta[name="theme-color"]');
		if (metaThemeColor) {
			metaThemeColor.setAttribute('content', '#1e1e1e');
		}

		document.documentElement.style.setProperty(
			'--rgb-accent',
			'146, 218, 74',
		); /* --accentColorLight */
		document.documentElement.style.setProperty(
			'--rgb-background',
			'30, 30, 30',
		); /* --primaryBackgroundColorLight */
		document.documentElement.style.setProperty(
			'--rgb-input',
			'42, 42, 42',
		); /* --secondaryBackgroundColorLight */
		document.documentElement.style.setProperty(
			'--rgb-color',
			'237, 237, 237',
		); /* --primaryTxtColorLight */

		document.documentElement.style.setProperty(
			'--primaryBackgroundColorLight',
			'#1e1e1e',
		);
		document.documentElement.style.setProperty(
			'--secondaryBackgroundColorLight',
			'#2a2a2a',
		);
		document.documentElement.style.setProperty('--accentColorLight', '#92da4a');
		document.documentElement.style.setProperty(
			'--primaryTxtColorLight',
			'#ededed',
		);
		document.documentElement.style.setProperty(
			'--secondaryTxtColorLight',
			'#b0b0b0',
		);
		document.documentElement.style.setProperty(
			'--HighlightBackgroundLight',
			'#beef75',
		);
		document.documentElement.style.setProperty(
			'--lowkeyBtnColorLight',
			'#3a3a3a',
		);
		document.documentElement.style.setProperty(
			'--lowkeyBtnColorHoverLight',
			'#4a4a4a',
		);
		this.setState({ themeMode: 'dark' });
	};

	componentDidMount() {
		this.checkTokenValidity();
		this.setThemeBasedOnPreference();
		window
			.matchMedia('(prefers-color-scheme: dark)')
			.addEventListener('change', this.handleSystemThemeChange);
	}

	componentWillUnmount() {
		window
			.matchMedia('(prefers-color-scheme: dark)')
			.removeEventListener('change', this.handleSystemThemeChange);
	}

	handleSystemThemeChange = (e) => {
		if (e.matches) {
			this.setDarkTheme();
		} else {
			this.setLightTheme();
		}
	};

	setThemeBasedOnPreference = () => {
		if (
			window.matchMedia &&
			window.matchMedia('(prefers-color-scheme: dark)').matches
		) {
			// The user has set their system to use dark mode
			this.setDarkTheme();
		} else {
			// The user has set their system to use light mode or doesn't specify a preference
			this.setLightTheme();
		}
	};

	checkTokenValidity = () => {
		const token = Cookies.get('token');

		if (!token) {
			return;
		}

		const formData = new FormData();
		formData.append('token', token);

		fetch(`https://api.gallivibe.com/verifyToken`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.is_token_valid) {
					this.setState({
						isUserAuthenticated: true,
						token: token,
					});
					this.updateUserObject(data.user, data.current_adventures);
				} else {
					// Delete the token, it's not valid.
					this.setState({ isUserAuthenticated: false });
				}
			})
			.catch((error) => console.error(error));
	};

	updateAuthenticatedStatus = (newAuthenticatedStatus, token) => {
		this.setState({
			isUserAuthenticated: newAuthenticatedStatus,
			token: token,
		});
	};

	updateUserObject = (userData, currentAdventures) => {
		this.setState({
			user: {
				firstName: userData.first_name,
				lastName: userData.last_name,
				phone: userData.phone,
				email: userData.email,
			},
			currentAdventures: currentAdventures,
		});
	};

	render() {
		return (
			<div
				className="mainAppArea"
				style={{ display: this.props.isDisplayingGallivibe ? 'block' : 'none' }}
			>
				<AuthenticateUserFlow
					isUserAuthenticated={this.state.isUserAuthenticated}
					updateAuthenticatedStatusFunc={this.updateAuthenticatedStatus}
					updateUserObject={this.updateUserObject}
				></AuthenticateUserFlow>
				{this.state.isUserAuthenticated && (
					<UserArea
						themeMode={this.state.themeMode}
						toggleTheme={this.toggleTheme}
						isUserAuthenticated={this.state.isUserAuthenticated}
						updateAuthenticatedStatusFunc={this.updateAuthenticatedStatus}
						user={this.state.user}
						token={this.state.token}
						currentAdventures={this.state.currentAdventures}
					></UserArea>
				)}
			</div>
		);
	}
}
