import { Grow } from '@mui/material';
import React, { Component } from 'react';
import AddressAutocomplete from './../../../AddressAutocomplete';

export default class TripLodgingAreaNewItemAddressSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			name: '',
		};
	}

	render() {
		return (
			<Grow in={this.props.isAddingAddress}>
				<div style={{ display: this.props.isAddingAddress ? 'block' : 'none' }}>
					<br />
					<AddressAutocomplete
						placeholder="Name or address, we'll help you..."
						address={this.props.lodgingAddress}
						name={this.props.lodgingName}
						updateAddress={this.props.selectAddressAndName}
					></AddressAutocomplete>
				</div>
			</Grow>
		);
	}
}
