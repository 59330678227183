import React, { Component } from 'react';

import TripLodgingAreaNewItemType from './TripLodgingAreaNewItemType';
import TripLodgingAreaNewItemDateSelection from './TripLodgingAreaNewItemDateSelection';
import TripLodgingAreaNewItemAddressSelection from './TripLodgingAreaNewItemAddressSelection';
import TripLodgingAreaNewItemConfirmationCodeAndComments from './TripLodgingAreaNewItemConfirmationCodeAndComments';
import { fromFulltimestampToDate } from './../../../../utils';
import { Grow } from '@mui/material';
import TripLodgingAreaLoadFromScreenshot from './TripLodgingAreaLoadFromScreenshot';
import {
	ArrowBackIos,
	CalendarMonth,
	Category,
	Map,
	Note,
} from '@mui/icons-material';

import { transformDateFormat } from './../../../../utils';

export default class TripLodgingAreaNewItem extends Component {
	constructor(props) {
		super(props);

		this.lodgingTypes = {
			0: {
				id: 0,
				emoji: '🏨',
				name: 'Hotel',
			},
			1: {
				id: 1,
				emoji: '🛏️',
				name: 'Hostel',
			},
			2: {
				id: 2,
				emoji: '🏡',
				name: 'Airbnb',
			},
			3: {
				id: 3,
				emoji: '👫',
				name: 'Friend',
			},
		};

		if (this.props.existingItem) {
			let existingItem = this.props.existingItem;

			this.state = {
				isEditing: true,
				isAddingAddress: false,
				isSelectingDates: false,
				isSelectingConfirmationCodeAndData: false,
				isSelectingLodgingType: false,
				isLoadingFromScreenshot: false,

				lodgingType: this.lodgingTypes[existingItem.type],
				lodgingFrom: transformDateFormat(existingItem.start_date),
				lodgingTo: transformDateFormat(existingItem.end_date),
				lodgingAddress: existingItem.address,
				lodgingName: existingItem.name,
				lodgingQuestionnaire: {
					lodgingConfirmationCode: existingItem.confirmation_code,
					lodgingComments: existingItem.comments,
				},
			};
			// this.setState({

			// });
		} else {
			this.state = {
				isEditing: false,
				isAddingAddress: false,
				isSelectingDates: false,
				isSelectingConfirmationCodeAndData: false,
				isSelectingLodgingType: true,
				isLoadingFromScreenshot: false,

				lodgingType: null,

				lodgingFrom: '',
				lodgingTo: '',

				lodgingAddress: '',
				lodgingName: '',

				lodgingQuestionnaire: {
					lodgingConfirmationCode: '',
					lodgingComments: '',
				},
			};
		}
	}

	// componentDidMount() {

	// }

	selectLodgingType = (lodgingType) => {
		this.setState({
			lodgingType: lodgingType,
			isSelectingLodgingType: false,
			isSelectingDates: true,
			isAddingAddress: false,
		});
	};

	goToDateSelection = () => {
		this.setState({
			isSelectingLodgingType: false,
			isSelectingDates: true,
			isAddingAddress: false,
		});
	};

	goToLodgeTypeSelection = () => {
		this.setState({
			isSelectingLodgingType: true,
			isSelectingDates: false,
		});
	};

	saveLodgingItemFromScreenshot = (
		start_date,
		end_date,
		address,
		title,
		confirmation_code,
		comment,
	) => {
		this.setState(
			{
				lodgingName: title,
				lodgingAddress: address,
				lodgingQuestionnaire: {
					lodgingComments: comment,
					lodgingConfirmationCode: confirmation_code,
				},
				lodgingFrom: start_date,
				lodgingTo: end_date,
			},
			() => this.saveNewLodgingItem(),
		);
	};

	selectDates = (fromDate, toDate) => {
		this.setState({
			lodgingFrom: fromDate,
			lodgingTo: toDate,
		});
	};

	goToAddressSelection = () => {
		this.setState({
			isSelectingDates: false,
			isAddingAddress: true,
			isSelectingConfirmationCodeAndData: false,
		});
	};

	selectAddressAndName = (address, name) => {
		if (name != '') {
			this.setState({
				isAddingAddress: false,
				isSelectingConfirmationCodeAndData: true,
				lodgingAddress: address,
				lodgingName: name,
			});
		} else {
			this.setState({
				lodgingAddress: address,
				lodgingName: name,
			});
		}
	};

	updateLodgingName = (newName) => {
		this.setState({ lodgingName: newName });
	};

	goToConfirmationCodeAndComments = () => {
		this.setState({
			isAddingAddress: false,
			isSelectingConfirmationCodeAndData: true,
		});
	};

	updateQuestionnaire = (updatedQuestionnaire) => {
		this.setState({ lodgingQuestionnaire: updatedQuestionnaire });
	};

	updateLodgingItem = () => {
		// If we don't have the right details, don't submit
		if (
			!(this.state.lodgingType != null && this.state.lodgingName.length > 2)
		) {
			return;
		}

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('item_id', this.props.existingItem.lodging_item_id);
		formData.append('lodging_type', this.state.lodgingType.id);
		formData.append('lodging_name', this.state.lodgingName);
		formData.append('lodging_address', this.state.lodgingAddress);
		formData.append(
			'lodging_comments',
			this.state.lodgingQuestionnaire.lodgingComments,
		);
		formData.append(
			'lodging_confirmation_code',
			this.state.lodgingQuestionnaire.lodgingConfirmationCode,
		);
		formData.append(
			'lodging_start_date',
			fromFulltimestampToDate(this.state.lodgingFrom),
		);
		formData.append(
			'lodging_end_date',
			fromFulltimestampToDate(this.state.lodgingTo),
		);

		fetch(`https://api.gallivibe.com/updateLocationLodgingItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.removeFromLodgingItems(this.props.existingItem, () =>
						this.props.addToLodgingItems(data.lodging_item),
					);

					this.props.goToItem(data.lodging_item);
				}
			})
			.catch((error) => console.error(error));
	};

	saveNewLodgingItem = () => {
		// If we don't have the right details, don't submit
		if (
			!(this.state.lodgingType != null && this.state.lodgingName.length > 2)
		) {
			return;
		}

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('lodging_type', this.state.lodgingType.id);
		formData.append('lodging_name', this.state.lodgingName);
		formData.append('lodging_address', this.state.lodgingAddress);
		formData.append(
			'lodging_comments',
			this.state.lodgingQuestionnaire.lodgingComments,
		);
		formData.append(
			'lodging_confirmation_code',
			this.state.lodgingQuestionnaire.lodgingConfirmationCode,
		);
		formData.append(
			'lodging_start_date',
			fromFulltimestampToDate(this.state.lodgingFrom),
		);
		formData.append(
			'lodging_end_date',
			fromFulltimestampToDate(this.state.lodgingTo),
		);

		fetch(`https://api.gallivibe.com/addLocationLodgingItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.addToLodgingItems(data.item);
					this.props.goToItem(data.item);
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		return (
			<Grow in={this.props.isAddingNewItem}>
				<div>
					<br />
					<div className="addingLodgingInfoTitle">
						<div
							className="lodgingInfoTitle"
							onClick={() => {
								if (this.state.isLoadingFromScreenshot) {
									this.setState({ isLoadingFromScreenshot: false });
								} else if (this.state.isEditing) {
									this.props.goToItem(this.props.existingItem);
								} else {
									this.props.goToLodgingItemMenu();
								}
							}}
						>
							<div className="backArrowBtn">
								<ArrowBackIos fontSize="x-small"></ArrowBackIos>
							</div>
							{this.state.lodgingName == ''
								? this.state.isLoadingFromScreenshot
									? 'From Screenshot'
									: 'Adding Lodging'
								: this.state.lodgingName}
						</div>
						<div>
							{!this.state.isLoadingFromScreenshot &&
								this.state.lodgingType != null &&
								this.state.lodgingType.id != 3 && (
									<div
										className="mainBtn"
										style={{
											fontSize: 'small',
											paddingTop: '0px',
											paddingBottom: '0px',
											marginTop: '0px',
											marginBottom: '0px',
											marginRight: '4px',
										}}
										onClick={() =>
											this.setState({
												isLoadingFromScreenshot: true,
											})
										}
									>
										📷 From Screenshot
									</div>
								)}
						</div>
					</div>
					<hr />
					<div className="addLodgingArea">
						<Grow in={!this.state.isLoadingFromScreenshot}>
							<div
								style={{
									display: this.state.isLoadingFromScreenshot
										? 'none'
										: 'block',
								}}
							>
								<div
									className={`newActivitySection ${
										this.state.isSelectingLodgingType ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												isSelectingLodgingType:
													!this.state.isSelectingLodgingType,
												isSelectingDates: false,
												isAddingAddress: false,
												isSelectingConfirmationCodeAndData: false,
											})
										}
									>
										<div>
											<div>
												<Category fontSize="x-small"></Category>
											</div>
											<div>Lodging Type</div>
										</div>
										<div>
											{this.state.lodgingType != null
												? `${this.state.lodgingType.emoji} ${this.state.lodgingType.name}`
												: ``}
										</div>
									</div>
									<div>
										<TripLodgingAreaNewItemType
											currentType={this.state.lodgingType}
											selectLodgingType={this.selectLodgingType}
											isSelectingLodgingType={true}
										></TripLodgingAreaNewItemType>
									</div>
								</div>

								<div
									className={`newActivitySection ${
										this.state.isSelectingDates ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												isSelectingLodgingType: false,
												isSelectingDates: !this.state.isSelectingDates,
												isAddingAddress: false,
												isSelectingConfirmationCodeAndData: false,
											})
										}
									>
										<div>
											<div>
												<CalendarMonth fontSize="x-small"></CalendarMonth>
											</div>
											<div>Dates</div>
										</div>
										<div>{`${this.state.lodgingFrom} - ${this.state.lodgingTo}`}</div>
									</div>
									<div>
										<TripLodgingAreaNewItemDateSelection
											tripData={this.props.tripData}
											selectDates={this.selectDates}
											goToAddressSelection={this.goToAddressSelection}
											lodgingFrom={this.state.lodgingFrom}
											lodgingTo={this.state.lodgingTo}
											isSelectingDates={true}
										></TripLodgingAreaNewItemDateSelection>
									</div>
								</div>

								<div
									className={`newActivitySection ${
										this.state.isAddingAddress ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												isSelectingLodgingType: false,
												isSelectingDates: false,
												isAddingAddress: !this.state.isAddingAddress,
												isSelectingConfirmationCodeAndData: false,
											})
										}
									>
										<div>
											<div>
												<Map fontSize="x-small"></Map>
											</div>
											<div>Address</div>
										</div>
										<div>{this.state.lodgingAddress}</div>
									</div>
									<div>
										<TripLodgingAreaNewItemAddressSelection
											selectAddressAndName={this.selectAddressAndName}
											lodgingAddress={this.state.lodgingAddress}
											lodgingName={this.state.lodgingName}
											isAddingAddress={true}
										></TripLodgingAreaNewItemAddressSelection>
									</div>
								</div>

								<div
									className={`newActivitySection ${
										this.state.isSelectingConfirmationCodeAndData
											? 'sectionSelected'
											: ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												isSelectingLodgingType: false,
												isSelectingDates: false,
												isAddingAddress: false,
												isSelectingConfirmationCodeAndData:
													!this.state.isSelectingConfirmationCodeAndData,
											})
										}
									>
										<div>
											<div>
												<Note fontSize="x-small"></Note>
											</div>
											<div>Name & Miscellaneous</div>
										</div>
										<div></div>
									</div>
									<div>
										<TripLodgingAreaNewItemConfirmationCodeAndComments
											lodgingName={this.state.lodgingName}
											lodgingQuestionnaire={this.state.lodgingQuestionnaire}
											updateLodgingName={this.updateLodgingName}
											updateQuestionnaire={this.updateQuestionnaire}
											isSelectingConfirmationCodeAndData={true}
											saveNewLodgingItem={this.saveNewLodgingItem}
										></TripLodgingAreaNewItemConfirmationCodeAndComments>
									</div>
								</div>

								<div
									className={`${
										this.state.lodgingType != null &&
										this.state.lodgingName.length > 2
											? 'mainSubmitBtn'
											: 'mainBtn'
									} SaveItemBtn`}
									onClick={
										this.props.existingItem
											? this.updateLodgingItem
											: this.saveNewLodgingItem
									}
								>
									Save Item
								</div>
							</div>
						</Grow>
						<TripLodgingAreaLoadFromScreenshot
							isLoadingFromScreenshot={this.state.isLoadingFromScreenshot}
							saveLodgingItemFromScreenshot={this.saveLodgingItemFromScreenshot}
						></TripLodgingAreaLoadFromScreenshot>
					</div>
				</div>
			</Grow>
		);
	}
}
