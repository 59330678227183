import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { transformDateFormat } from './../../../utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	ArrowBackIos,
	CalendarMonth,
	Check,
	Clear,
	ConfirmationNumber,
	Map,
	Note,
} from '@mui/icons-material';

export default class TripLodgingAreaItemContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeleting: false,
		};
	}

	deleteItem = () => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('item_id', this.props.viewingLodgingItem.lodging_item_id);

		fetch(`https://api.gallivibe.com/deleteLocationLodgingItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.removeFromLodgingItems(this.props.viewingLodgingItem);
					this.props.viewItems();
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		let lodgingItem = this.props.viewingLodgingItem;

		return (
			<Grow in={this.props.isViewingItemContent}>
				<div>
					<br />
					<div className="addingLodgingInfoTitle">
						<div onClick={this.props.viewItems}>
							<div className="backArrowBtn">
								<ArrowBackIos fontSize="x-small"></ArrowBackIos>
							</div>
							<div className="lodgingItemName">{lodgingItem.name}</div>
						</div>
						<div style={{ gap: '15px' }}>
							<div
								onClick={() => this.props.editItem(lodgingItem)}
								style={{ display: 'flex', gap: '5px' }}
							>
								<EditIcon fontSize="x-small"></EditIcon>
								<div>Edit</div>
							</div>
						</div>
						<div
							onClick={() => this.setState({ isDeleting: true })}
							style={{ display: 'flex', gap: '5px' }}
						>
							<DeleteIcon fontSize="x-small"></DeleteIcon>
							<div>Delete</div>
						</div>
					</div>
					<hr />

					<div
						className="addLodgingArea lodgingInfoContent"
						style={{ display: this.state.isDeleting ? 'none' : 'flex' }}
					>
						<Grow in={!this.state.isDeleting}>
							<div>
								<Grow in={true} timeout={500}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<Map fontSize="xx-small"></Map>
											</div>
											<div>Address:</div>
										</div>
										<a
											href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
												lodgingItem.address,
											)}`}
											target="_blank"
										>
											{lodgingItem.address}
										</a>
									</div>
								</Grow>

								<Grow in={true} timeout={700}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<CalendarMonth fontSize="xx-small"></CalendarMonth>
											</div>
											<div>Dates:</div>
										</div>
										<div>
											{transformDateFormat(lodgingItem.start_date)}
											{' - '}
											{transformDateFormat(lodgingItem.end_date)}
										</div>
									</div>
								</Grow>

								<Grow in={true} timeout={900}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<ConfirmationNumber fontSize="xx-small"></ConfirmationNumber>
											</div>
											<div>Confirmation:</div>
										</div>
										<div>{lodgingItem.confirmation_code}</div>
									</div>
								</Grow>

								<Grow in={true} timeout={1100}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<Note fontSize="xx-small"></Note>
											</div>
											<div>Note:</div>
										</div>
										{lodgingItem.comments}
									</div>
								</Grow>
							</div>
						</Grow>
					</div>
					<div style={{ display: this.state.isDeleting ? 'block' : 'none' }}>
						<Grow in={this.state.isDeleting}>
							<div className="deletingTripPage">
								<h3>
									Are you sure that you would like to delete this lodging item?
								</h3>
								<center>
									<div
										style={{
											width: '70%',
											display: 'flex',
											justifyContent: 'space-evenly',
											marginTop: '40px',
										}}
									>
										<div
											className="deleteItemOption"
											style={{ width: '100px', height: '100px' }}
											onClick={() => {
												this.setState({ isDeleting: false });
											}}
										>
											<div>
												<Clear></Clear>
											</div>
											<div>No</div>
										</div>
										<div
											className="deleteItemOption"
											style={{ width: '100px', height: '100px' }}
											onClick={(e) => {
												this.deleteItem();
											}}
										>
											<div>
												<Check></Check>
											</div>
											<div>Yes</div>
										</div>
									</div>
								</center>
							</div>
						</Grow>
					</div>
				</div>
			</Grow>
		);
	}
}
