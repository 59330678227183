import React, { Component } from 'react';

export default class MultipleChoiceOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
    };
  }

  render() {
    return (
      <div
        className={`answerOption ${
          this.props.answerStatus[this.props.optionData.id]
            ? 'selectedOption'
            : ''
        }`}
        onClick={(e) => {
          this.props.tapAnswer(this.props.optionData.id);
        }}
      >
        {this.props.optionData.option}
      </div>
    );
  }
}
