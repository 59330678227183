import { Grow } from '@mui/material';
import React, { Component } from 'react';
import Question from './../../../TripPlanning/SmallerComponents/Question';

export default class TripLodgingAreaNewItemConfirmationCodeAndComments extends Component {
	constructor(props) {
		super(props);
		this.state = this.props.lodgingQuestionnaire;
	}

	render() {
		return (
			<Grow in={this.props.isSelectingConfirmationCodeAndData}>
				<div
					style={{
						display: this.props.isSelectingConfirmationCodeAndData
							? 'block'
							: 'none',
					}}
				>
					<br />
					<Question
						questionData={{
							type: 'singleLine',
							question: 'Lodging Name:',
							options: [],
							defaultValue: this.props.lodgingName,
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.props.updateLodgingName(questionAnswer);
						}}
					></Question>

					<Question
						questionData={{
							type: 'singleLine',
							question: 'Confirmation Code:',
							defaultValue: this.state.lodgingConfirmationCode,
							options: [],
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.setState({ lodgingConfirmationCode: questionAnswer }, (e) =>
								this.props.updateQuestionnaire(this.state),
							);
						}}
					></Question>

					<Question
						questionData={{
							type: 'textarea',
							question: 'Comments:',
							options: [],
							default: this.state.lodgingComments,
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.setState({ lodgingComments: questionAnswer }, (e) =>
								this.props.updateQuestionnaire(this.state),
							);
						}}
					></Question>
				</div>
			</Grow>
		);
	}
}
