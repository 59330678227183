import React, { Component } from 'react';
import { Grow } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EmojiPicker from './SmallerComponents/EmojiPicker';
import AddressAutocompleteItem from './../AddressAutocompleteItem';

export default class SelectLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEmojiPicker: false,
			showTitleRequirementsWarning: false,
			hasExited: true,
			shakeTitle: false,
			selectedEmoji: '⛰️',
			selectedTitle: '',
			suggestions: [],
		};
	}

	toggleEmojiPicker = () => {
		this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
	};

	autocompleteLocation = (event) => {
		const formData = new FormData();
		formData.append('location', event.target.value);

		fetch('https://api.gallivibe.com/autocompleteLocation', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.suggestions) {
					this.setState({
						suggestions: data.suggestions,
					});
				} else {
				}
			})
			.catch((error) => {
				console.error('Error occurred:', error);
			});
	};

	handleOnKeyUp = (event) => {
		clearTimeout(this.state.timeout);
		this.setState({
			timeout: setTimeout(() => {
				this.autocompleteLocation(event);
			}, 500),
		});
	};

	render() {
		return (
			<Grow in={this.props.isSelectingDestination}>
				<div>
					<div className="userAreaTitle">
						{`${this.props.adventure.emoji} ${this.props.adventure.title}`}
					</div>
					<hr />
					<div className="userAreaContent">
						<br />
						Which location would you like to add?
						<br />
						<br />
						<div>
							<input
								type="text"
								className={`mainTxtInput ${
									this.state.shakeTitle ? 'shake' : ''
								}`}
								placeholder="e.g., San Francisco, CA"
								// value={this.state.selectedTitle}
								onChange={this.handleOnKeyUp}
								onKeyDown={(event) => {
									if (event.key === 'Enter' && this.state.password !== '')
										this.continue();
								}}
							/>
						</div>
						{this.state.suggestions.length > 0 && (
							<div className="locationSearchResults">
								<br />
								Tap the location:
								<br />
								<br />
								{this.state.suggestions.map((suggestion, index) => (
									<Grow in={true} key={index} timeout={100 + index * 250}>
										<div
											className="mainBoxBtn wHighlight"
											onClick={(e) => {
												this.props.setDestination(
													suggestion.address,
													suggestion.emoji,
												);
											}}
										>
											{suggestion.emoji} {suggestion.address}
										</div>
									</Grow>
								))}
							</div>
						)}
					</div>
					{this.state.suggestions.length == 0 && <br />}
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon> Back
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
