import React, { Component } from 'react';
import AdventurePageMain from './AdventurePageMain.js';
import Trip from './../TripPage/Trip.js';
import PlanTrip from './../TripPlanning/PlanTrip.js';
import {
	normalToMilitary,
	sortTransportationItemsByDateTime,
} from './../../utils';
import DeleteAdventure from './DeleteAdventure.js';
import AdventureCheckIn from './AdventureCheckIn.js';
import AdventureViewCheckInHistory from './AdventureViewCheckInHistory.js';

export default class AdventurePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowingTrip: false,
			isPlanningTrip: false,
			isDeletingAdventure: false,
			isAddingAdventureCheckIn: false,
			isViewingCheckInHistory: false,

			trip: {},
			itinerary: {},
			lodgingItems: [],
			transportationItems: [],
			aiSuggestion: '',
			checkInHistory: null,
		};
	}

	getAISuggestion = (tripData) => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', tripData.id);

		fetch(`https://api.gallivibe.com/getLocationAISuggestion`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.setState({ aiSuggestion: data.suggestion });
				}
			})
			.catch((error) => console.error(error));
	};

	goToViewCheckInHistory = () => {
		this.setState(
			{
				isAddingAdventureCheckIn: false,
				isViewingCheckInHistory: true,
				checkInHistory: null,
			},
			() => {
				const formData = new FormData();
				formData.append('token', this.props.token);
				formData.append('adventure_id', this.props.adventure.id);

				fetch(`https://api.gallivibe.com/getCheckIns`, {
					method: 'POST',
					body: formData,
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.ok) {
							this.setState({ checkInHistory: data.checkIns });
						}
					})
					.catch((error) => console.error(error));
			},
		);
	};

	goToShowTrip = (tripData) => {
		this.setState({
			trip: tripData,
			isPlanningTrip: false,
			isShowingTrip: true,
			itinerary: {},
			lodgingItems: [],
			transportationItems: [],
			aiSuggestion: '',
		});

		let itinerary = {};
		let lodgingItems = [];
		let transportationItems = [];

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', tripData.id);

		const fetchItinerary = fetch(
			`https://api.gallivibe.com/getLocationItinerary`,
			{
				method: 'POST',
				body: formData,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					for (const key in data.itinerary) {
						data.itinerary[key] = data.itinerary[key].sort((a, b) => {
							const startTimeA = new Date(
								`1970-01-01 ${normalToMilitary(a.start_time)}`,
							); // Using a common date to ensure proper parsing
							const startTimeB = new Date(
								`1970-01-01 ${normalToMilitary(b.start_time)}`,
							);
							return startTimeA - startTimeB;
						});
					}

					itinerary = data.itinerary;
				}
			})
			.catch((error) => console.error(error));

		const fetchLodgingItems = fetch(
			`https://api.gallivibe.com/getLocationLodgingItems`,
			{
				method: 'POST',
				body: formData,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					lodgingItems = data.lodging_items;
				}
			})
			.catch((error) => console.error(error));

		const fetchTransportationItems = fetch(
			`https://api.gallivibe.com/getLocationTransportationItems`,
			{
				method: 'POST',
				body: formData,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					transportationItems = data.transportation_items;
					transportationItems =
						sortTransportationItemsByDateTime(transportationItems);
				}
			})
			.catch((error) => console.error(error));

		Promise.all([fetchItinerary, fetchLodgingItems, fetchTransportationItems])
			.then(() => {
				// Both requests have completed
				// You can perform any additional logic here if needed
				this.setState({
					itinerary: itinerary,
					lodgingItems: lodgingItems,
					transportationItems: transportationItems,
				});
				this.getAISuggestion(tripData);
			})
			.catch((error) => console.error(error));
	};

	addToItinerary = (itemDate, item) => {
		let currentItinerary = this.state.itinerary;

		if (!currentItinerary.hasOwnProperty(itemDate)) {
			currentItinerary[itemDate] = [];
		}
		currentItinerary[itemDate].push(item);
		currentItinerary[itemDate].sort((a, b) => {
			const startTimeA = new Date(
				`1970-01-01 ${normalToMilitary(a.start_time)}`,
			); // Using a common date to ensure proper parsing
			const startTimeB = new Date(
				`1970-01-01 ${normalToMilitary(b.start_time)}`,
			);
			return startTimeA - startTimeB;
		});

		this.setState({ itinerary: currentItinerary });
	};

	addToLodgingItems = (item) => {
		let currentLodgingItems = this.state.lodgingItems;
		currentLodgingItems.push(item);
		this.setState({ lodgingItems: currentLodgingItems });
	};

	addToTransportationItems = (item) => {
		let currentTransportationItems = this.state.transportationItems;
		currentTransportationItems.push(item);
		currentTransportationItems = sortTransportationItemsByDateTime(
			currentTransportationItems,
		);
		this.setState({ transportationItems: currentTransportationItems });
	};

	removeTransportationItems = (item, callback = () => {}) => {
		let transportationItemsWithoutRemovedItem =
			this.state.transportationItems.filter(
				(transportationItem) =>
					transportationItem.transportation_item_id !=
					item.transportation_item_id,
			);
		this.setState(
			{
				transportationItems: transportationItemsWithoutRemovedItem,
			},
			callback,
		);
	};

	addToLodgingItems = (item) => {
		let currentLodgingItems = this.state.lodgingItems;
		currentLodgingItems.push(item);
		this.setState({ lodgingItems: currentLodgingItems });
	};

	removeFromLodgingItems = (item, callback = () => {}) => {
		let lodgingItemsWithoutRemovedItem = this.state.lodgingItems.filter(
			(lodgingItem) => lodgingItem.lodging_item_id != item.lodging_item_id,
		);
		this.setState({ lodgingItems: lodgingItemsWithoutRemovedItem }, callback);
	};

	removeFromItinerary = (
		itemDate,
		item,
		hardRemove = false,
		callbackFunc = () => {},
	) => {
		let currentItinerary = this.state.itinerary;

		if (
			!currentItinerary.hasOwnProperty(itemDate) ||
			this.state.trip.length == 0
		) {
			return;
		}

		if (hardRemove) {
			const formData = new FormData();
			formData.append('token', this.props.token);
			formData.append('adventure_id', this.props.adventure.id);
			formData.append('trip_id', this.state.trip.id);
			formData.append('item_id', item.id);

			fetch(`https://api.gallivibe.com/deleteLocationItineraryItem`, {
				method: 'POST',
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					// TODO: Handle errors lol.
				})
				.catch((error) => console.error(error));
		}

		currentItinerary[itemDate] = this.state.itinerary[itemDate];
		currentItinerary[itemDate] = this.state.itinerary[itemDate].filter(
			(currentItem) => currentItem.id != item.id,
		);

		this.setState({ itinerary: currentItinerary }, () => {
			callbackFunc();
		});
	};

	render() {
		return (
			<div>
				{!this.state.isPlanningTrip && !this.state.isShowingTrip && (
					<div>
						<AdventurePageMain
							token={this.props.token}
							user={this.props.user}
							isShowingMainPage={
								!this.state.isPlanningTrip &&
								!this.state.isShowingTrip &&
								!this.state.isDeletingAdventure &&
								!this.state.isAddingAdventureCheckIn &&
								!this.state.isViewingCheckInHistory
							}
							adventure={this.props.adventure}
							deleteAdventure={() => {
								this.setState({ isDeletingAdventure: true });
							}}
							addLocation={() => {
								this.setState({ isPlanningTrip: true });
							}}
							adventureCheckIn={() =>
								this.setState({ isAddingAdventureCheckIn: true })
							}
							adventureCheckInHistory={this.goToViewCheckInHistory}
							goToShowTrip={this.goToShowTrip}
							adventureComingFrom={this.props.adventureComingFrom}
							goBack={this.props.goBack}
						></AdventurePageMain>
						<DeleteAdventure
							token={this.props.token}
							adventure={this.props.adventure}
							isDeletingAdventure={this.state.isDeletingAdventure}
							backToAdventure={() =>
								this.setState({ isDeletingAdventure: false })
							}
							adventureComingFrom={this.props.adventureComingFrom}
							goBack={this.props.goBack}
						></DeleteAdventure>
						{this.state.isAddingAdventureCheckIn && (
							<AdventureCheckIn
								token={this.props.token}
								adventure={this.props.adventure}
								goToViewCheckInHistory={this.goToViewCheckInHistory}
								goBack={() => {
									this.setState({ isAddingAdventureCheckIn: false });
								}}
								isAddingAdventureCheckIn={this.state.isAddingAdventureCheckIn}
							></AdventureCheckIn>
						)}
						{this.state.isViewingCheckInHistory && (
							<AdventureViewCheckInHistory
								token={this.props.token}
								adventure={this.props.adventure}
								checkInHistory={this.state.checkInHistory}
								goToAdventureCheckIn={() =>
									this.setState({
										isViewingCheckInHistory: false,
										isAddingAdventureCheckIn: true,
									})
								}
								goBack={() => {
									this.setState({ isViewingCheckInHistory: false });
								}}
								isViewingCheckInHistory={this.state.isViewingCheckInHistory}
							></AdventureViewCheckInHistory>
						)}
					</div>
				)}

				{this.state.isPlanningTrip && (
					<PlanTrip
						themeMode={this.props.themeMode}
						isPlanningTrip={this.state.isPlanningTrip}
						token={this.props.token}
						adventure={this.props.adventure}
						goBack={() => {
							this.setState({ isPlanningTrip: false });
						}}
						goToShowTrip={this.goToShowTrip}
					></PlanTrip>
				)}
				{this.state.isShowingTrip && (
					<Trip
						isShowingTrip={this.state.isShowingTrip}
						token={this.props.token}
						adventure={this.props.adventure}
						tripData={this.state.trip}
						itinerary={this.state.itinerary}
						lodgingItems={this.state.lodgingItems}
						transportationItems={this.state.transportationItems}
						aiSuggestion={this.state.aiSuggestion}
						addToItinerary={this.addToItinerary}
						addToLodgingItems={this.addToLodgingItems}
						addToTransportationItems={this.addToTransportationItems}
						removeTransportationItems={this.removeTransportationItems}
						removeFromLodgingItems={this.removeFromLodgingItems}
						removeFromItinerary={this.removeFromItinerary}
						user={this.props.user}
						goBack={() => {
							this.setState({
								isShowingTrip: false,
								trip: {},
								lodgingItems: [],
								transportationItems: [],
							});
						}}
					></Trip>
				)}
			</div>
		);
	}
}
