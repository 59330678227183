import { Grow, CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import ScheduledAdventuresItem from './TripPlanning/SmallerComponents/ScheduledAdventuresItem.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Add } from '@mui/icons-material';

export default class ScheduledTrips extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alreadyChecked: false,
		};
	}

	render() {
		return (
			<div style={{ marginTop: '7px' }}>
				<Grow in={this.props.isSeeingScheduledTrips}>
					<div className="mainPageHeader">
						<div onClick={this.props.goToMainMenu}>
							<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
						</div>
						<div className="userAreaTitle">🛤️ Upcoming Adventures</div>
						<div></div>
					</div>
				</Grow>

				<div>
					<div className="userAreaContent scheduledAdventuresArea">
						<hr />

						{this.props.fetchedScheduledAdventures.length > 0 ? (
							<div>
								<br />
								{this.props.fetchedScheduledAdventures.map(
									(adventure, index) => (
										<ScheduledAdventuresItem
											itemIndex={index}
											key={index}
											adventure={adventure}
											goToShowAdventure={(adventure) =>
												this.props.goToShowAdventure(adventure, 'scheduled')
											}
										></ScheduledAdventuresItem>
									),
								)}
								<br />
							</div>
						) : (
							<div
								style={{
									marginTop: '70px',
									marginBottom: '70px',
									textAlign: 'center',
								}}
							>
								{this.props.adventuresFetched ? (
									<div>
										<br />
										When you schedule your adventures, they'll show up here!
									</div>
								) : (
									<CircularProgress></CircularProgress>
								)}
							</div>
						)}
					</div>

					<div
						className="mainBtn addLocationBtn"
						onClick={this.props.goToRecordNewAdventure}
					>
						<Add></Add> Create New Adventure
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goToMainMenu}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon> Back to
						Main Menu
					</div>
					<hr />
				</div>
			</div>
		);
	}
}
