import { Grow } from '@mui/material';
import React, { Component } from 'react';
import Question from './../../../TripPlanning/SmallerComponents/Question';

export default class TripTransportationNewItemDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			transportationName: '',
			transportationConfirmationCode: '',
			transportationComments: '',
		};
	}

	select = () => {
		this.props.saveNewTransportationItem();
	};

	render() {
		return (
			<Grow in={this.props.selectingDetails}>
				<div
					style={{ display: this.props.selectingDetails ? 'block' : 'none' }}
				>
					<br />
					<Question
						questionData={{
							type: 'singleLine',
							question: 'Name:',
							options: [],
							defaultValue: this.props.name,
							placeholder: 'e.g. JFK to SFO...',
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.props.updateName(questionAnswer);
						}}
					></Question>
					<Question
						questionData={{
							type: 'singleLine',
							question: 'Confirmation Code:',
							options: [],
							defaultValue: this.props.confirmationCode,
							placeholder: 'e.g. 123456789...',
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.props.updateConfirmationCode(questionAnswer);
						}}
					></Question>
					<Question
						questionData={{
							type: 'textarea',
							question: 'Comments:',
							options: [],
							default: this.props.comments,
							placeholder: "e.g. Eduardo's train ride...",
						}}
						updateQuestionAnswerFunc={(questionID, questionAnswer) => {
							this.props.updateComments(questionAnswer);
						}}
					></Question>
					{/* <button
						className="mainSubmitBtn"
						style={{ marginTop: '0px' }}
						onClick={this.select}
					>
						Save
					</button> */}
				</div>
			</Grow>
		);
	}
}
