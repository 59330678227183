import { Collapse } from '@mui/material';
import React, { Component } from 'react';
import { validateEmailPattern } from './../utils.js';
import NewAccountDialogue from './NewAccountDialogue.js';

export default class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Input values
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      verifyPassword: '',

      // Actions
      shakePasswords: false,
      shakeEmail: false,
      shakeFirstName: false,
      shakeLastName: false,

      // States
      isAddingNames: true,
      isAddingEmailAndPassword: false,
      isShowingNewAccountDialogue: false,
    };
  }

  shakePasswordFields = () => {
    this.setState({ shakePasswords: true });
    setTimeout(
      function () {
        this.setState({ shakePasswords: false });
      }.bind(this),
      500,
    );
    this.setState({ password: '', verifyPassword: '' });
  };

  shakeEmailField = () => {
    this.setState({ shakeEmail: true });
    setTimeout(
      function () {
        this.setState({ shakeEmail: false });
      }.bind(this),
      500,
    );
  };

  shakeFirstName = () => {
    this.setState({ shakeFirstName: true });
    setTimeout(
      function () {
        this.setState({ shakeFirstName: false });
      }.bind(this),
      500,
    );
  };

  shakeLastName = () => {
    this.setState({ shakeLastName: true });
    setTimeout(
      function () {
        this.setState({ shakeLastName: false });
      }.bind(this),
      500,
    );
  };

  validatePassword = (password) => {
    // Password length should be at least 8 characters
    if (password.length < 8) {
      return {
        valid: false,
        message: 'Password should be at least 8 characters long',
      };
    }

    // Password should contain at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return {
        valid: false,
        message: 'Password should contain at least one uppercase letter',
      };
    }

    // Password should contain at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return {
        valid: false,
        message: 'Password should contain at least one lowercase letter',
      };
    }

    // Password should contain at least one digit
    if (!/\d/.test(password)) {
      return {
        valid: false,
        message: 'Password should contain at least one digit',
      };
    }

    // Password meets all requirements
    return {
      valid: true,
      message: 'Password is acceptable.',
    };
  };

  validateName = (name, fieldName) => {
    // Remove leading/trailing whitespaces
    const trimmedName = name.trim();

    // Name should contain at least two characters
    if (trimmedName.length < 2) {
      return {
        valid: false,
        message: `${fieldName} should contain at least two characters`,
      };
    }

    // Name should contain max 15 characters
    if (trimmedName.length > 15) {
      return {
        valid: false,
        message: `${fieldName} should not be longer than 15 characters`,
      };
    }

    // Name should consist of only letters, spaces, and hyphens
    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ -]+$/.test(trimmedName)) {
      return {
        valid: false,
        message: `${fieldName} should consist of only letters, spaces, and hyphens`,
      };
    }

    // Name is valid
    return {
      valid: true,
      message: `${fieldName} is valid`,
    };
  };

  validateNames = () => {
    const { firstName, lastName } = this.state;

    let validateFirstName = this.validateName(firstName, 'First name');
    let validateLastName = this.validateName(lastName, 'Last name');

    if (!validateFirstName.valid) {
      this.shakeFirstName();
      this.props.updateMessage(validateFirstName.message);
      return false;
    }

    if (!validateLastName.valid) {
      this.shakeLastName();
      this.props.updateMessage(validateLastName.message);
      return false;
    }

    this.setState({ isAddingNames: false, isAddingEmailAndPassword: true });
    this.props.updateMessage(
      'Great! Now we just need your email address, and a password for your Gallivibe account',
    );
    return true;
  };

  validateEmailAndPasswordInputs = () => {
    const { email, password, verifyPassword } = this.state;

    if (!validateEmailPattern(email)) {
      this.shakeEmailField();
      this.props.updateMessage('Invalid email address.');

      return false;
    }

    if (password != verifyPassword) {
      this.shakePasswordFields();
      this.props.updateMessage('The passwords do not match, please try again');

      return false;
    }

    let passwordSecurityValidation = this.validatePassword(password);
    if (!passwordSecurityValidation.valid) {
      this.shakePasswordFields();
      this.props.updateMessage(passwordSecurityValidation.message);
      return false;
    }

    return true;
  };

  goToSigningIn = () => {
    this.props.goToSigningIn();
    this.props.updateMessage('😎 Now we just need to sign you in!');
  };

  createAccount = () => {
    let allInputIsValid = this.validateEmailAndPasswordInputs();

    if (allInputIsValid) {
      const { firstName, lastName, email, password } = this.state;
      let phoneNumber = this.props.phoneNumber;
      let phoneCode = this.props.phoneCode;

      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('phoneNumber', phoneNumber);
      formData.append('phoneCode', phoneCode);

      const response = fetch('https://api.gallivibe.com/createAccount', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok == true && data.status == 'account_created') {
            // this.props.goToSigningIn();
            // this.props.updateMessage('Welcome to Gallivibe, please sign in');
            this.setState({
              isShowingNewAccountDialogue: true,
              isAddingEmailAndPassword: false,
            });
            this.props.updateMessage("🕵️ It's time to adventure");
          }

          if (data.ok == true && data.status == 'email_unavailable') {
            this.props.updateMessage(
              'The email address you used is not available, try a different one',
            );
            this.shakeEmailField();
          }

          if (data.ok == false) {
            this.props.updateMessage(
              'Ouch, something went wrong! Please refresh the app and try again.',
            );
          } else {
          }
        });
    }
  };

  render() {
    return (
      <Collapse in={this.props.isCreatingAccount}>
        <div
          style={{ display: this.props.isCreatingAccount ? 'block' : 'none' }}
          id="createAccountForm"
        >
          <Collapse in={this.state.isAddingNames}>
            <div
              style={{ display: this.state.isAddingNames ? 'block' : 'none' }}
            >
              <input
                placeholder="First Name..."
                className={`mainTxtInput ${
                  this.state.shakeFirstName ? 'shake' : ''
                }`}
                onChange={(event) =>
                  this.setState({ firstName: event.target.value })
                }
                type="text"
                style={{ marginBottom: '10px' }}
              ></input>
              <input
                placeholder="Last Name..."
                className={`mainTxtInput ${
                  this.state.shakeLastName ? 'shake' : ''
                }`}
                onChange={(event) =>
                  this.setState({ lastName: event.target.value })
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && this.state.lastName !== '')
                    this.validateNames();
                }}
                type="text"
              ></input>
              <button className="mainSubmitBtn" onClick={this.validateNames}>
                Continue
              </button>
            </div>
          </Collapse>
          <Collapse in={this.state.isAddingEmailAndPassword}>
            <div
              style={{
                display: this.state.isAddingEmailAndPassword ? 'block' : 'none',
              }}
            >
              <input
                placeholder="E-mail address..."
                id="accountCreationEmailAddress"
                type="email"
                autoComplete="off"
                className={`mainTxtInput ${
                  this.state.shakeEmail ? 'shake' : ''
                }`}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
                style={{ marginBottom: '10px' }}
              ></input>
              <input
                placeholder="Password..."
                id="accountCreationPassword"
                type="password"
                className={`mainTxtInput ${
                  this.state.shakePasswords ? 'shake' : ''
                }`}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
                value={this.state.password}
                autoComplete="new-password"
                style={{ marginBottom: '10px' }}
              ></input>
              <input
                placeholder="Verify Password..."
                id="accountCreationVerifyPassword"
                type="password"
                className={`mainTxtInput ${
                  this.state.shakePasswords ? 'shake' : ''
                }`}
                onChange={(event) =>
                  this.setState({ verifyPassword: event.target.value })
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && this.state.verifyPassword !== '')
                    this.createAccount();
                }}
                value={this.state.verifyPassword}
                autoComplete="new-password"
              ></input>
              <button className="mainSubmitBtn" onClick={this.createAccount}>
                Create Account
              </button>
            </div>
          </Collapse>
          <Collapse in={this.state.isShowingNewAccountDialogue}>
            {this.state.isShowingNewAccountDialogue && (
              <NewAccountDialogue
                goToSigningIn={this.goToSigningIn}
              ></NewAccountDialogue>
            )}
          </Collapse>
        </div>
      </Collapse>
    );
  }
}
