import './Itinerary.css';
import React, { Component } from 'react';
import ItineraryDailyBubble from './ItineraryDailyBubble';
import { transformDateFormat, fromFulltimestampToDate } from './../../../utils';
import ItineraryDayItems from './ItineraryDayItems';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

export default class Itinerary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			dateStr: '',
			dayOptions: [],
			isFirstVisible: true,
			isLastVisible: false,
			dayToSelectedItemIndex: {},
		};
		this.scrollDiv = React.createRef();
		this.checkVisibility = this.checkVisibility.bind(this);
	}

	formatDate = (date) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Intl.DateTimeFormat('en-US', options).format(date);
	};

	selectDay = (day) => {
		let currentStateSelected = this.state.selected;
		let dateDay = new Date(day);

		let delay = 550;
		if (this.state.selected == day || this.state.selected == null) {
			delay = 0;
		}

		this.setState({
			selected: null,
		});

		setTimeout((e) => {
			this.setState({
				selected: currentStateSelected == day ? null : day,
				dateStr: this.formatDate(dateDay),
			});
		}, delay);
	};

	selectDayNoContentToggle = (day) => {
		let dateDay = new Date(day);

		let selectedBubble = document.getElementById(`Bubble${day}`);
		if (selectedBubble != null) {
			const container = document.getElementById('itineraryBubbles');
			const elementPositionLeft =
				selectedBubble.offsetLeft - container.offsetLeft;

			container.scrollTo({
				left: elementPositionLeft,
				behavior: 'smooth',
			});
		}

		this.setState({
			selected: day,
			dateStr: this.formatDate(dateDay),
		});
	};

	generateItinerary = (startDate, endDate) => {
		const start = new Date(startDate);
		const end = new Date(endDate);
		const itinerary = [];
		const dayOptions = [];
		let index = 0;

		while (start <= end) {
			let theDate = start.toISOString();

			dayOptions.push({
				dayString: this.formatDate(start),
				dayKey: start.toISOString(),
			});
			itinerary.push(
				<ItineraryDailyBubble
					bubbleIndex={index}
					key={theDate}
					date={theDate}
					selected={this.state.selected == theDate}
					select={this.selectDay}
					itinerary={this.props.itinerary}
				/>,
			);
			start.setDate(start.getDate() + 1);
			index += 1;
		}

		return { bubbles: itinerary, dayOptions: dayOptions };
	};

	updateDaysSelectedItem = (dayKey, newSelected, callback = null) => {
		let prepDayToSelectedItemIndex = { ...this.state.dayToSelectedItemIndex };
		prepDayToSelectedItemIndex[dayKey] = newSelected;
		this.setState(
			{
				dayToSelectedItemIndex: prepDayToSelectedItemIndex,
			},
			() => {
				// The state update has completed, now call the callback if it was provided.
				if (callback instanceof Function) {
					callback();
				}
			},
		);
	};

	componentDidMount() {
		let dateString = new Date().toISOString();

		if (this.props.tripIsOngoing) {
			setTimeout((e) => {
				this.selectDay(dateString);
			}, 500);
		}

		const div = this.scrollDiv.current;
		if (div) {
			div.addEventListener('scroll', this.checkVisibility);
			// Initial check
			this.checkVisibility();
		}
	}

	componentWillUnmount() {
		const div = this.scrollDiv.current;
		if (div) {
			div.removeEventListener('scroll', this.checkVisibility);
		}
	}

	checkVisibility() {
		const div = this.scrollDiv.current;
		const firstElement = div.firstChild;
		const lastElement = div.lastChild;

		const firstElemLeft = firstElement.getBoundingClientRect().left;
		const lastElemRight = lastElement.getBoundingClientRect().right;

		const divLeft = div.getBoundingClientRect().left;
		const divRight = div.getBoundingClientRect().right;

		this.setState({
			isFirstVisible: firstElemLeft >= divLeft,
			isLastVisible: lastElemRight - 5 <= divRight,
		});
	}

	render() {
		let startDate = transformDateFormat(this.props.tripData.from_date);
		let endDate = transformDateFormat(this.props.tripData.to_date);

		let res = this.generateItinerary(startDate, endDate);
		const itineraryComponents = res.bubbles;
		const dayOptions = res.dayOptions;
		return (
			<div className="itineraryComponent">
				<div
					className={`scroll-arrow scroll-left ${
						!this.state.isFirstVisible ? 'selected' : ''
					}`}
					onClick={this.scrollToPreviousLocation}
				>
					<ArrowLeft></ArrowLeft>
				</div>
				<div
					className="ItineraryItems"
					id="itineraryBubbles"
					ref={this.scrollDiv}
				>
					{itineraryComponents}
				</div>
				<div
					className={`scroll-arrow scroll-right ${
						!this.state.isLastVisible ? 'selected' : ''
					}`}
					onClick={this.scrollToNextLocation}
				>
					<ArrowRight></ArrowRight>
				</div>
				<ItineraryDayItems
					token={this.props.token}
					adventure={this.props.adventure}
					selected={this.state.selected}
					select={this.selectDay}
					selectDayNoContentToggle={this.selectDayNoContentToggle}
					itinerary={this.props.itinerary}
					dateStr={this.state.dateStr}
					tripData={this.props.tripData}
					dayOptions={dayOptions}
					addToItinerary={this.props.addToItinerary}
					softRemoveFromItinerary={this.props.removeFromItinerary}
					removeFromItinerary={(
						itemDate,
						item,
						hardRemove = false,
						callbackFunc,
					) => {
						this.updateDaysSelectedItem(
							itemDate,
							item.key > 0 ? item.key - 1 : 0,
							() =>
								this.props.removeFromItinerary(
									itemDate,
									item,
									hardRemove,
									callbackFunc,
								),
						);
					}}
					updateDaysSelectedItem={this.updateDaysSelectedItem}
					dayToSelectedItem={this.state.dayToSelectedItemIndex}
				></ItineraryDayItems>
			</div>
		);
	}
}
