import { ArrowBackIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import DateSelector from '../TripPlanning/SmallerComponents/DateSelector';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default class PlanAdventureSelectDates extends Component {
	constructor(props) {
		super(props);
		const currentDate = new Date();

		this.state = {
			from: {
				day: currentDate.getDate().toString().padStart(2, '0'),
				month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
				year: currentDate.getFullYear(),
			},
			to: {
				day: currentDate.getDate().toString().padStart(2, '0'),
				month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
				year: currentDate.getFullYear(),
			},
		};
	}

	updateFromDate = (day, month, year) => {
		this.setState({ from: { day: day, month: month, year: year } });

		const fromDate = new Date(`${year}-${month}-${day}`);
		const toDate = new Date(
			`${this.state.to.year}-${this.state.to.month}-${this.state.to.day}`,
		);

		if (toDate.getTime() < fromDate.getTime()) {
			this.updateToDate(day, month, year);
		}
	};
	updateToDate = (day, month, year) => {
		this.setState({ to: { day: day, month: month, year: year } });
	};

	getCurrentDate = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
		const day = String(currentDate.getDate()).padStart(2, '0');

		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	};

	createAdventure = () => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('title', this.props.adventureTitle);
		formData.append('emoji', this.props.adventureEmoji);
		formData.append(
			'start_date',
			`${this.state.from.month}/${this.state.from.day}/${this.state.from.year}`,
		);
		formData.append(
			'end_date',
			`${this.state.to.month}/${this.state.to.day}/${this.state.to.year}`,
		);

		fetch(`https://api.gallivibe.com/saveNewAdventure`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok == true) {
					this.props.goToShowAdventure(data.adventure);
				} else {
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		return (
			<Grow in={this.props.shouldShowSelectDates}>
				<div
					style={{
						display: this.props.shouldShowSelectDates ? 'block' : 'none',
					}}
				>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIos></ArrowBackIos>
						</div>
						<div className="userAreaTitle">
							{this.props.adventureEmoji} {this.props.adventureTitle}
						</div>
						<div></div>
					</div>

					<hr />
					<div className="userAreaContent">
						<br />
						<center>Let's select the dates for your adventure</center>
						<br />
						<div className="fromAndToDateSelector">
							<DateSelector
								type="From"
								year={this.state.from.year}
								month={this.state.from.month}
								day={this.state.from.day}
								updateTheDate={this.updateFromDate}
								minDate={this.getCurrentDate()}
								//       maxDate={}
							></DateSelector>
							 
							<div className="fromToArrowContainer">
								<ArrowForwardIcon></ArrowForwardIcon>
							</div>
							<DateSelector
								type="To"
								year={this.state.to.year}
								month={this.state.to.month}
								day={this.state.to.day}
								updateTheDate={this.updateToDate}
								minDate={`${this.state.from.year}-${this.state.from.month}-${this.state.from.day}`}
							></DateSelector>
						</div>
					</div>
					<div
						className="mainSubmitBtn"
						style={{ textAlign: 'center', width: '95%' }}
						onClick={() => {
							this.createAdventure();
						}}
					>
						Create Adventure
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIos fontSize="smaller"></ArrowBackIos> Back
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
