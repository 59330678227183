import React, { Component } from 'react';
import EmojiPicker from './SmallerComponents/EmojiPicker';
import { Grow } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import TypingEffect from './SmallerComponents/TypingEffect';

export default class NewAdventureDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingSecondParagraph: false,
      isAddingThirdParagraph: false,
      isAddingFourthParagraph: false,
      readyToGo: false,
      showBtn: false,
    };
  }

  render() {
    return (
      <Grow in={this.props.isReadingDialogue}>
        <div>
          <div className="userAreaTitle">🕵️ Adventure Planning</div>
          <hr />
          <div className="userAreaContent">
            <div
              style={{
                backgroundColor: 'var(--primaryBackgroundColorLight)',
                padding: '10px',
                borderRadius: '10px',
                fontSize: 'large',
              }}
            >
              <TypingEffect
                normalText=" Ever felt the thrill of a spontaneous road trip, the delight of a hidden café, or the awe atop an unknown peak? "
                boldText="  That's the essence of gallivanting."
                onComplete={() =>
                  setInterval(
                    () =>
                      this.setState({
                        isAddingSecondParagraph: true,
                        showBtn: true,
                      }),
                    2000,
                  )
                }
              ></TypingEffect>
            </div>
            <br />

            {this.state.isAddingSecondParagraph && (
              <div>
                <div
                  style={{
                    backgroundColor: 'var(--primaryBackgroundColorLight)',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: 'large',
                  }}
                >
                  <TypingEffect
                    normalText=" Life's about those unexpected moments, those unplanned detours that lead to the most magical memories."
                    boldText=""
                    onComplete={() =>
                      setInterval(
                        () => this.setState({ isAddingThirdParagraph: true }),
                        2000,
                      )
                    }
                  ></TypingEffect>
                </div>
                <br />
              </div>
            )}

            {this.state.isAddingThirdParagraph && (
              <div>
                <div
                  style={{
                    backgroundColor: 'var(--primaryBackgroundColorLight)',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: 'large',
                  }}
                >
                  <TypingEffect
                    normalText=" With Gallivibe, we invite you to embrace this spirit. Chart your own course and let every journey, big or small, paint your story. Because every adventure is a memory waiting to unfold."
                    boldText=""
                    onComplete={() =>
                      setInterval(
                        () => this.setState({ isAddingFourthParagraph: true }),
                        1000,
                      )
                    }
                  ></TypingEffect>
                </div>
                <br />
              </div>
            )}

            {this.state.isAddingFourthParagraph && (
              <div>
                <div
                  style={{
                    backgroundColor: 'var(--primaryBackgroundColorLight)',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: 'large',
                  }}
                >
                  <TypingEffect
                    normalText="  Are you ready to make yours?"
                    boldText="  Let's dive in! 🚀"
                    onComplete={() =>
                      setInterval(() => this.setState({ readyToGo: true }), 500)
                    }
                  ></TypingEffect>
                </div>
                <br />
              </div>
            )}
          </div>

          <center>
            {/* <EmojiPicker themeMode={this.props.themeMode}></EmojiPicker> */}

            {this.state.showBtn && (
              <div
                className={this.state.readyToGo ? 'mainSubmitBtn' : 'mainBtn'}
                style={{ maxWidth: '95%', marginTop: '0px' }}
                onClick={this.props.goNext}
              >
                {this.state.readyToGo ? 'Continue' : 'Skip'}
              </div>
            )}
            <hr />
            <div className="mainBtn" onClick={this.props.goToMainMenu}>
              <ArrowBackIos fontSize="smaller"></ArrowBackIos> Plan later
            </div>
            <hr />
          </center>
        </div>
      </Grow>
    );
  }
}
