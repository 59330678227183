import { ArrowBackIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';

export default class FAQ extends Component {
  render() {
    return (
      <Grow in={true}>
        <div>
          <div className="userAreaTitle">❓ Frequently Asked Questions</div>
          <hr />
          <div className="userAreaContent">
            It's natural for gallivanters to have questions... so here are some,
            along with the answers.
            <br />
            <br />
            <div className="FAQContainer">
              <div className="FAQItem">
                <div>Question</div>
                <div>Answer</div>
              </div>
              <div className="FAQItem">
                <div>Question</div>
                <div>Answer</div>
              </div>
              <div className="FAQItem">
                <div>Question</div>
                <div>Answer</div>
              </div>
            </div>
            <br />
            <br />
          </div>
          <hr />
          <div className="mainBtn" onClick={this.props.goBack}>
            <ArrowBackIos fontSize="smaller"></ArrowBackIos> Back
          </div>
          <hr />
        </div>
      </Grow>
    );
  }
}
