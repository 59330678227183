import React, { Component } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export default class DeleteTrip extends Component {
	deleteTrip = (id) => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('trip_id', id);
		formData.append('adventure_id', this.props.adventure.id);

		fetch(`https://api.gallivibe.com/deleteAdventureLocation`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.goBack();
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		return (
			<div className="deletingTripPage">
				<h3>Are you sure that you would like to delete your trip?</h3>
				<center>
					<div
						style={{
							width: '70%',
							display: 'flex',
							justifyContent: 'space-evenly',
							marginTop: '40px',
						}}
					>
						<div
							className="deleteItemOption"
							style={{ width: '100px', height: '100px' }}
							onClick={this.props.doNotDeleteTrip}
						>
							<div>
								<ClearIcon></ClearIcon>
							</div>
							<div>No</div>
						</div>
						<div
							className="deleteItemOption"
							style={{ width: '100px', height: '100px' }}
							onClick={(e) => {
								this.deleteTrip(this.props.tripData.id);
							}}
						>
							<div>
								<CheckIcon></CheckIcon>
							</div>
							<div>Yes</div>
						</div>
					</div>
				</center>
			</div>
		);
	}
}
