import './DateSelector.css';
import React, { Component } from 'react';

export default class DateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      month: '07',
      day: '23',
      year: '1997',
    };
  }

  selectDate = () => {
    const dateSelector = document.getElementById(
      `invisibleDate-${this.props.type}`,
    );

    dateSelector.focus();
  };

  componentDidMount() {
    //     this.checkTokenValidity();
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('safari') !== -1) {
      document.getElementById(
        `invisibleDate-${this.props.type}`,
      ).style.pointerEvents = 'all';
    }
    this.updateDate(`${this.props.year}-${this.props.month}-${this.props.day}`);
  }

  handleBackgroundHover = (isHovering) => {
    if (isHovering) {
      document
        .getElementById(`dateSelectorDay${this.props.type}`)
        .classList.add('dateSelectorHover');
      document
        .getElementById(`dateSelectorMonth${this.props.type}`)
        .classList.add('dateSelectorHover');
      document
        .getElementById(`dateSelectorYear${this.props.type}`)
        .classList.add('dateSelectorHover');
    } else {
      document
        .getElementById(`dateSelectorDay${this.props.type}`)
        .classList.remove('dateSelectorHover');
      document
        .getElementById(`dateSelectorMonth${this.props.type}`)
        .classList.remove('dateSelectorHover');
      document
        .getElementById(`dateSelectorYear${this.props.type}`)
        .classList.remove('dateSelectorHover');
    }
  };

  showThePicker = () => {
    document.getElementById(`invisibleDate-${this.props.type}`).showPicker();
    document.getElementById(`invisibleDate-${this.props.type}`).focus();
  };

  updateDate = (newVal) => {
    const newValMap = newVal.split('-');
    this.props.updateTheDate(newValMap[2], newValMap[1], newValMap[0]);
  };

  render() {
    return (
      <div
        className={`dateSelector ${
          this.state.focused ? 'dateSelectorFocused' : ''
        }`}
      >
        <input
          type="date"
          className="invisibleDateInput"
          style={{ backgroundColor: 'red' }}
          id={`invisibleDate-${this.props.type}`}
          onMouseEnter={() => {
            this.handleBackgroundHover(true);
          }}
          onMouseLeave={() => {
            this.handleBackgroundHover(false);
          }}
          onChange={(event) => {
            this.updateDate(event.target.value);
          }}
          onClick={(event) => {
            event.target.showPicker();
          }}
          onSelect={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          min={this.props.minDate}
          max={this.props.maxDate}
          value={`${this.props.year}-${this.props.month}-${this.props.day}`}
        ></input>
        <div className="dateSelectorType">{this.props.type}</div>
        <div>
          <div
            className="dateSelectorDay"
            id={`dateSelectorDay${this.props.type}`}
            onClick={this.showThePicker}
          >
            {this.props.month}
          </div>
          <div className="dateSelectorDivider">/</div>
          <div
            className="dateSelectorMonth"
            id={`dateSelectorMonth${this.props.type}`}
            onClick={this.showThePicker}
          >
            {this.props.day}
          </div>
        </div>
        <div
          className="dateSelectorYear"
          id={`dateSelectorYear${this.props.type}`}
          onClick={this.showThePicker}
        >
          {this.props.year}
        </div>
      </div>
    );
  }
}
