import './AppMain.css';
import './MainUtils.css';
import Gallivibe from './Gallivibe';
import TermsOfUse from './ToSAndPrivacyPolicy/TermsOfUse';
import PrivacyPolicy from './ToSAndPrivacyPolicy/PrivacyPolicy';
import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiCircularProgress: {
			styleOverrides: {
				colorPrimary: {
					color: 'var(--accentColorLight)',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					'&.Mui-checked': {
						color: 'var(--accentColorLight)',
						'+ .MuiSwitch-track': {
							backgroundColor: 'var(--accentColorLight)',
						},
					},
				},
			},
		},
	},
});

function App() {
	useEffect(() => {
		// Get the computed style of the root element
		const rootStyle = getComputedStyle(document.documentElement);

		// Extract the value of the CSS variable
		const primaryBackgroundColorLight = rootStyle
			.getPropertyValue('--primaryBackgroundColorLight')
			.trim();

		// Set the content attribute of the meta tag with name "theme-color"
		const metaThemeColor = document.querySelector('meta[name="theme-color"]');
		if (metaThemeColor) {
			metaThemeColor.setAttribute('content', primaryBackgroundColorLight);
		}
	}, []); // The empty dependency array ensures this effect runs once when the component mounts

	// State to keep track of the content height
	const [contentHeight, setContentHeight] = useState(160);
	const [isDisplayingGallivibe, setIsDisplayingGallivibe] = useState(true);
	const [isDisplayingPrivacyPolicy, setIsDisplayingPrivacyPolicy] =
		useState(false);
	const [isDisplayingTermsOfUse, setIsDisplayingTermsOfUse] = useState(false);
	const appBodyRef = useRef(null);

	// Function to update the content height
	const updateContentHeight = (entries) => {
		const contentElement = entries[0].target;
		const { height } = contentElement.getBoundingClientRect();
		setContentHeight(height);

		window.scrollBy(0, 0);
		// const gallivibeLogo = document.getElementById('gallivibeLogo');
		// gallivibeLogo.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	// Update the content height on mount and whenever the content changes
	useEffect(() => {
		const appBodyElement = appBodyRef.current;
		if (!appBodyElement) return;

		// Create a new ResizeObserver and attach it to the .App-body element
		const resizeObserver = new ResizeObserver(updateContentHeight);
		resizeObserver.observe(appBodyElement);

		// Cleanup: disconnect the ResizeObserver when the component unmounts
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	// Move the .App-body element using inline styles
	const appBodyStyle = {
		height: `calc(50vh - ${Math.min(47, contentHeight * 0.082)}vh)`,
		transition: 'height 0.3s ease',
	};

	return (
		<ThemeProvider theme={theme}>
			<div className="App" id="appContainer">
				<div id="dynamicDiv" style={appBodyStyle}></div>
				<header className="App-header">
					<h1 id="gallivibeLogo">Gallivibe</h1>
				</header>
				<br />
				<div className="App-body" ref={appBodyRef}>
					<Gallivibe isDisplayingGallivibe={isDisplayingGallivibe}></Gallivibe>
					{isDisplayingPrivacyPolicy && (
						<PrivacyPolicy
							goBack={(e) => {
								setIsDisplayingGallivibe(true);
								setIsDisplayingPrivacyPolicy(false);
								setIsDisplayingTermsOfUse(false);
							}}
						></PrivacyPolicy>
					)}
					{isDisplayingTermsOfUse && (
						<TermsOfUse
							goBack={(e) => {
								setIsDisplayingGallivibe(true);
								setIsDisplayingPrivacyPolicy(false);
								setIsDisplayingTermsOfUse(false);
							}}
						></TermsOfUse>
					)}
					<footer className="App-footer">
						<h4 className="gallivibe-company">
							© Gallivibe, Inc
							<br />
							<a
								href="#"
								onClick={(e) => {
									setIsDisplayingGallivibe(false);
									setIsDisplayingPrivacyPolicy(false);
									setIsDisplayingTermsOfUse(true);
								}}
							>
								Terms of Use
							</a>{' '}
							|{' '}
							<a
								href="#"
								onClick={(e) => {
									setIsDisplayingGallivibe(false);
									setIsDisplayingPrivacyPolicy(true);
									setIsDisplayingTermsOfUse(false);
								}}
							>
								Privacy Policy
							</a>
							<br />
							<a href="mailto:hello@gallivibe.com">📧 hello@gallivibe.com</a>
						</h4>
					</footer>
				</div>
			</div>
		</ThemeProvider>
	);
}

export default App;
