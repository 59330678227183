import React, { Component } from 'react';
import DateTimeComponent from './SmallerComponents/DateTimeComponent';
import {
	fromFulltimestampToDate,
	dashesDateFormatToObject,
} from './../../../../utils';
import { Grow } from '@mui/material';

export default class TripTransportationAreaNewItemArrival extends Component {
	constructor(props) {
		super(props);
		if (this.props.defaultValue == null) {
			let fromDate = dashesDateFormatToObject(
				fromFulltimestampToDate(this.props.tripData.from_date),
			);
			this.state = {
				day: fromDate.day,
				month: fromDate.month,
				year: fromDate.year,
				time: '13:00',
				address: '',
			};
		} else {
			this.state = {
				day: this.props.defaultValue.date.day,
				month: this.props.defaultValue.date.month,
				year: this.props.defaultValue.date.year,
				time: this.props.defaultValue.time,
				address: this.props.defaultValue.address,
			};
		}
	}

	updateDate = (day, month, year) => {
		this.setState({ day: day, month: month, year: year }, () => this.select());
	};

	updateAddress = (address) => {
		this.setState({ address: address }, () => this.select());
	};

	updateTime = (time) => {
		this.setState({ time: time }, () => this.select());
	};

	select = () => {
		this.props.next({
			address: this.state.address,
			date: {
				day: this.state.day,
				month: this.state.month,
				year: this.state.year,
			},
			time: this.state.time,
		});
	};

	render() {
		return (
			<Grow in={this.props.selectingArrivalDetails}>
				<div
					style={{
						display: this.props.selectingArrivalDetails ? 'block' : 'none',
					}}
				>
					<br />
					<DateTimeComponent
						day={this.state.day}
						month={this.state.month}
						year={this.state.year}
						time={this.state.time}
						address={this.state.address}
						updateDate={this.updateDate}
						updateAddress={this.updateAddress}
						updateTime={this.updateTime}
						dateTimeType={`Arrival`}
						onChange={this.select}
					></DateTimeComponent>
					{/* <button className="mainSubmitBtn" onClick={this.select}>
						Select
					</button> */}
				</div>
			</Grow>
		);
	}
}
