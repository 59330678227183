import './DateTimeComponent.css';
import React, { Component } from 'react';
import AddressAutocomplete from '../../../../AddressAutocomplete';
import DateSelector from '../../../../TripPlanning/SmallerComponents/DateSelector';

export default class DateTimeComponent extends Component {
  render() {
    return (
      <div className="dateTimeComponent">
        <div className="dateTimeComponentLeft">
          <div className="dateTimeComponentDate">
            <DateSelector
              updateTheDate={this.props.updateDate}
              type={` ${this.props.dateTimeType} `}
              year={this.props.year}
              month={this.props.month}
              day={this.props.day}
            ></DateSelector>
          </div>
          <div className="dateTimeComponentTime">
            <input
              type="time"
              value={this.props.time}
              onChange={(e) => this.props.updateTime(e.target.value)}
            />
          </div>
        </div>
        <div className="dateTimeComponentRight">
          <div className="dateTimeComponentAddress">
            <AddressAutocomplete
              placeholder={`${this.props.dateTimeType} address...`}
              address={this.props.address}
              updateAddress={this.props.updateAddress}
            ></AddressAutocomplete>
          </div>
        </div>
      </div>
    );
  }
}
