import { Grow } from '@mui/material';
import React, { Component } from 'react';
import {
	transformDateFormat,
	transportationCategories,
} from './../../../utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	AccessTime,
	ArrowBackIos,
	CalendarMonth,
	ConfirmationNumber,
	Map,
	NorthEast,
	Note,
	SouthEast,
	Check,
	Clear,
} from '@mui/icons-material';

export default class TripTransportationItemContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeleting: false,
		};
	}

	deleteItem = () => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append(
			'item_id',
			this.props.transportationItem.transportation_item_id,
		);

		fetch(`https://api.gallivibe.com/deleteLocationTransportationItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.removeTransportationItems(this.props.transportationItem);
					this.props.viewItems();
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		let item = this.props.transportationItem;
		return (
			<Grow in={true}>
				<div>
					<br />
					<div className="addingLodgingInfoTitle">
						<div onClick={this.props.viewItems}>
							<div className="backArrowBtn">
								<ArrowBackIos fontSize="x-small"></ArrowBackIos>
							</div>
							{item.name}
						</div>
						<div style={{ gap: '15px' }}>
							<div
								onClick={() => this.props.editTransportationItem(item)}
								style={{ display: 'flex', gap: '5px' }}
							>
								<EditIcon fontSize="x-small"></EditIcon>
								<div>Edit</div>
							</div>
						</div>
						<div
							onClick={() => this.setState({ isDeleting: true })}
							style={{ display: 'flex', gap: '5px' }}
						>
							<DeleteIcon fontSize="x-small"></DeleteIcon>
							<div>Delete</div>
						</div>
					</div>

					<hr />
					<div
						className="addLodgingArea lodgingInfoContent"
						style={{
							width: '100%',
							display: this.state.isDeleting ? 'none' : 'flex',
						}}
					>
						<Grow in={!this.state.isDeleting}>
							<div>
								<Grow in={true} timeout={500}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<NorthEast fontSize="xx-small"></NorthEast>
											</div>
											<div style={{ marginBottom: '5px' }}>Departure</div>
										</div>
										<div>
											<table className="itemContentMovingDetails">
												<tbody>
													<tr>
														<th>
															<div>
																<CalendarMonth fontSize="xx-small"></CalendarMonth>
															</div>
															<div>Date:</div>
														</th>
														<td>
															{transformDateFormat(item.departure_details.date)}
														</td>
													</tr>
													<tr>
														<th>
															<div>
																<AccessTime fontSize="xx-small"></AccessTime>
															</div>
															<div>Time:</div>
														</th>
														<td>{item.departure_details.time}</td>
													</tr>
													<tr>
														<th>
															<div>
																<Map fontSize="xx-small"></Map>
															</div>
															<div>Address:</div>
														</th>
														<td>
															<a
																href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
																	item.departure_details.address,
																)}`}
																target="_blank"
															>
																{item.departure_details.address}
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Grow>
								<Grow in={true} timeout={800}>
									<div style={{ width: '100%' }}>
										<div className="lodgingInfoContentLabel">
											<div>
												<SouthEast fontSize="xx-small"></SouthEast>
											</div>
											<div style={{ marginBottom: '5px' }}>Arrival</div>
										</div>
										<div>
											<table className="itemContentMovingDetails">
												<tbody>
													<tr>
														<th>
															<div>
																<CalendarMonth fontSize="xx-small"></CalendarMonth>
															</div>
															<div>Date:</div>
														</th>
														<td>
															{transformDateFormat(item.arrival_details.date)}
														</td>
													</tr>
													<tr>
														<th>
															<div>
																<AccessTime fontSize="xx-small"></AccessTime>
															</div>
															<div>Time:</div>
														</th>
														<td>{item.arrival_details.time}</td>
													</tr>
													<tr>
														<th>
															<div>
																<Map fontSize="xx-small"></Map>
															</div>
															<div>Address:</div>
														</th>
														<td>
															<a
																href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
																	item.arrival_details.address,
																)}`}
																target="_blank"
															>
																{item.arrival_details.address}
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Grow>

								<Grow in={true} timeout={1000}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<ConfirmationNumber fontSize="xx-small"></ConfirmationNumber>
											</div>
											<div>Confirmation:</div>
										</div>
										<div>{item.confirmation_code}</div>
									</div>
								</Grow>
								<Grow in={true} timeout={1100}>
									<div>
										<div className="lodgingInfoContentLabel">
											<div>
												<Note fontSize="xx-small"></Note>
											</div>
											<div>Note:</div>
										</div>
										{item.comments}
									</div>
								</Grow>
							</div>
						</Grow>
					</div>
					<div style={{ display: this.state.isDeleting ? 'block' : 'none' }}>
						<Grow in={this.state.isDeleting}>
							<div className="deletingTripPage">
								<h3>Are you sure that you would like to delete this item?</h3>
								<center>
									<div
										style={{
											width: '70%',
											display: 'flex',
											justifyContent: 'space-evenly',
											marginTop: '40px',
										}}
									>
										<div
											className="deleteItemOption"
											style={{ width: '100px', height: '100px' }}
											onClick={() => {
												this.setState({ isDeleting: false });
											}}
										>
											<div>
												<Clear></Clear>
											</div>
											<div>No</div>
										</div>
										<div
											className="deleteItemOption"
											style={{ width: '100px', height: '100px' }}
											onClick={(e) => {
												this.deleteItem();
											}}
										>
											<div>
												<Check></Check>
											</div>
											<div>Yes</div>
										</div>
									</div>
								</center>
							</div>
						</Grow>
					</div>
				</div>
			</Grow>
		);
	}
}
