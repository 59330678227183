import './TripMainPage.css';
import React, { Component } from 'react';
import TripLodgingArea from './TripLodgingArea/TripLodgingArea.js';
import { daysUntilDate, transformDateFormat } from './../../utils.js';
import TripTransportationArea from './TripTransportationArea/TripTransportationArea.js';
import Itinerary from './Itinerary/Itinerary';
import { CircularProgress, Grow, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import IosShareIcon from '@mui/icons-material/IosShare';
import TypingEffect from './../TripPlanning/SmallerComponents/TypingEffect';
import { Close } from '@mui/icons-material';

export default class TripMainPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			closeAI: false,
		};
	}

	render() {
		let trip = this.props.tripData;
		let settings = this.props.settings;
		let fromDate = transformDateFormat(trip.from_date);
		let toDate = transformDateFormat(trip.to_date);

		let tripAlreadyStarted = daysUntilDate(fromDate) <= 0;
		let tripAlreadyFinished = daysUntilDate(toDate) < 0;
		let tripIsOngoing = tripAlreadyStarted && !tripAlreadyFinished;

		return (
			<Grow in={!this.props.isDeletingTrip}>
				<div style={{ display: !this.props.isDeletingTrip ? 'block' : 'none' }}>
					{!tripAlreadyStarted ? (
						<div className="tripAnnouncement">
							Only <b>{daysUntilDate(fromDate)}</b> days left until this
							location.
						</div>
					) : (
						<div></div>
					)}

					<div
						className="TripArea"
						style={{ display: tripIsOngoing ? 'block' : 'none' }}
					>
						{/* <div className="mainSubmitBtn">Check In</div> */}
					</div>

					<div className={`TripArea Itinerary`}>
						<div className="tripAreaDesc">Itinerary</div>
						<div>
							<Itinerary
								token={this.props.token}
								adventure={this.props.adventure}
								tripData={trip}
								itinerary={this.props.itinerary}
								addToItinerary={this.props.addToItinerary}
								removeFromItinerary={this.props.removeFromItinerary}
								tripIsOngoing={tripIsOngoing}
							></Itinerary>
						</div>
					</div>
					{settings.AINextSteps && (
						<Grow in={!this.state.closeAI && settings.AINextSteps}>
							<div
								className="aiSuggestion"
								style={{
									display: !this.state.closeAI ? 'block' : 'none',
								}}
							>
								<div
									className="tripAreaDesc"
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div>Next steps</div>
									<div onClick={() => this.setState({ closeAI: true })}>
										<Close fontSize="x-small"></Close>
									</div>
								</div>
								<div className="aiSuggestionTxt">
									{this.props.aiSuggestion.length > 0 && (
										<TypingEffect
											normalText={` ${this.props.aiSuggestion}`}
											boldText=""
											onComplete={() => {}}
										></TypingEffect>
									)}
									{this.props.aiSuggestion.length == 0 && (
										<div style={{ textAlign: 'center' }}>
											<br />
											<CircularProgress></CircularProgress>
											<br />
											<br />
										</div>
									)}
								</div>
							</div>
						</Grow>
					)}

					{(settings.Transportation || settings.Lodging) && (
						<div className="TripArea TripAreaBasic">
							<div className="tripAreaDesc">Basic</div>
							<Grow in={true} timeout={700}>
								<div>
									{settings.Lodging && (
										<TripLodgingArea
											tripData={trip}
											adventure={this.props.adventure}
											lodgingItems={this.props.lodgingItems}
											token={this.props.token}
											addToLodgingItems={this.props.addToLodgingItems}
											removeFromLodgingItems={this.props.removeFromLodgingItems}
										></TripLodgingArea>
									)}
									{settings.Transportation && (
										<TripTransportationArea
											tripData={trip}
											adventure={this.props.adventure}
											transportationItems={this.props.transportationItems}
											addToTransportationItems={
												this.props.addToTransportationItems
											}
											removeTransportationItems={
												this.props.removeTransportationItems
											}
											token={this.props.token}
										></TripTransportationArea>
									)}
								</div>
							</Grow>
						</div>
					)}

					<div className="TripArea">
						<div className="tripAreaDesc">Other</div>
						<Grow in={true} timeout={700}>
							<div className="tripOthers">
								<div className="tripOthersItem" onClick={this.props.deleteTrip}>
									<div>
										<DeleteIcon fontSize="small"></DeleteIcon>
									</div>
									<div>Delete</div>
								</div>
								<div
									className="tripOthersItem"
									onClick={this.props.viewTripSettings}
								>
									<div>
										<SettingsIcon fontSize="small"></SettingsIcon>
									</div>
									<div>Settings</div>
								</div>
								<div className="tripOthersItem">
									<div>
										<IosShareIcon fontSize="small"></IosShareIcon>
									</div>
									<div>Share</div>
								</div>
							</div>
						</Grow>
					</div>
				</div>
			</Grow>
		);
	}
}
