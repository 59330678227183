import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { getDayDescription } from '../../../utils';

export default class ItineraryDailyBubble extends Component {
  render() {
    let date = new Date(this.props.date);
    return (
      <Grow in={true} timeout={700 + this.props.bubbleIndex * 200}>
        <div
          style={{
            marginRight: '3px',
            width: '95px',
          }}
        >
          <div
            className={`itineraryItem ${this.props.selected ? 'selected' : ''}`}
            onClick={(e) => {
              this.props.select(this.props.date);
            }}
            id={`Bubble${this.props.date}`}
          >
            <div className="itineraryDesc">
              {getDayDescription(this.props.date)}
            </div>
            <div className="itineraryDayAndMonth">
              <div className="itineraryMonth">
                {(date.getMonth() + 1).toString().padStart(2, '0')}
              </div>
              <div className="itinerarySeparator">/</div>
              <div className="itineraryDay">
                {date.getDate().toString().padStart(2, '0')}
              </div>
            </div>
          </div>
        </div>
      </Grow>
    );
  }
}
