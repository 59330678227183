import { Grow } from '@mui/material';
import React, { Component } from 'react';
import DateSelector from './SmallerComponents/DateSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default class SelectTravelDates extends Component {
	constructor(props) {
		super(props);

		const appendMidday = (dateStr) =>
			dateStr.replace('00:00:00 GMT', '12:00:00 GMT');

		const fromDate = new Date(appendMidday(this.props.adventure.date_start));
		const toDate = new Date(appendMidday(this.props.adventure.date_end));

		this.state = {
			from: {
				day: fromDate.getDate().toString().padStart(2, '0'),
				month: (fromDate.getMonth() + 1).toString().padStart(2, '0'),
				year: fromDate.getFullYear(),
			},
			to: {
				day: toDate.getDate().toString().padStart(2, '0'),
				month: (toDate.getMonth() + 1).toString().padStart(2, '0'),
				year: toDate.getFullYear(),
			},
		};
	}

	createTrip = () => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('destination_emoji', this.props.destinationEmoji);
		formData.append('destination_name', this.props.destinationName);
		formData.append(
			'start_date',
			`${this.state.from.month}/${this.state.from.day}/${this.state.from.year}`,
		);
		formData.append(
			'end_date',
			`${this.state.to.month}/${this.state.to.day}/${this.state.to.year}`,
		);

		fetch(`https://api.gallivibe.com/addLocationToAdventure`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.goToShowTrip(data.trip);
				}
			})
			.catch((error) => console.error(error));
	};

	updateFromDate = (day, month, year) => {
		this.setState({ from: { day: day, month: month, year: year } });

		const fromDate = new Date(`${year}-${month}-${day}`);
		const toDate = new Date(
			`${this.state.to.year}-${this.state.to.month}-${this.state.to.day}`,
		);

		if (toDate.getTime() < fromDate.getTime()) {
			this.updateToDate(day, month, year);
		}

		this.props.setFromTravelDate({
			day: day,
			month: month,
			year: year,
		});
	};
	updateToDate = (day, month, year) => {
		this.setState({ to: { day: day, month: month, year: year } });
		this.props.setToTravelDate({
			day: day,
			month: month,
			year: year,
		});
	};
	getCurrentDate = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
		const day = String(currentDate.getDate()).padStart(2, '0');

		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	};

	render() {
		return (
			<Grow in={this.props.isSelectingTravelDates}>
				<div
					style={{
						display: this.props.isSelectingTravelDates ? 'block' : 'none',
					}}
				>
					<div className="userAreaTitle">
						{this.props.destinationEmoji} {this.props.destinationName} -
						Adventure Planning
					</div>
					<hr />
					<div className="userAreaContent">
						Let's select the dates:
						<br />
						<br />
						<div className="fromAndToDateSelector">
							<DateSelector
								type="From"
								year={this.state.from.year}
								month={this.state.from.month}
								day={this.state.from.day}
								updateTheDate={this.updateFromDate}
								minDate={this.getCurrentDate()}
								//       maxDate={}
							></DateSelector>
							 
							<div className="fromToArrowContainer">
								<ArrowForwardIcon></ArrowForwardIcon>
							</div>
							<DateSelector
								type="To"
								year={this.state.to.year}
								month={this.state.to.month}
								day={this.state.to.day}
								updateTheDate={this.updateToDate}
								minDate={`${this.state.from.year}-${this.state.from.month}-${this.state.from.day}`}
							></DateSelector>
						</div>
						<br />
					</div>
					{/* <hr /> */}
					<div
						className="mainSubmitBtn"
						onClick={this.createTrip}
						style={{ width: '95%' }}
					>
						Save Trip
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goToSelectDestination}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon> Select
						Destination
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
