import './NewActivity.css';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import NewActivitySelectActivityType from './NewActivitySelectActivityType';
import NewActivitySelectAddress from './NewActivitySelectAddress';
import NewActivityTimeSelection from './NewActivityTimeSelection';
import { AccessTime, ArrowBackIos, Category, Map } from '@mui/icons-material';
import {
	militaryToStandardTime,
	fromFulltimestampToDate,
	normalToMilitary,
} from '../../../../utils';

export default class NewActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddingTime: true,
			isSelectingActivityType: false,
			isSelectingAddress: false,
			startTime: '12:00',
			endTime: '12:30',
			activityAddress: '',
			selectedActivity: null,
			activityTypes: [],
			activitiesFetched: false,
			isExistingItem: false,
			activityName: '',
			activityEmoji: '',
		};
	}

	setActivity = (activityName, activityEmoji) => {
		this.setState({ activityName: activityName, activityEmoji: activityEmoji });
	};

	loadActivityTypes = () => {
		if (this.state.activityTypes.length > 0) {
			return;
		}
		fetch(`https://api.gallivibe.com/getActivities`)
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.activities) {
					let activities = [];

					const entries = Object.entries(data.activities);
					for (const [key, value] of entries) {
						activities.push({
							id: key,
							activity: value.type,
							emoji: value.emoji,
						});
					}

					this.setState({ activityTypes: activities, activitiesFetched: true });
				} else {
					// Delete the token, it's not valid.
					// this.setState({ isUserAuthenticated: false });
				}
			})
			.catch((error) => console.error(error));
	};

	selectTime = (startTime, endTime) => {
		this.setState({
			startTime: startTime,
			endTime: endTime,
		});
	};

	backToTimeSelection = () => {
		this.setState({
			isAddingTime: !this.state.isAddingTime,
			isSelectingActivityType: false,
			isSelectingAddress: false,
		});
	};

	backToActivitySelection = () => {
		this.setState({
			isAddingTime: false,
			isSelectingActivityType: !this.state.isSelectingActivityType,
			isSelectingAddress: false,
		});
	};

	componentDidMount() {
		this.loadActivityTypes();
		if (this.props.existingItem) {
			let existingItem = this.props.existingItem;
			this.setState({
				isExistingItem: true,
				startTime: normalToMilitary(existingItem.start_time),
				endTime: normalToMilitary(existingItem.end_time),
				activityAddress: existingItem.address,
				selectedActivity: existingItem.activity.id,
				activityName: existingItem.activity.name,
				activityEmoji: existingItem.activity.emoji,
			});
		}
	}

	saveNewActivity = () => {
		if (this.state.activityName == '' || this.state.activityEmoji == '') {
			return;
		}

		if (this.state.isExistingItem) {
			this.updateActivity();
			return;
		}

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('item_date', fromFulltimestampToDate(this.props.selected));
		formData.append(
			'item_start_time',
			militaryToStandardTime(this.state.startTime),
		);
		formData.append(
			'item_end_time',
			militaryToStandardTime(this.state.endTime),
		);
		formData.append('activity_type', this.state.activityName);
		formData.append('activity_emoji', this.state.activityEmoji);

		formData.append('item_name', '');
		formData.append('item_address', this.state.activityAddress);

		fetch(`https://api.gallivibe.com/addLocationItineraryItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.addToItinerary(
						fromFulltimestampToDate(this.props.selected),
						data.item,
					);
					this.props.backToItems();
				}
			})
			.catch((error) => console.error(error));
	};

	updateActivity = () => {
		if (this.state.activityName == '' || this.state.activityEmoji == '') {
			return;
		}

		let existingItem = this.props.existingItem;

		let activity = {
			item_date: fromFulltimestampToDate(this.props.selected),
			item_address: this.state.activityAddress,
			item_start_time: militaryToStandardTime(this.state.startTime),
			item_end_time: militaryToStandardTime(this.state.endTime),
			activity_type: this.state.activityName,
			activity_emoji: this.state.activityEmoji,
		};

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('item_id', existingItem.id);
		formData.append('update_fields', JSON.stringify(activity));
		fetch('https://api.gallivibe.com/updateLocationItineraryItem', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				this.props.softRemoveFromItinerary(
					fromFulltimestampToDate(existingItem.item_date),
					existingItem,
				);
				this.props.addToItinerary(
					fromFulltimestampToDate(data.item.item_date),
					data.item,
				);
				this.props.backToItems();
			})
			.catch((error) => {
				console.error('Error occurred:', error);
			});
	};

	render() {
		let activityStr = `${this.state.activityEmoji} ${this.state.activityName}`;

		if (activityStr == '') {
			activityStr = <span style={{ fontSize: 'small' }}>Required</span>;
		}

		return (
			<Grow in={this.props.isAddingNewItem}>
				<div>
					<div
						className={`newActivitySection ${
							this.state.isAddingTime ? 'sectionSelected' : ''
						}`}
					>
						<div onClick={this.backToTimeSelection}>
							<div>
								<div>
									<AccessTime fontSize="x-small"></AccessTime>
								</div>
								<div>Time</div>
							</div>
							<div>
								{militaryToStandardTime(this.state.startTime)} -{' '}
								{militaryToStandardTime(this.state.endTime)}
							</div>
						</div>
						<div>
							<NewActivityTimeSelection
								startTime={this.state.startTime}
								endTime={this.state.endTime}
								isAddingTime={this.state.isAddingTime}
								selectTime={this.selectTime}
							></NewActivityTimeSelection>
						</div>
					</div>
					<div
						className={`newActivitySection ${
							this.state.isSelectingActivityType ? 'sectionSelected' : ''
						}`}
					>
						<div onClick={this.backToActivitySelection}>
							<div>
								<div>
									<Category fontSize="x-small"></Category>
								</div>
								<div>Type</div>
							</div>
							<div>{activityStr}</div>
						</div>

						<div>
							<NewActivitySelectActivityType
								activityName={this.state.activityName}
								isSelectingActivityType={this.state.isSelectingActivityType}
								activities={this.state.activityTypes}
								activitiesFetched={this.state.activitiesFetched}
								backToTimeSelection={this.backToTimeSelection}
								activityTimes={{
									startTime: this.state.startTime,
									endTime: this.state.endTime,
								}}
								setActivity={this.setActivity}
								selectActivity={(activityType) =>
									this.setState({
										selectedActivity: activityType,
										isSelectingAddress: true,
										isSelectingActivityType: false,
									})
								}
							></NewActivitySelectActivityType>
						</div>
					</div>
					<div
						className={`newActivitySection ${
							this.state.isSelectingAddress ? 'sectionSelected' : ''
						}`}
					>
						<div
							onClick={() =>
								this.setState({
									isSelectingAddress: !this.state.isSelectingAddress,
									isSelectingActivityType: false,
									isAddingTime: false,
								})
							}
						>
							<div>
								<div>
									<Map fontSize="x-small"></Map>
								</div>
								<div>Address</div>
							</div>
							<div>{this.state.activityAddress}</div>
						</div>
						<div>
							<NewActivitySelectAddress
								isSelectingAddress={this.state.isSelectingAddress}
								activityAddress={this.state.activityAddress}
								setActivityAddress={(newAddress) =>
									this.setState({ activityAddress: newAddress })
								}
							></NewActivitySelectAddress>
						</div>
					</div>
					<div
						className={`${
							this.state.activityName != '' && this.state.activityEmoji != ''
								? 'mainSubmitBtn'
								: 'mainBtn'
						} SaveItemBtn`}
						onClick={this.saveNewActivity}
					>
						Save Item
					</div>
				</div>
			</Grow>
		);
	}
}
