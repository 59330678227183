import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { fromFulltimestampToDate } from './../../../../utils';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export default class ItineraryDailyDisplayRightColumnDelete extends Component {
  render() {
    return (
      <Grow in={this.props.isDeleting}>
        <div className="deleteItemContent">
          Are you sure that you would like to delete this item?
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '20px',
            }}
          >
            <div className="deleteItemOption" onClick={this.props.backToItems}>
              <div>
                <ClearIcon fontSize="small"></ClearIcon>
              </div>
              <div>No</div>
            </div>
            <div
              className="deleteItemOption"
              onClick={(e) =>
                this.props.removeFromItinerary(
                  fromFulltimestampToDate(this.props.selected),
                  this.props.viewingItem,
                  true,
                )
              }
            >
              <div>
                <CheckIcon fontSize="small"></CheckIcon>
              </div>
              <div>Yes</div>
            </div>
          </div>
        </div>
      </Grow>
    );
  }
}
