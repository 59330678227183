import React, { Component } from 'react';
import { validateEmailPattern } from './../utils.js';

export default class PhoneNumberNotInvited extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shakeEmail: false,
		};
	}

	shakeEmailField = () => {
		this.setState({ shakeEmail: true });
		setTimeout(
			function () {
				this.setState({ shakeEmail: false });
			}.bind(this),
			500,
		);
	};

	inviteMeLaterPlease = () => {
		if (!validateEmailPattern(this.state.email)) {
			this.shakeEmailField();
		}
	};

	render() {
		return (
			<div
				id="notInvitedMessage"
				style={{
					display: this.props.isNotInvited ? 'block' : 'none',
					fontSize: 'medium',
					backgroundColor: 'var(--lowkeyBtnColorLight)',
					borderRadius: '10px',
					padding: '20px 20px 20px 20px',
				}}
			>
				Thank you for your interest in Gallivibe! 🏝️
				<br />
				<br />
				At this time we're invite only.
				<br />
				<br />
				Let us know how to contact you below and we'll invite you soon 😉
				<br />
				<br />
				<input
					placeholder="E-mail address..."
					id="emailForWaitlist"
					type="email"
					autoComplete="off"
					className={`mainTxtInput ${this.state.shakeEmail ? 'shake' : ''}`}
					onChange={(event) => this.setState({ email: event.target.value })}
				></input>
				<button className="mainSubmitBtn" onClick={this.inviteMeLaterPlease}>
					Add to Waitlist
				</button>
			</div>
		);
	}
}
