import { Grow, LinearProgress, Typography } from '@mui/material';
import React, { Component } from 'react';
import axios from 'axios';
import LinearProgressWithLabel from './../../../TripPlanning/SmallerComponents/LinearProgressWithLabel';
import ImageCompressor from 'image-compressor.js';

export default class TripLodgingAreaLoadFromScreenshot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: null,
			isProcessingImage: false,
			isUploadingImage: false,
			isComplete: false,
			response: '',
			uploadProgress: 0,
		};
	}

	handleProcessedResponse = (response) => {
		let start_date = response.hasOwnProperty('start_date')
			? response.start_date
			: '';
		let end_date = response.hasOwnProperty('end_date') ? response.end_date : '';
		let address = response.hasOwnProperty('address') ? response.address : '';
		let comment = response.hasOwnProperty('comment') ? response.comment : '';
		let title = response.hasOwnProperty('title') ? response.title : '';
		let confirmation_code = response.hasOwnProperty('confirmation_code')
			? response.confirmation_code
			: '';

		this.props.saveLodgingItemFromScreenshot(
			start_date,
			end_date,
			address,
			title,
			confirmation_code,
			comment,
		);
	};

	handleImageChange = (e) => {
		this.setState({ image: e.target.files[0] });
	};

	handleUpload = () => {
		// Set state to indicate uploading has started
		this.setState({
			isUploadingImage: true,
			isProcessingImage: false,
			uploadProgress: 0,
		});

		// Create a new instance of the image compressor
		new ImageCompressor(this.state.image, {
			quality: 0.6, // Adjust this value for desired quality, 0.6 is 60% quality
			success: (compressedResult) => {
				const formData = new FormData();
				formData.append('image', compressedResult); // Upload compressed image

				axios
					.post('https://api.gallivibe.com/analyze-image', formData, {
						onUploadProgress: (progressEvent) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total,
							);
							if (percentCompleted === 100) {
								this.setState({
									uploadProgress: percentCompleted,
									isProcessingImage: true,
								});
							} else {
								this.setState({ uploadProgress: percentCompleted });
							}
						},
					})
					.then((response) => {
						this.handleProcessedResponse(response.data);
						this.setState({
							isUploadingImage: false,
							isProcessingImage: false,
							isComplete: true,
							response: JSON.stringify(response.data),
						});
					})
					.catch((error) => {
						console.error('Error uploading image:', error);
						this.setState({
							isUploadingImage: false,
							isProcessingImage: false,
						});
					});
			},
			error(err) {
				console.error('Error during compression:', err.message);
				this.setState({ isUploadingImage: false, isProcessingImage: false });
			},
		});
	};

	render() {
		return (
			<Grow in={this.props.isLoadingFromScreenshot}>
				<div
					style={{
						display: this.props.isLoadingFromScreenshot ? 'block' : 'none',
					}}
				>
					<center>
						<br />
						<div
							style={{
								display:
									!this.state.isProcessingImage &&
									!this.state.isUploadingImage &&
									!this.state.isComplete
										? 'block'
										: 'none',
							}}
						>
							<Typography>
								Upload a screenshot of your lodging reservation.
							</Typography>
						</div>

						<br />
						<div>
							{this.state.isUploadingImage && !this.state.isProcessingImage && (
								<div style={{ width: '90%' }}>
									Uploading ...
									<br />
									<br />
									<LinearProgressWithLabel value={this.state.uploadProgress} />
									<br />
									<br />
								</div>
							)}
							{this.state.isProcessingImage && (
								<div style={{ width: '90%' }}>
									Processing ...
									<br />
									<br />
									<LinearProgress
										sx={{
											backgroundColor: 'var(--lowkeyBtnColorLight)',
											'& .MuiLinearProgress-bar': {
												backgroundColor: 'var(--accentColorLight)',
											},
										}}
									/>
									<br />
									<br />
									<br />
								</div>
							)}
							{this.state.isComplete && (
								<div>One second...{/*this.state.response*/}</div>
							)}
							<div
								style={{
									display:
										this.state.isUploadingImage ||
										this.state.isProcessingImage ||
										this.state.isComplete
											? 'none'
											: 'block',
								}}
							>
								<input
									type="file"
									onChange={this.handleImageChange}
									className="fileInput"
								/>
								<br />
								<br />
								<div style={{ fontSize: 'small' }}>
									Note: After saving, ensure all the information is accurate
								</div>

								<br />
								<button className="mainSubmitBtn" onClick={this.handleUpload}>
									Load from Screenshot
								</button>
							</div>
						</div>
					</center>
				</div>
			</Grow>
		);
	}
}
