import React, { Component } from 'react';
import AddressAutocompleteItem from './../../../AddressAutocompleteItem';

export default class NewActivitySelectAddress extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			suggestions: [],
			timeout: null,
			selectedSuggestion: null,
		};
	}

	handleOnKeyUp = (event) => {
		clearTimeout(this.state.timeout);
		this.setState({
			timeout: setTimeout(() => {
				this.autocompleteAddress(event);
			}, 500),
		});
	};

	selectSuggestion = (selectSuggestionID, selectedAddress) => {
		if (selectSuggestionID == this.state.selectedSuggestion) {
			this.setState({ selectedSuggestion: null });
		} else {
			this.setState(
				{
					selectedSuggestion: selectSuggestionID,
					// address: selectedAddress,
				},
				() => this.props.setActivityAddress(selectedAddress),
			);
		}
	};

	autocompleteAddress = (event) => {
		const formData = new FormData();
		formData.append('address', event.target.value);
		// formData.append(
		// 	'activity_type',
		// 	this.props.activities[this.props.activityType].id,
		// );

		fetch('https://api.gallivibe.com/autocompleteAddress', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.suggestions) {
					this.setState({
						selectedSuggestion: null,
						suggestions: data.suggestions,
					});
				} else {
					this.setState({ selectedSuggestion: null, suggestions: [] });
				}
			})
			.catch((error) => {
				console.error('Error occurred:', error);
				this.setState({ selectedSuggestion: null, suggestions: [] });
			});
	};

	render() {
		return (
			<div className="newItineraryItem">
				<div>
					<input
						type="text"
						placeholder="Activity Address..."
						value={this.props.activityAddress}
						onChange={(e) => {
							this.props.setActivityAddress(e.target.value);
							this.handleOnKeyUp(e);
						}}
						className="mainTxtInput"
					/>
					{this.state.suggestions.length > 0 && (
						<div className="locationSearchResults">
							{this.state.suggestions.map((suggestion, index) => {
								return (
									<AddressAutocompleteItem
										itemIndex={index}
										suggestionId={suggestion.id}
										address={suggestion.address}
										key={suggestion.id}
										isSelected={this.state.selectedSuggestion == suggestion.id}
										selectSuggestion={this.selectSuggestion}
									></AddressAutocompleteItem>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}
}
