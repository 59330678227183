import { Lock } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import Setting from './Setting';

export default class TripSettings extends Component {
	constructor(props) {
		super(props);
		let tripSettings = this.props.settings;
		this.state = {
			AINextSteps: tripSettings.AINextSteps,
			Transportation: tripSettings.Transportation,
			Lodging: tripSettings.Lodging,
		};
	}

	render() {
		return (
			<Grow in={this.props.isViewingSettings}>
				<div className="tripSettings">
					<div>Enable or Disable different sections for your location.</div>
					<br />

					<Setting
						emoji={`🪄`}
						settingName={`AI Next Steps`}
						settingStatus={this.state.AINextSteps}
						toggleSetting={(e) => {
							this.props.toggleAINextSteps();
							this.setState({ AINextSteps: !this.state.AINextSteps });
						}}
					></Setting>
					<Setting
						emoji={`🏠`}
						settingName={`Lodging`}
						settingStatus={this.state.Lodging}
						toggleSetting={(e) => {
							this.props.toggleLodging();
							this.setState({ Lodging: !this.state.Lodging });
						}}
					></Setting>
					<Setting
						emoji={`🛫`}
						settingName={`Transportation`}
						settingStatus={this.state.Transportation}
						toggleSetting={(e) => {
							this.props.toggleTransportation();
							this.setState({ Transportation: !this.state.Transportation });
						}}
					></Setting>

					<br />
					<hr />
					<div
						className="tripSetting"
						style={{ justifyContent: 'center', marginBottom: '0px' }}
					>
						<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<div
								style={{
									color: 'var(--accentColorLight)',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Lock fontSize="x-small"></Lock>
							</div>
							<div>Data encrypted with a 128-bit AES key.</div>
						</div>
					</div>
				</div>
			</Grow>
		);
	}
}
