import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

export default class NewActivityTimeSelectionSlider extends Component {
	constructor(props) {
		super(props);
		this.state = this.calculateStateFromProps(props);
	}

	// Helper function to format the slider value to HH:mm format
	valuetext(value) {
		const hours = Math.floor(value / 60);
		const minutes = value % 60;
		return `${hours.toString().padStart(2, '0')}:${minutes
			.toString()
			.padStart(2, '0')}`;
	}

	// Handle slider value change
	handleChange = (event, newValue) => {
		this.setState({ eventDuration: newValue });
		// Assuming you want to lift state up to parent component
		if (this.props.onDurationChange) {
			this.props.onDurationChange(this.valuetext(newValue));
		}
	};

	formatDuration = (durationInMinutes) => {
		const hours = Math.floor(durationInMinutes / 60);
		const minutes = durationInMinutes % 60;
		return `${hours}h ${minutes}min`;
	};

	componentDidUpdate(prevProps) {
		// React to changes in either startTime or endTime
		if (
			this.props.startTime !== prevProps.startTime ||
			this.props.endTime !== prevProps.endTime
		) {
			this.setState(this.calculateStateFromProps(this.props));
		}
	}

	calculateStateFromProps(props) {
		// Convert startTime and endTime to total minutes
		const startTimeHours = parseInt(props.startTime.split(':')[0], 10);
		const startTimeMinutes = parseInt(props.startTime.split(':')[1], 10);
		const endTimeHours = parseInt(props.endTime.split(':')[0], 10);
		const endTimeMinutes = parseInt(props.endTime.split(':')[1], 10);
		const startTotalMinutes = startTimeHours * 60 + startTimeMinutes;
		const endTotalMinutes = endTimeHours * 60 + endTimeMinutes;
		const maxDuration = 240; // Maximum duration of 4 hours in minutes
		const maxEndTime = Math.min(startTotalMinutes + maxDuration, 23 * 60 + 59);

		// Ensure that the endTime from props is within the valid range
		const eventDuration = endTotalMinutes;

		return {
			eventDuration: eventDuration,
			maxEndTime: maxEndTime,
		};
	}

	render() {
		const { startTime } = this.props;

		const startHours = parseInt(startTime.split(':')[0], 10);
		const startMinutes = parseInt(startTime.split(':')[1], 10);
		const startTotalMinutes = startHours * 60 + startMinutes;
		const { eventDuration, maxEndTime } = this.state;

		// Calculate the duration difference
		const duration = eventDuration - startTotalMinutes;
		const durationHours = Math.floor(duration / 60);
		const durationMinutes = duration % 60;

		if (duration < 0) {
			return <div></div>;
		}

		return (
			<div>
				<Typography id="event-duration-slider" gutterBottom>
					Activity Duration: {`${durationHours}h ${durationMinutes}min`}
				</Typography>
				<Slider
					aria-label="Event Duration"
					value={this.state.eventDuration}
					getAriaValueText={this.valuetext}
					valueLabelDisplay="off"
					step={15} // Set step for increments (e.g., 1 for 1 minute)
					marks
					min={startTotalMinutes}
					max={maxEndTime}
					onChange={this.handleChange}
					valueLabelFormat={() => `${durationHours}h ${durationMinutes}min`}
					sx={{
						'& .MuiSlider-thumb': {
							color: 'var(--accentColorLight)', // Thumb color
							boxShadow: `0px 0px 0px 8px var(--accentColorShadow)`,
						},
						'& .MuiSlider-track': {
							color: 'var(--accentColorLight)', // Track color
						},
						'& .MuiSlider-rail': {
							color: 'var(--accentColorLight, #ccc)', // Rail color with default fallback
						},
						'& .MuiSlider-mark': {
							color: 'var(--primaryTxtColorLight, #ccc)', // Mark color with default fallback
						},
						'& .MuiSlider-markLabel': {
							color: 'var(--accentColorLight)', // Mark label color with default fallback
						},
						'& .MuiSlider-valueLabel': {
							color: 'var(--primaryTxtColorLight, #ccc)', // Value label color
						},
					}}
				/>
			</div>
		);
	}
}
