import './ScheduledTripsItem.css';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { transformDateFormat, daysUntilDate } from './../../../utils.js';
import { ArrowForwardIos } from '@mui/icons-material';

export default class ScheduledAdventuresItem extends Component {
	constructor(props) {
		super(props);
		this.state = { isClicked: false };
	}

	render() {
		let adventure = this.props.adventure;
		let fromDate = transformDateFormat(adventure.date_start);
		let toDate = transformDateFormat(adventure.date_end);
		return (
			<Grow
				in={true}
				timeout={
					100 + this.props.itemIndex * 250 < 1000
						? 100 + this.props.itemIndex * 250
						: 1000
				}
			>
				<div>
					<div
						className={`scheduledTripsItem ${
							this.state.isClicked ? 'clicked' : ''
						} ${this.props.wHighlight ? 'wHighlight' : ''}`}
						style={
							this.props.wHighlight
								? { width: '93%', position: 'relative', left: '-5px' }
								: {}
						}
						onMouseDown={(e) => {
							this.setState({ isClicked: true });
						}}
						onMouseOut={(e) => {
							this.setState({ isClicked: false });
						}}
						onTouchStart={(e) => {
							this.setState({ isClicked: true });
						}}
						onTouchEnd={(e) => {
							this.setState({ isClicked: false });
						}}
						onClick={(e) => {
							this.props.goToShowAdventure(adventure);
						}}
					>
						<div className="scheduledTripsItemEmoji">{adventure.emoji}</div>
						<div className="scheduledTripsItemText">
							<div className="scheduledTripsItemDestination">
								{adventure.title}
							</div>
							<div className="scheduledTripsItemDates">{`${fromDate} - ${toDate}`}</div>
						</div>
						<div className="scheduledTripsItemTimeLeft">
							{daysUntilDate(fromDate) > 0
								? `T - ${daysUntilDate(fromDate)} Days`
								: -1 * daysUntilDate(toDate) > 0
								? `${-1 * daysUntilDate(toDate)} days ago`
								: ``}
						</div>
						<div>
							{this.props.wHighlight ? (
								``
							) : (
								<ArrowForwardIos fontSize="8px"></ArrowForwardIos>
							)}
						</div>
					</div>
				</div>
			</Grow>
		);
	}
}
