import React, { Component } from 'react';
import { Grow } from '@mui/material';

export default class LandingPhoneNumberCheck extends Component {
  constructor(props) {
    super(props);
    this.state = { shakeInput: false };
  }

  handleCheckPhoneNumber = () => {
    if (this.props.phoneNumber.length !== 14) {
      this.setState({ shakeInput: true });
      setTimeout(
        function () {
          this.setState({ shakeInput: false });
        }.bind(this),
        500,
      );
      return;
    }

    fetch(
      `https://api.gallivibe.com/phoneNumberExists?phoneNumber=${this.props.phoneNumber}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.ok == true) {
          if (data.status == 'not_invited_yet') {
            this.props.goToNotInvitedYet();
          }
          if (data.status == 'is_invited') {
            this.props.goToCodeVerification();
            this.props.updateMessage(
              'We sent a verification code to your phone, please enter the code when you receive it',
            );
          }
          if (data.status == 'is_user') {
            this.props.goToSigningIn();
            this.props.updateMessage('✌️ Welcome back, please sign in!');
          }
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <Grow in={this.props.isLandingPhoneNumberCheck}>
        <div
          style={{
            display: this.props.isLandingPhoneNumberCheck ? 'block' : 'none',
          }}
        >
          <input
            placeholder="Phone number..."
            className={`mainTxtInput ${this.state.shakeInput ? 'shake' : ''}`}
            type="tel"
            value={this.props.phoneNumber}
            onChange={(event) =>
              this.props.updatePhoneNumber(event.target.value)
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') this.handleCheckPhoneNumber();
            }}
            autoComplete="off"
          ></input>

          <button
            className="mainSubmitBtn"
            onClick={this.handleCheckPhoneNumber}
          >
            Continue
          </button>
        </div>
      </Grow>
    );
  }
}
