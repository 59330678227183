import { Collapse } from '@mui/material';
import React, { Component } from 'react';

export default class CodeVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Input Values
			verificationCode: '',

			// Actions
			shakeNumberFields: false,
		};
	}

	handleInputChange = (index, e) => {
		const codeLength = 6;
		const nextInput = document.getElementById(`input-${index + 1}`);
		const prevInput = document.getElementById(`input-${index - 1}`);
		// const currentInput = document.getElementById(`input-${index}`);

		const currentValue = e.target.value;
		const sanitizedValue = currentValue.replace(/[^0-9]/g, '');

		e.target.value = sanitizedValue;

		if (e.target.value.length === 1) {
			if (nextInput) {
				nextInput.focus();
			}
		}

		// update the state with the current code
		let currentCode = '';
		for (let localIndex = 0; localIndex < codeLength; localIndex++) {
			currentCode += document.getElementById(`input-${localIndex}`).value;
		}
		this.setState({ verificationCode: currentCode });
		this.props.updateVerificationCode(currentCode);
	};

	handleKeyDown = (index, e) => {
		const nextInput = document.getElementById(`input-${index + 1}`);
		const prevInput = document.getElementById(`input-${index - 1}`);

		if (e.key === 'ArrowLeft' && prevInput) {
			e.preventDefault();
			prevInput.focus();
		} else if (
			e.target.value.length === 1 &&
			e.key === 'ArrowRight' &&
			nextInput
		) {
			nextInput.focus();
		} else if (
			e.target.value.length === 0 &&
			(e.key === 'Backspace' || e.key === 'Delete')
		) {
			if (prevInput) {
				prevInput.focus();
			}
		} else if (
			e.key === 'Enter' &&
			!nextInput &&
			this.state.verificationCode.length === 6
		) {
			this.verifyPhoneNumber();
		}
	};

	verifyPhoneNumber = () => {
		const { verificationCode } = this.state;
		fetch(
			`https://api.gallivibe.com/verifyPhoneCode?phoneNumber=${this.props.phoneNumber}&code=${verificationCode}`,
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.ok == true) {
					if (data.status == 'valid_code') {
						this.props.goToAccountCreation();
						this.props.updateMessage(
							'We just need a little more information to create your account',
						);
					} else {
						this.props.updateMessage(
							'Invalid Code. Make sure all the numbers are correct',
						);
						this.setState({ shakeNumberFields: true });
						setTimeout(
							function () {
								this.setState({ shakeNumberFields: false });
							}.bind(this),
							500,
						);
						for (let localIndex = 0; localIndex < 6; localIndex++) {
							document.getElementById(`input-${localIndex}`).value = '';
						}
					}
				} else {
					this.props.updateMessage(
						'Something went badly, please refresh the app and try again',
					);
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		return (
			<Collapse in={this.props.isVerifyingCode}>
				<div
					className="verificationCode"
					style={{ display: this.props.isVerifyingCode ? 'flex' : 'none' }}
				>
					{[...Array(6)].map((_, index) => (
						<input
							key={index}
							id={`input-${index}`}
							type="number"
							min={1}
							max={9}
							maxLength={1}
							onChange={(e) => this.handleInputChange(index, e)}
							onKeyDown={(e) => this.handleKeyDown(index, e)}
							onKeyUp={this.handleKeyUp}
							className={`verificationCodeInput ${
								this.state.shakeNumberFields ? 'shake' : ''
							}`}
						/>
					))}
				</div>
				<div>
					<button className="mainSubmitBtn" onClick={this.verifyPhoneNumber}>
						Verify Phone Number
					</button>
				</div>
			</Collapse>
		);
	}
}
