import { Add, ArrowBackIos } from '@mui/icons-material';
import { Grow, CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import ScheduledAdventuresItem from './TripPlanning/SmallerComponents/ScheduledAdventuresItem.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default class ExplorePastAdventures extends Component {
	render() {
		return (
			<div style={{ marginTop: '7px' }}>
				<Grow in={true}>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
						</div>
						<div className="userAreaTitle">🏝️ Explore Past Adventures</div>
						<div></div>
					</div>
				</Grow>

				<div>
					<div className="userAreaContent scheduledAdventuresArea">
						<hr />

						{this.props.pastAdventuresFetched.length > 0 ? (
							<div>
								<br />
								{this.props.pastAdventuresFetched.map((adventure, index) => (
									<ScheduledAdventuresItem
										itemIndex={index}
										key={index}
										adventure={adventure}
										goToShowAdventure={(adventure) =>
											this.props.goToShowAdventure(adventure, 'past_adventures')
										}
									></ScheduledAdventuresItem>
								))}
								<br />
							</div>
						) : (
							<div
								style={{
									marginTop: '70px',
									marginBottom: '70px',
									textAlign: 'center',
								}}
							>
								{this.props.fetchedPastAdventures ? (
									<div>
										<br />
										When you complete adventures, they'll show up here!
									</div>
								) : (
									<CircularProgress></CircularProgress>
								)}
							</div>
						)}
					</div>
					<div
						className="mainBtn addLocationBtn"
						onClick={this.props.goToRecordNewAdventure}
					>
						<Add></Add> Add Past Adventure
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIos fontSize="smaller"></ArrowBackIos> Back to Main Menu
					</div>
					<hr />
				</div>
			</div>
		);
	}
}
