import './Question.css';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import MultipleChoiceOption from './MultipleChoiceOption';

export default class Question extends Component {
	constructor(props) {
		super(props);
		this.state = {
			answerStatus: {},
		};
	}

	tapOption = (key, strVal = '') => {
		if (
			this.props.questionData.type == 'singleChoice' ||
			this.props.questionData.type == 'multipleChoice'
		) {
			let prepAnswerStatus = {};

			this.props.questionData.options.forEach((optionData) => {
				prepAnswerStatus[optionData.id] =
					this.props.questionData.type === 'singleChoice'
						? false
						: this.state.answerStatus[optionData.id] || false;
			});

			prepAnswerStatus[key] = !this.state.answerStatus[key];

			this.setState({ answerStatus: prepAnswerStatus });
			this.props.updateQuestionAnswerFunc(
				this.props.questionData.id,
				prepAnswerStatus,
			);
		} else {
			let prepAnswerStatus = strVal;
			this.props.updateQuestionAnswerFunc(
				this.props.questionData.id,
				prepAnswerStatus,
			);
		}
	};

	componentDidMount() {
		let initialAnswerStatus = {};

		this.props.questionData.options.forEach((_, index) => {
			initialAnswerStatus[index] = false;
		});

		this.setState({
			answerStatus: initialAnswerStatus,
		});
	}

	render() {
		if (this.props.questionData.type === 'singleLine') {
			return (
				<Grow
					in={true}
					timeout={500 + this.props.itemIndex ? 300 * this.props.itemIndex : 0}
				>
					<div className="questionHolder">
						<div className="question">{this.props.questionData.question}</div>
						<div className="answerHolder">
							{this.props.questionData.defaultValue != null ? (
								<input
									type="text"
									className="questionnaireSingleLine"
									value={`${this.props.questionData.defaultValue}`}
									onChange={(e) => {
										this.tapOption(this.props.questionData.id, e.target.value);
									}}
									placeholder={
										this.props.questionData.placeholder == null
											? ''
											: this.props.questionData.placeholder
									}
								></input>
							) : (
								<input
									type="text"
									className="questionnaireSingleLine"
									onChange={(e) => {
										this.tapOption(this.props.questionData.id, e.target.value);
									}}
								></input>
							)}
						</div>
					</div>
				</Grow>
			);
		} else if (this.props.questionData.type === 'textarea') {
			return (
				<Grow
					in={true}
					timeout={500 + this.props.itemIndex ? 300 * this.props.itemIndex : 0}
				>
					<div className="questionHolder">
						<div className="question">{this.props.questionData.question}</div>
						<div>
							<textarea
								className="questionnaireTextarea"
								rows="4"
								onChange={(e) => {
									this.tapOption(this.props.questionData.id, e.target.value);
								}}
								defaultValue={
									this.props.questionData.default
										? this.props.questionData.default
										: ''
								}
							></textarea>
							<br />
						</div>
					</div>
				</Grow>
			);
		} else {
			return (
				<Grow
					in={true}
					timeout={500 + this.props.itemIndex ? 300 * this.props.itemIndex : 0}
				>
					<div className="questionHolder">
						<div className="question">{this.props.questionData.question}</div>
						<div className="answerHolder">
							{this.props.questionData.options.map((option, index) => (
								<MultipleChoiceOption
									optionData={option}
									key={`${this.props.questionData.question}-${index}`}
									answerKey={index}
									answerStatus={this.state.answerStatus}
									tapAnswer={this.tapOption}
								></MultipleChoiceOption>
							))}
						</div>
					</div>
				</Grow>
			);
		}
	}
}
