import { Grow } from '@mui/material';
import React, { Component } from 'react';

export default class NewActivityTypeOption extends Component {
	render() {
		return (
			<Grow
				in={true}
				timeout={
					300 + this.props.activityIndex * 100 < 1000
						? 300 + this.props.activityIndex * 100
						: 1000
				}
			>
				<div
					className={`activitySelectionOption ${
						this.props.selected ? 'selectedActivityOption' : ''
					}`}
					onClick={(e) => this.props.selectActivity(this.props.activity.id)}
				>
					<div>{this.props.activity.emoji}</div>
					<div>{this.props.activity.activity}</div>
				</div>
			</Grow>
		);
	}
}
