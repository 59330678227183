import React, { Component } from 'react';
import { transformDateFormat } from './../../utils.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ArrowForwardIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import Question from '../TripPlanning/SmallerComponents/Question.js';

export default class AdventureCheckIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			note: '',
		};
	}

	saveNewCheckIn = () => {
		if (this.state.note == '') {
			return;
		}

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('note', this.state.note);

		fetch(`https://api.gallivibe.com/createCheckIn`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.goToViewCheckInHistory();
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		let adventure = this.props.adventure;
		let fromDate = transformDateFormat(adventure.date_start);
		let toDate = transformDateFormat(adventure.date_end);

		return (
			<Grow in={this.props.isAddingAdventureCheckIn}>
				<div
					className="adventureMainPage"
					style={{
						display: this.props.isAddingAdventureCheckIn ? 'block' : 'none',
					}}
				>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
						</div>
						<div className="userAreaTitle">
							<div className="tripHeader">
								<div className="tripAdventureTitle" onClick={this.props.goBack}>
									{adventure.emoji} {adventure.title}
								</div>
								<div>
									<ArrowForwardIos
										style={{
											fontSize: 'small',
										}}
									></ArrowForwardIos>
								</div>
								<div className="tripHeaderLocation">✍️ Check In</div>
							</div>
						</div>
						<div></div>
					</div>
					<hr />
					<div className="userAreaContent">
						<br />
						What are your thoughts?
						<Question
							questionData={{
								type: 'textarea',
								id: 1,
								options: [],
							}}
							updateQuestionAnswerFunc={(qId, qAnswer) => {
								this.setState({ note: qAnswer });
							}}
						></Question>
						<div
							className="mainBtn addLocationBtn"
							onClick={this.saveNewCheckIn}
						>
							Save
						</div>
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
						{adventure.emoji} {adventure.title}
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
