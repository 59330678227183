import { Grow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { Component } from 'react';
import { ContentCopy } from '@mui/icons-material';
import { copyToClipboard } from '../../../../utils';

export default class ItineraryDailyDisplayRightColumnMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			copySuccess: false,
		};
	}

	getGoogleMapsLink = () => {
		if (this.props.viewingItem.key >= 1) {
			let prevItem = this.props.dayItems[this.props.viewingItem.key - 1];
			if (prevItem.address != '') {
				return `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
					prevItem.address,
				)}&destination=${encodeURIComponent(
					this.props.viewingItem.address,
				)}&travelmode=driving`;
			}
		}
		return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
			this.props.viewingItem.address,
		)}`;
	};

	render() {
		let viewingItem = this.props.viewingItem;
		return (
			<Grow in={!this.props.isDeleting}>
				<div
					className="itineraryRightColumnContent"
					style={{ display: this.props.isDeleting ? 'none' : 'block' }}
				>
					<div>
						<div
							className="eventContentDesc"
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<div>Time:</div>
							<div style={{ fontSize: 'small', fontWeight: 'normal' }}>
								{/* (GMT-7) */}
							</div>
						</div>
						<div className="eventContentVal">
							{viewingItem.start_time} - {viewingItem.end_time}
						</div>
						<div
							style={{ display: viewingItem.address === '' ? 'none' : 'block' }}
						>
							<div
								className="eventContentDesc"
								style={{ display: 'flex', justifyContent: 'space-between' }}
							>
								<div>Address:</div>
								<div
									className="clipboardCopyIconAddress"
									onClick={() => {
										copyToClipboard(viewingItem.address, 'addressText');
										this.setState({ copySuccess: true });
										setTimeout(() => {
											this.setState({ copySuccess: false });
										}, 1000);
									}}
								>
									{!this.state.copySuccess && (
										<Grow in={!this.state.copySuccess}>
											<div>
												<ContentCopy fontSize="x-small"></ContentCopy>
											</div>
										</Grow>
									)}
									{this.state.copySuccess && (
										<Grow in={this.state.copySuccess}>
											<div>
												<span>Copied!</span>
											</div>
										</Grow>
									)}
								</div>
							</div>
							<div className="eventContentVal scheduledItemAddress">
								<a href={this.getGoogleMapsLink()} target="_blank">
									<input
										type="hidden"
										value={viewingItem.address}
										id="addressText"
									/>
									{viewingItem.address}
								</a>
							</div>
						</div>
					</div>
					<div>
						<div className="scheduledItemOptionArea">
							<div
								className="scheduledItemOption"
								onClick={this.props.deleteItem}
							>
								<div>
									<DeleteIcon fontSize="small" />
								</div>
								<div>Delete</div>
							</div>
							<div
								className="scheduledItemOption"
								onClick={(e) => this.props.modifyActivity(viewingItem)}
							>
								<div>
									<EditIcon fontSize="small" />
								</div>
								<div>Edit</div>
							</div>
						</div>
					</div>
				</div>
			</Grow>
		);
	}
}
