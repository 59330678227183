import React, { Component } from 'react';
import Cookies from 'js-cookie';
import UserAreaMenu from './UserAreaMenu';
import ScheduledTrips from './ScheduledTrips';
import { Grow } from '@mui/material';
import FAQ from './FAQ';
import InviteOthers from './InviteOthers';
import ExplorePastAdventures from './ExplorePastAdventures';
import PlanAdventure from './AdventureCreation/PlanAdventure';
import AdventurePage from './AdventurePage/AdventurePage';

export default class UserArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPlanningTrip: false,
			isSeeingScheduledTrips: false,
			isShowingFAQ: false,
			isShowingInviteOthers: false,
			isRecordingNewAdventure: false,
			isExploringPastAdventures: false,
			isShowingAdventure: false,

			adventure: {},
			adventuresFetched: false,
			pastAdventuresFetched: [],
			fetchedScheduledAdventures: [],
			recordingNewAdventureFromScheduledTrips: false,

			adventureComingFrom: null,
		};
	}

	signOut = () => {
		Cookies.remove('token');
		this.props.updateAuthenticatedStatusFunc(false, '');
	};

	goToTripPlanning = () => {
		this.setState({ isPlanningTrip: true });
	};

	goToShowAdventure = (adventure, comingFrom) => {
		this.setState({
			adventureComingFrom: comingFrom,
			adventure: adventure,
			isShowingAdventure: true,
			isRecordingNewAdventure: false,
			isSeeingScheduledTrips: false,
			isExploringPastAdventures: false,
		});
	};

	goToPastAdventures = () => {
		const gallivibeLogo = document.getElementById('gallivibeLogo');
		gallivibeLogo.scrollIntoView({ behavior: 'smooth' });

		const today = new Date();

		// Convert to ISO string, which includes the time and timezone (Z denotes UTC)
		const toRange = today.toISOString();

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('toRange', toRange);

		fetch(`https://api.gallivibe.com/getAdventures`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				let adventures = data.adventures;
				data.adventures.sort((a, b) => {
					const dateA = new Date(a.date_end);
					const dateB = new Date(b.date_end);

					if (dateA > dateB) return -1;
					if (dateA < dateB) return 1;
					return 0;
				});

				if (data.ok) {
					this.setState({
						fetchedPastAdventures: true,
						pastAdventuresFetched: adventures,
					});
				}
			})
			.catch((error) => console.error(error));

		this.setState({
			isShowingAdventure: false,
			isPlanningTrip: false,
			isShowingTrip: false,
			isExploringPastAdventures: true,
		});
	};

	adventureGoBack = () => {
		let adventureComingFrom = this.state.adventureComingFrom;
		if (adventureComingFrom == null || adventureComingFrom == 'scheduled') {
			this.goToScheduledTrips();
		} else if (adventureComingFrom == 'main_menu') {
			this.goToMainMenu();
		} else if (adventureComingFrom == 'past_adventures') {
			this.goToPastAdventures();
		}
	};

	goToScheduledTrips = () => {
		const gallivibeLogo = document.getElementById('gallivibeLogo');
		gallivibeLogo.scrollIntoView({ behavior: 'smooth' });

		const today = new Date();
		const tomorrow = new Date(
			today.getFullYear(),
			today.getMonth(),
			today.getDate() + 1,
		);

		// Convert to ISO string, which includes the time and timezone (Z denotes UTC)
		const fromRange = tomorrow.toISOString();

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('fromRange', fromRange);

		fetch(`https://api.gallivibe.com/getAdventures`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				let adventures = data.adventures;
				data.adventures.sort((a, b) => {
					const dateA = new Date(a.date_start);
					const dateB = new Date(b.date_start);

					if (dateA > dateB) return 1;
					if (dateA < dateB) return -1;
					return 0;
				});

				if (data.ok) {
					this.setState({
						fetchedScheduledAdventures: adventures,
						adventuresFetched: true,
					});
				}
			})
			.catch((error) => console.error(error));

		this.setState({
			isShowingAdventure: false,
			isPlanningTrip: false,
			isShowingTrip: false,
			isSeeingScheduledTrips: true,
		});
	};

	goToMainMenu = () => {
		this.setState({
			isPlanningTrip: false,
			isShowingTrip: false,
			isSeeingScheduledTrips: false,
			isShowingAdventure: false,
		});
	};

	goToRecordNewAdventure = () =>
		this.setState({
			isRecordingNewAdventure: true,
			isSeeingScheduledTrips: false,
			isExploringPastAdventures: false,
			recordingNewAdventureFromScheduledTrips: true,
		});

	render() {
		return (
			<Grow in={this.props.isUserAuthenticated}>
				<div
					style={{ display: this.props.isUserAuthenticated ? 'block' : 'none' }}
				>
					<UserAreaMenu
						user={this.props.user}
						themeMode={this.props.themeMode}
						toggleTheme={this.props.toggleTheme}
						shouldShowMenu={
							!this.state.isPlanningTrip &&
							!this.state.isShowingAdventure &&
							!this.state.isShowingTrip &&
							!this.state.isSeeingScheduledTrips &&
							!this.state.isShowingFAQ &&
							!this.state.isShowingInviteOthers &&
							!this.state.isExploringPastAdventures &&
							!this.state.isRecordingNewAdventure
						}
						goToRecordNewAdventure={() =>
							this.setState({
								isRecordingNewAdventure: true,
								recordingNewAdventureFromScheduledTrips: false,
							})
						}
						goToExplorePastAdventures={this.goToPastAdventures}
						goToTripPlanning={this.goToTripPlanning}
						goToScheduledTrips={this.goToScheduledTrips}
						goToFAQ={() => {
							this.setState({ isShowingFAQ: true });
						}}
						goToInviteOthers={() => {
							this.setState({ isShowingInviteOthers: true });
						}}
						signOut={this.signOut}
						currentAdventures={this.props.currentAdventures}
						goToShowAdventure={(adventure) =>
							this.goToShowAdventure(adventure, 'main_menu')
						}
					></UserAreaMenu>

					{this.state.isShowingFAQ && (
						<FAQ goBack={() => this.setState({ isShowingFAQ: false })}></FAQ>
					)}

					{this.state.isShowingInviteOthers && (
						<InviteOthers
							goBack={() => this.setState({ isShowingInviteOthers: false })}
						></InviteOthers>
					)}

					{this.state.isRecordingNewAdventure && (
						// <RecordNewAdventure
						// 	goBack={() => this.setState({ isRecordingNewAdventure: false })}
						// ></RecordNewAdventure>
						<PlanAdventure
							token={this.props.token}
							goToShowAdventure={this.goToShowAdventure}
							goBack={() => {
								if (this.state.recordingNewAdventureFromScheduledTrips) {
									this.setState({
										isRecordingNewAdventure: false,
										isSeeingScheduledTrips: true,
									});
								} else {
									this.setState({ isRecordingNewAdventure: false });
								}
							}}
						></PlanAdventure>
					)}
					{this.state.isExploringPastAdventures && (
						<ExplorePastAdventures
							pastAdventuresFetched={this.state.pastAdventuresFetched}
							fetchedPastAdventures={this.state.fetchedPastAdventures}
							goToShowAdventure={this.goToShowAdventure}
							goToRecordNewAdventure={this.goToRecordNewAdventure}
							goBack={() => this.setState({ isExploringPastAdventures: false })}
						></ExplorePastAdventures>
					)}

					{this.state.isSeeingScheduledTrips && (
						<ScheduledTrips
							isSeeingScheduledTrips={this.state.isSeeingScheduledTrips}
							adventuresFetched={this.state.adventuresFetched}
							fetchedScheduledAdventures={this.state.fetchedScheduledAdventures}
							goToMainMenu={this.goToMainMenu}
							goToShowAdventure={this.goToShowAdventure}
							goToRecordNewAdventure={this.goToRecordNewAdventure}
						></ScheduledTrips>
					)}
					{this.state.isShowingAdventure && (
						<AdventurePage
							themeMode={this.props.themeMode}
							token={this.props.token}
							user={this.props.user}
							adventure={this.state.adventure}
							adventureComingFrom={this.state.adventureComingFrom}
							goBack={this.adventureGoBack}
						></AdventurePage>
					)}
				</div>
			</Grow>
		);
	}
}
