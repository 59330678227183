import React, { Component } from 'react';
import { transformDateFormat } from './../../utils.js';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grow, CircularProgress } from '@mui/material';

export default class DeleteAdventure extends Component {
	deleteAdventure = (id) => {
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);

		fetch(`https://api.gallivibe.com/deleteAdventure`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.goBack();
				}
			})
			.catch((error) => console.error(error));
	};

	render() {
		let adventure = this.props.adventure;
		let fromDate = transformDateFormat(adventure.date_start);
		let toDate = transformDateFormat(adventure.date_end);

		return (
			<Grow in={this.props.isDeletingAdventure}>
				<div
					style={{ display: this.props.isDeletingAdventure ? 'block' : 'none' }}
					className="adventureMainPage"
				>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
						</div>
						<div className="userAreaTitle">
							<div>
								{adventure.emoji} {adventure.title}
							</div>
							<div
								className="travelDates"
								onClick={this.props.goToSelectTravelDates}
							>
								{fromDate} {` - `}
								{toDate}
							</div>
						</div>
						<div></div>
					</div>
					<hr />
					<div className="deletingTripPage">
						<h3>Are you sure that you would like to delete your adventure?</h3>
						<center>
							<div
								style={{
									width: '70%',
									display: 'flex',
									justifyContent: 'space-evenly',
									marginTop: '40px',
								}}
							>
								<div
									className="deleteItemOption"
									style={{ width: '100px', height: '100px' }}
									onClick={this.props.backToAdventure}
								>
									<div>
										<ClearIcon></ClearIcon>
									</div>
									<div>No</div>
								</div>
								<div
									className="deleteItemOption"
									style={{ width: '100px', height: '100px' }}
									onClick={this.deleteAdventure}
								>
									<div>
										<CheckIcon></CheckIcon>
									</div>
									<div>Yes</div>
								</div>
							</div>
						</center>
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
						{
							{
								null: 'Upcoming Adventures',
								scheduled: 'Upcoming Adventures',
								main_menu: 'Back to Main Menu',
								past_adventures: 'Explore Past Adventures',
							}[this.props.adventureComingFrom]
						}
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
