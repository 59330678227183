import { Grow } from '@mui/material';
import React, { Component } from 'react';
import Question from './../../../TripPlanning/SmallerComponents/Question';
import { transportationCategories } from './../../../../utils';

export default class TripTransportationAreaNewItemType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			transportationCategory: null,
		};
	}

	render() {
		return (
			<Grow in={this.props.selectingTransportationType}>
				<div
					style={{
						display: this.props.selectingTransportationType ? 'block' : 'none',
					}}
				>
					<div
						className={`lodgingQuestion ${
							this.state.shakeTransportationCategorySelection ? 'shake' : ''
						}`}
					>
						<br />
						<Question
							questionData={{
								id: 1,
								type: 'singleChoice',
								question: '',
								options: Object.values(transportationCategories).map(
									(type) => ({
										id: type.id,
										option: (
											<div className="transportationLodgingTypeSelection">
												<div>{type.emojiSmall}</div>
												<div>{type.name}</div>
												<div></div>
											</div>
										),
									}),
								),
							}}
							updateQuestionAnswerFunc={(e, answerOptions) => {
								for (let key in answerOptions) {
									if (answerOptions.hasOwnProperty(key) && answerOptions[key]) {
										this.setState(
											{
												transportationCategory: transportationCategories[key],
											},
											() =>
												this.props.selectTransportationType(
													this.state.transportationCategory,
												),
										);
										return;
									}
									this.setState({ transportationCategory: null });
								}
							}}
						></Question>
					</div>
					{/* <button
						className="mainSubmitBtn"
						style={{ marginTop: '0px' }}
						onClick={(e) => {
							if (this.state.transportationCategory == null) {
								this.setState({ shakeTransportationCategorySelection: true });
								setTimeout(
									function () {
										this.setState({
											shakeTransportationCategorySelection: false,
										});
									}.bind(this),
									500,
								);
							} else {
								this.props.selectTransportationType(
									this.state.transportationCategory,
								);
							}
						}}
					>
						Select
					</button> */}
				</div>
			</Grow>
		);
	}
}
