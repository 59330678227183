import {
	DirectionsBus,
	AirplaneTicket,
	DirectionsCar,
	DirectionsTransit,
	DirectionsBoat,
	DirectionsBike,
} from '@mui/icons-material';

export function formatPhoneNumber(phoneNumber) {
	let formattedValue = phoneNumber;

	// Remove all non-digits
	formattedValue = formattedValue.replace(/\D/g, '');

	// Limit the phone number to a maximum length of 10 digits
	formattedValue = formattedValue.slice(0, 10);

	// Add the opening parenthesis after the first digit
	if (formattedValue.length > 0) {
		formattedValue = `(${formattedValue}`;
	}

	// Add the closing parenthesis and dash after the fourth digit
	if (formattedValue.length > 4) {
		formattedValue = `${formattedValue.slice(0, 4)}) ${formattedValue.slice(
			4,
		)}`;
		if (formattedValue.length > 9) {
			formattedValue = `${formattedValue.slice(0, 9)}-${formattedValue.slice(
				9,
			)}`;
		}
	}

	return formattedValue;
}

export function validateEmailPattern(email) {
	// Regular expression pattern for email validation
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (!emailPattern.test(email)) {
		return false;
	}

	return true;
}

export function transformDateFormat(dateString) {
	const inputDate = new Date(dateString);
	const month = inputDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
	const day = inputDate.getUTCDate();
	const year = inputDate.getUTCFullYear();
	return `${month.toString().padStart(2, '0')}/${day
		.toString()
		.padStart(2, '0')}/${year}`;
}

export function daysUntilDate(targetDateStr) {
	// Parse the targetDateStr to obtain month, day, and year components
	const [targetMonth, targetDay, targetYear] = targetDateStr
		.split('/')
		.map(Number);

	// Create a Date object for the current date
	const currentDate = new Date();

	// Create a Date object for the target date
	const targetDate = new Date(targetYear, targetMonth - 1, targetDay); // Months in Date object are 0-indexed

	// Calculate the difference in milliseconds between the two dates
	const timeDifference = targetDate.getTime() - currentDate.getTime();

	// Calculate the number of days
	const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

	return daysDifference;
}

export function slashesDateFormatToObject(slashesDateFormatDate) {
	const [month, day, year] = slashesDateFormatDate.split('/');

	const formattedDate = {
		month: month,
		day: day,
		year: year,
	};

	return formattedDate;
}

export function dashesDateFormatToObject(slashesDateFormatDate) {
	const [year, month, day] = slashesDateFormatDate.split('-');

	const formattedDate = {
		month: month,
		day: day,
		year: year,
	};

	return formattedDate;
}

export function getDayDescription(date) {
	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const dayIndex = new Date(date).getDay();
	return daysOfWeek[dayIndex];
}

export function fromFulltimestampToDate(dateString) {
	const dateObject = new Date(dateString);
	const formattedDate = dateObject.toISOString().split('T')[0];
	return formattedDate;
}

export function militaryToStandardTime(militaryTime) {
	// Check if the time is already in standard format with AM or PM
	if (militaryTime.match(/AM|PM/i)) {
		return militaryTime; // Return as is, because no conversion is needed.
	}

	// If not, split the time and proceed with the conversion
	const [hours, minutes] = militaryTime.split(':').map(Number);

	const period = hours >= 12 ? 'PM' : 'AM';
	const twelveHourFormat = (hours % 12 === 0 ? 12 : hours % 12)
		.toString()
		.padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');
	const formattedTime = `${twelveHourFormat}:${formattedMinutes} ${period}`;

	return formattedTime;
}

export function normalToMilitary(normalTime) {
	const timeParts = normalTime.split(' ');
	const time = timeParts[0];
	const meridian = timeParts[1];

	const [hours, minutes] = time.split(':').map(Number);

	let militaryHours = hours;
	if (meridian === 'PM' && hours !== 12) {
		militaryHours += 12;
	} else if (meridian === 'AM' && hours === 12) {
		militaryHours = 0;
	}

	const militaryTime = `${militaryHours.toString().padStart(2, '0')}:${minutes
		.toString()
		.padStart(2, '0')}`;
	return militaryTime;
}

export function getCSSVariableValue(variableName) {
	return getComputedStyle(document.documentElement)
		.getPropertyValue(variableName)
		.trim();
}

export const transportationCategories = {
	0: {
		id: 0,
		emoji: '✈️',
		// emoji: <AirplaneTicket fontSize="large"></AirplaneTicket>,
		emojiSmall: '✈️',
		// emojiSmall: <AirplaneTicket fontSize="x-small"></AirplaneTicket>,
		name: 'Plane',
	},
	1: {
		id: 1,
		emoji: '🚗',
		emojiSmall: '🚗',
		// emoji: <DirectionsCar fontSize="large"></DirectionsCar>,
		// emojiSmall: <DirectionsCar fontSize="x-small"></DirectionsCar>,
		name: 'Car',
	},
	2: {
		id: 2,
		emoji: '🚌',
		emojiSmall: '🚌',
		// emoji: <DirectionsBus fontSize="large"></DirectionsBus>,
		// emojiSmall: <DirectionsBus fontSize="x-small"></DirectionsBus>,
		name: 'Bus',
	},
	3: {
		id: 3,
		emoji: '🚆',
		emojiSmall: '🚆',
		// emoji: <DirectionsTransit fontSize="large"></DirectionsTransit>,
		// emojiSmall: <DirectionsTransit fontSize="x-small"></DirectionsTransit>,
		name: 'Train',
	},
	4: {
		id: 4,
		emoji: '🚲',
		emojiSmall: '🚲',
		// emoji: <DirectionsBike fontSize="large"></DirectionsBike>,
		// emojiSmall: <DirectionsBike fontSize="x-small"></DirectionsBike>,
		name: 'Bike',
	},
	5: {
		id: 5,
		emoji: '⛴️',
		emojiSmall: '⛴️',
		// emoji: <DirectionsBoat fontSize="large"></DirectionsBoat>,
		// emojiSmall: <DirectionsBoat fontSize="x-small"></DirectionsBoat>,
		name: 'Boat',
	},
};

export function copyToClipboard(text, fallbackIDElement) {
	if (navigator.clipboard) {
		navigator.clipboard
			.writeText(text)
			.then(function () {})
			.catch(function (err) {
				console.error('Failed to copy text: ', err);
			});
	} else {
		try {
			const originalOverflow = document.body.style.overflow; // Store the original overflow value
			document.body.style.overflow = 'hidden'; // Temporarily prevent scrolling

			const fallbackElem = document.createElement('textarea');
			document.body.appendChild(fallbackElem);
			fallbackElem.value = text;

			Object.assign(fallbackElem.style, {
				position: 'fixed',
				top: '-50px', // Smaller off-screen value
				left: '-50px',
				width: '1px',
				height: '1px',
				padding: '0',
				border: 'none',
				opacity: '0',
				pointerEvents: 'none',
			});

			// Optionally skip focusing on the textarea
			// fallbackElem.focus();

			fallbackElem.select();
			document.execCommand('copy');
			document.body.removeChild(fallbackElem);

			document.body.style.overflow = originalOverflow; // Restore the original overflow value
		} catch (err) {
			console.error('Failed to copy text using fallback: ', err);
		}
	}
}

export function sortTransportationItemsByDateTime(items) {
	function parseDateAndTime(dateStr, timeStr) {
		// Parse the date string
		const date = new Date(dateStr);

		// Extract hours and minutes from the time string
		let [time, modifier] = timeStr.split(' ');
		let [hours, minutes] = time.split(':');

		// Convert hours to 24-hour format if the time is PM
		if (modifier === 'PM' && hours !== '12') {
			hours = parseInt(hours, 10) + 12;
		} else if (modifier === 'AM' && hours === '12') {
			hours = '00';
		}

		// Set hours and minutes to the date
		date.setHours(hours);
		date.setMinutes(minutes);

		return date;
	}

	return items.sort((a, b) => {
		const aDateTime = parseDateAndTime(
			a.departure_details.date,
			a.departure_details.time,
		);
		const bDateTime = parseDateAndTime(
			b.departure_details.date,
			b.departure_details.time,
		);

		return aDateTime - bDateTime;
	});
}
