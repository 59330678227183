import { ArrowBackIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';

export default class InviteOthers extends Component {
  render() {
    return (
      <Grow in={true}>
        <div>
          <div className="userAreaTitle">✉️ Invite Others</div>
          <hr />
          <div className="userAreaContent">
            <center>
              <br />
              <br />
              We're flattered you'd like to invite your friends!
              <br />
              <br />
              <br />
              You currently don't have invitations available, but we'll notify
              you when that changes 😔
              <br />
              <br />
              <br />
              <br />
            </center>
          </div>
          <hr />
          <div className="mainBtn" onClick={this.props.goBack}>
            <ArrowBackIos fontSize="smaller"></ArrowBackIos> Back
          </div>
          <hr />
        </div>
      </Grow>
    );
  }
}
