import React, { Component } from 'react';
import { Switch } from '@mui/material';

export default class Setting extends Component {
	render() {
		return (
			<div onClick={this.props.toggleSetting} className="tripSetting">
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					<div>{this.props.emoji}</div>
					<div>{this.props.settingName}</div>
				</div>
				<div>
					<Switch checked={this.props.settingStatus}></Switch>
				</div>
			</div>
		);
	}
}
