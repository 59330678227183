import { ArrowForwardIos } from '@mui/icons-material';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { transformDateFormat } from './../../../utils';
import Hotel from '@mui/icons-material/Hotel';

export default class TripLodgingAreaItemMenu extends Component {
	constructor(props) {
		super(props);
	}

	lodgingTypeToEmoji = (lodgingType) => {
		return { 0: <Hotel fontSize="large"></Hotel>, 1: '🛏️', 2: '🏡', 3: '👫' }[
			lodgingType
		];
	};

	render() {
		return (
			<Grow in={this.props.isViewingLodgingItemsMenu}>
				<div className="tripLodgingAreaItemMenu">
					<br />

					<div>
						{this.props.lodgingItems.length > 0 ? (
							this.props.lodgingItems.map((lodgingItem, index) => {
								return (
									<div
										className="scheduledTripsItem"
										onClick={(e) => this.props.goToItem(lodgingItem)}
										key={index}
									>
										<div className="scheduledTripsItemEmoji">
											{this.lodgingTypeToEmoji(lodgingItem.type)}
										</div>
										<div className="scheduledTripsItemText">
											<div className="scheduledTripsItemDestination">
												{lodgingItem.name}
											</div>
											<div className="scheduledTripsItemDates">
												{transformDateFormat(lodgingItem.start_date)}
												{' - '}
												{transformDateFormat(lodgingItem.end_date)}
											</div>
										</div>
										<div></div>
										<div></div>
										<div style={{ textAlign: 'right' }}>
											<ArrowForwardIos fontSize="8px"></ArrowForwardIos>
										</div>
									</div>
								);
							})
						) : (
							<div>
								<br />
								<br />
								<center>No lodging added yet 😔</center>
								<br />
								<br />
							</div>
						)}
					</div>
					<div className="mainBtn" onClick={this.props.goToAddNewLodging}>
						Add Lodging
					</div>
				</div>
			</Grow>
		);
	}
}
