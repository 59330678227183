import { Grow } from '@mui/material';
import React, { Component } from 'react';

export default class AddressAutocompleteItem extends Component {
  render() {
    return (
      <Grow in={true} timeout={300 + this.props.itemIndex * 100}>
        <div
          className={`locationSearchResultItem ${
            this.props.isSelected ? 'selectedItem' : ''
          }`}
          onClick={(e) => {
            this.props.selectSuggestion(
              this.props.suggestionId,
              this.props.address,
              this.props.name,
            );
          }}
        >
          {this.props.address}
        </div>
      </Grow>
    );
  }
}
