import './UserArea.css';
import React, { Component } from 'react';
import { Avatar, Grow } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import ScheduledAdventuresItem from './TripPlanning/SmallerComponents/ScheduledAdventuresItem.js';
import { LiveIcon } from './../utils';

export default class UserAreaMenu extends Component {
	render() {
		return (
			<div style={{ display: this.props.shouldShowMenu ? 'block' : 'none' }}>
				<div className="gallivibeMainTitle">
					<div className="avatarContainer">
						<Avatar>{this.props.user['firstName'][0]}</Avatar>
					</div>
					<div className="messageContainer">
						Welcome back, {this.props.user['firstName']}!
					</div>
					<div
						className="extraContentContainer"
						onClick={this.props.toggleTheme}
					>
						{this.props.themeMode == 'light' ? (
							<DarkModeIcon style={{ padding: '10px' }}></DarkModeIcon>
						) : (
							<LightModeIcon style={{ padding: '10px' }}></LightModeIcon>
						)}
					</div>
				</div>
				<div className="userAreaContent mainMenu">
					<hr />
					<div className="menuSubtitle">What would you like to do?</div>
					<Grow in={this.props.shouldShowMenu} timeout={100}>
						<div
							className="mainBtn wHighlight"
							onClick={this.props.goToRecordNewAdventure}
						>
							📝 Create New Adventure
						</div>
					</Grow>
					<Grow in={this.props.shouldShowMenu} timeout={300}>
						<div
							className="mainBtn wHighlight"
							onClick={this.props.goToScheduledTrips}
						>
							🛤️ Upcoming Adventures
						</div>
					</Grow>
					<Grow in={this.props.shouldShowMenu} timeout={500}>
						<div
							className="mainBtn wHighlight"
							onClick={this.props.goToExplorePastAdventures}
						>
							🏝️ Explore Past Adventures
						</div>
					</Grow>

					{this.props.currentAdventures.length > 0 ? (
						<div>
							<br />
							<div className="TripArea TripAreaBasic adventureLocations">
								<div className="tripAreaDesc">Happening Now</div>
								<div className="happeningNowAdventures">
									{this.props.currentAdventures.length > 0 ? (
										this.props.currentAdventures.map((adventure, index) => (
											<ScheduledAdventuresItem
												itemIndex={index}
												key={index}
												adventure={adventure}
												goToShowAdventure={this.props.goToShowAdventure}
												wHighlight={true}
											></ScheduledAdventuresItem>
										))
									) : (
										<div></div>
									)}
								</div>
							</div>
						</div>
					) : (
						<div>
							<hr />
						</div>
					)}

					<div className="menuSubtitle">Here are some other options</div>
					<Grow in={this.props.shouldShowMenu} timeout={100}>
						<div className="mainBtn wHighlight" onClick={this.props.goToFAQ}>
							❓ FAQ
						</div>
					</Grow>
					<Grow in={this.props.shouldShowMenu} timeout={300}>
						<div
							className="mainBtn wHighlight"
							onClick={this.props.goToInviteOthers}
						>
							✉️ Invite Others
						</div>
					</Grow>
					<Grow in={this.props.shouldShowMenu} timeout={500}>
						<div className="mainBtn wHighlight" onClick={this.props.signOut}>
							👋 Sign out
						</div>
					</Grow>
					<hr />
				</div>
			</div>
		);
	}
}
