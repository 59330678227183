import React, { Component } from 'react';
import TripLodgingAreaItemContent from './TripLodgingAreaItemContent';
import TripLodgingAreaNewItem from './NewTripLodgingDetails/TripLodgingAreaNewItem';
import TripLodgingAreaItemMenu from './TripLodgingAreaItemMenu';

export default class TripLodgingAreaContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddingNewItem: false,
			isEditingItem: false,
			itemBeingEdited: {},
			isViewingLodgingItemsMenu: true,
			isViewingLodgingItem: false,
			isViewingItemContent: false,
			lodgingItems: [],
			lodgingItem: {},
		};
	}

	viewLodgingItems = () => {
		this.setState({
			isViewingLodgingItemsMenu: true,
			isAddingNewItem: false,
			isEditingItem: false,
			isViewingItemContent: false,
		});
	};

	render() {
		return (
			<div
				className={`addLodgingInfoArea ${
					this.props.showingAddLodging ? 'visible' : 'hiding'
				}`}
			>
				{this.state.isViewingLodgingItemsMenu && (
					<TripLodgingAreaItemMenu
						isViewingLodgingItemsMenu={this.state.isViewingLodgingItemsMenu}
						lodgingItems={this.props.lodgingItems}
						goToItem={(lodgingItem) => {
							this.setState({
								lodgingItem: lodgingItem,
								isAddingNewItem: false,
								isViewingLodgingItemsMenu: false,
								isViewingItemContent: true,
							});
						}}
						goToAddNewLodging={() => {
							this.setState({
								isAddingNewItem: true,
								isViewingLodgingItemsMenu: false,
								isViewingItemContent: false,
							});
						}}
					></TripLodgingAreaItemMenu>
				)}
				{this.state.isViewingItemContent && (
					<TripLodgingAreaItemContent
						isViewingItemContent={this.state.isViewingItemContent}
						token={this.props.token}
						adventure={this.props.adventure}
						tripData={this.props.tripData}
						viewingLodgingItem={this.state.lodgingItem}
						viewItems={this.viewLodgingItems}
						removeFromLodgingItems={this.props.removeFromLodgingItems}
						editItem={(lodgingItem) =>
							this.setState({
								isViewingItemContent: false,
								isEditingItem: true,
								itemBeingEdited: lodgingItem,
							})
						}
					></TripLodgingAreaItemContent>
				)}
				{this.state.isAddingNewItem && (
					<TripLodgingAreaNewItem
						isAddingNewItem={this.state.isAddingNewItem}
						token={this.props.token}
						tripData={this.props.tripData}
						adventure={this.props.adventure}
						goToLodgingItemMenu={this.viewLodgingItems}
						addToLodgingItems={this.props.addToLodgingItems}
						removeFromLodgingItems={this.props.removeFromLodgingItems}
						goToItem={(lodgingItem) => {
							this.setState({
								lodgingItem: lodgingItem,
								isAddingNewItem: false,
								isViewingLodgingItemsMenu: false,
								isViewingItemContent: true,
							});
						}}
						setLodgingEmoji={this.props.setLodgingEmoji}
					></TripLodgingAreaNewItem>
				)}
				{this.state.isEditingItem && (
					<TripLodgingAreaNewItem
						isAddingNewItem={this.state.isEditingItem}
						existingItem={this.state.itemBeingEdited}
						token={this.props.token}
						tripData={this.props.tripData}
						adventure={this.props.adventure}
						goToLodgingItemMenu={this.viewLodgingItems}
						addToLodgingItems={this.props.addToLodgingItems}
						removeFromLodgingItems={this.props.removeFromLodgingItems}
						goToItem={(lodgingItem) => {
							this.setState({
								lodgingItem: lodgingItem,
								isAddingNewItem: false,
								isEditingItem: false,
								isViewingLodgingItemsMenu: false,
								isViewingItemContent: true,
							});
						}}
						setLodgingEmoji={this.props.setLodgingEmoji}
					></TripLodgingAreaNewItem>
				)}
			</div>
		);
	}
}
