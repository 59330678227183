import './ToSAndPrivacyPolicy.css';
import React, { Component } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default class TermsOfUse extends Component {
  render() {
    return (
      <div className="ToSOrPrivacyPolicy">
        <b>Gallivibe, Inc - Terms of Use</b>
        <br />
        <span className="lastUpdate">Last Updated: August 7, 2023</span>
        <hr />
        <p>
          <h4>1. Acceptance of Terms:</h4>
          <div>
            By accessing or using the Gallivibe platform, you concur with and
            bind yourself to the stipulations outlined in these Terms of Use
            ("Terms"). Should you disagree with any part of these Terms, kindly
            refrain from using our service.
          </div>
        </p>
        <p>
          <h4>2. Registration and Account Security:</h4>
          <div>
            2.1. To leverage Gallivibe's features, you may be required to
            register for an account. 2.2. You vow to provide factual, complete,
            and updated registration data. 2.3. Safeguarding your account rests
            on your shoulders. Hence, maintain the confidentiality of your
            credentials and promptly report unauthorized use.
          </div>
        </p>
        <p>
          <h4>3. Use of Service:</h4>
          <div>
            3.1. Gallivibe grants you a limited, non-exclusive, and
            non-transferable license to use the platform for personal,
            non-commercial purposes. 3.2. Actions like duplicating,
            redistributing, or reselling our platform or its data are
            prohibited. 3.3. Unlawful or illicit activities on our platform are
            forbidden.
          </div>
        </p>
        <p>
          <h4>4. Content Ownership:</h4>
          <div>
            4.1. You retain the rights to content you submit or share. By
            uploading, you grant Gallivibe a non-exclusive, royalty-free license
            to use, reproduce, and display your content solely for operating and
            improving our service. 4.2. Gallivibe reserves the right to review,
            flag, or remove any content deemed inappropriate.
          </div>
        </p>
        <p>
          <h4>5. Termination of Service:</h4>
          <div>
            Gallivibe may discontinue or alter any part of the platform, or may
            suspend or terminate your use, at our discretion, without prior
            notice.
          </div>
        </p>
        <p>
          <h4>6. Disclaimers:</h4>
          <div>
            6.1. The service is provided "as is." Gallivibe disclaims all
            warranties, express or implied, including merchantability, fitness
            for a particular purpose, and non-infringement. 6.2. Gallivibe
            doesn't guarantee uninterrupted or error-free service.
          </div>
        </p>
        <p>
          <h4>7. Limitation of Liability:</h4>
          <div>
            Under no circumstance shall Gallivibe, its affiliates, or its
            licensors be held accountable for any direct, indirect, punitive,
            incidental, or consequential damages, or any loss of data or
            profits, arising out of or related to the use or inability to use
            this service, even if Gallivibe has been advised of the possibility
            of such damages.
          </div>
        </p>
        <p>
          <h4>8. Indemnity:</h4>
          <div>
            You agree to indemnify and hold harmless Gallivibe, its officers,
            directors, employees, and agents, from any liabilities, claims,
            expenses, or demands, including legal fees, made by any third party
            due to or arising out of your use of the service, your violation of
            these Terms, or your violation of any rights of another.
          </div>
        </p>
        <p>
          <h4>9. Amendments to Terms:</h4>
          <div>
            Gallivibe reserves the right to modify these Terms periodically.
            Continued use after such alterations signifies your acknowledgment
            and acceptance of the revised Terms.
          </div>
        </p>
        <p>
          <h4>10. Governing Law:</h4>
          <div>
            These Terms shall be governed by and construed following the laws of
            the State of Delaware, excluding its conflicts of law rules.
          </div>
        </p>
        <p>
          <h4>11. Feedback and Complaints:</h4>
          <div>
            All feedback, comments, requests for technical support, and other
            communications relating to the service should be directed to:{' '}
            <a href="mailto:hello@gallivibe.com">hello@gallivibe.com</a>.
          </div>
        </p>
        <p>
          <h4>12. Severability:</h4>
          <div>
            If any provision of these Terms is deemed unlawful, void, or for any
            reason unenforceable, then that provision shall be deemed severable
            from the Terms and shall not affect the validity and enforceability
            of any remaining provisions.
          </div>
        </p>
        <p>
          <h4>13. Entire Agreement:</h4>
          <div>
            These Terms and our Privacy Policy constitute the entire agreement
            between you and Gallivibe, Inc. with respect to the service and
            supersede all prior or contemporaneous understandings, whether
            written or oral, regarding the service.
          </div>
        </p>
        <br />
        <hr />
        <div className="mainBtn" onClick={this.props.goBack}>
          <ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon> Go Back
        </div>
      </div>
    );
  }
}
