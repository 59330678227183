import React, { Component } from 'react';
import { transformDateFormat, daysUntilDate } from './../../utils.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Add, ArrowLeft, ArrowRight, Circle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Grow, CircularProgress } from '@mui/material';
import ScheduledTripsItem from './../TripPlanning/SmallerComponents/ScheduledTripsItem';
import './AdventurePageMain.css';

export default class AdventurePageMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			trips: [],
			tripsFetched: false,
			currentLocationItemIndex: 0,
		};

		this.containerRef = React.createRef();
	}

	loadLocations = () => {
		this.setState({ trips: [], tripsFetched: false });

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);

		fetch(`https://api.gallivibe.com/getAdventureLocations`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok && data.trips) {
					const sortedTrips = data.trips.sort((a, b) => {
						const dateA = new Date(a.date_create);
						const dateB = new Date(b.date_create);

						return dateA - dateB;
					});

					this.setState({ trips: sortedTrips, tripsFetched: true });
				} else {
					// Delete the token, it's not valid.
					// this.setState({ isUserAuthenticated: false });
				}
			})
			.catch((error) => console.error(error));
	};

	componentDidMount() {
		this.loadLocations();
	}

	handleScroll = () => {
		const container = this.containerRef.current;
		const scrollLeft = container.scrollLeft;
		const itemWidth = container.offsetWidth; // Assuming equal item widths
		const currentLocationItemIndex = Math.round(scrollLeft / itemWidth);

		this.setState({ currentLocationItemIndex });
	};

	scrollToNextLocation = () => {
		this.scrollToLocation(this.state.currentLocationItemIndex + 1);
	};

	scrollToPreviousLocation = () => {
		this.scrollToLocation(this.state.currentLocationItemIndex - 1);
	};

	scrollToLocation = (locationIndex) => {
		const element = document.querySelector(
			`#scheduledTripItemInRoll${locationIndex}`,
		);

		if (element && element.parentElement) {
			const parent = element.parentElement;
			parent.scrollLeft = element.offsetLeft - parent.offsetLeft;
		}
	};

	render() {
		let adventure = this.props.adventure;
		let fromDate = transformDateFormat(adventure.date_start);
		let toDate = transformDateFormat(adventure.date_end);
		let displayLeftArrow =
			this.state.trips.length > 0 && this.state.currentLocationItemIndex != 0;
		let displayRightArrow =
			this.state.trips.length > 0 &&
			this.state.currentLocationItemIndex < this.state.trips.length - 1;

		let message =
			daysUntilDate(fromDate) > 0
				? `${this.props.user['firstName']}, there are ${daysUntilDate(
						fromDate,
				  )} days until this adventure.`
				: -1 * daysUntilDate(toDate) > 0
				? `${this.props.user['firstName']}, this adventure happened ${
						-1 * daysUntilDate(toDate)
				  } days ago.`
				: `${this.props.user['firstName']}, there are ${daysUntilDate(
						toDate,
				  )} days left in this adventure`;

		return (
			<div
				style={{ display: this.props.isShowingMainPage ? 'block' : 'none' }}
				className="adventureMainPage"
			>
				<div className="mainPageHeader">
					<div onClick={this.props.goBack}>
						<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
					</div>
					<div className="userAreaTitle">
						<div>
							{adventure.emoji} {adventure.title}
						</div>
						<div
							className="travelDates"
							onClick={this.props.goToSelectTravelDates}
						>
							{fromDate} {` - `}
							{toDate}
						</div>
					</div>
					<div></div>
				</div>
				<hr />
				<Grow in={this.props.isShowingMainPage}>
					<div>
						<div className="userAreaContent" style={{ marginBottom: '30px' }}>
							<div className="tripAnnouncement">{message}</div>

							<div className="TripArea TripAreaBasic adventureLocations">
								<div className="tripAreaDesc">📍 Locations</div>
								<hr />
								<br />
								<div
									className={`scroll-arrow scroll-left ${
										displayLeftArrow ? 'selected' : ''
									}`}
									onClick={this.scrollToPreviousLocation}
								>
									<ArrowLeft></ArrowLeft>{' '}
								</div>
								<div
									className={`scroll-arrow scroll-right ${
										displayRightArrow ? 'selected' : ''
									}`}
									onClick={this.scrollToNextLocation}
								>
									<ArrowRight></ArrowRight>
								</div>
								{this.state.trips.length > 0 ? (
									<div>
										<div
											className="adventureLocationScroll"
											onScroll={this.handleScroll}
											ref={this.containerRef}
										>
											{this.state.trips.map((trip, index) => (
												<ScheduledTripsItem
													itemIndex={index}
													scheduledTripData={trip}
													goToShowTrip={this.props.goToShowTrip}
													key={index}
												></ScheduledTripsItem>
											))}
										</div>
										<div className="inBetweenMenu">
											<div>{/* <TurnRight></TurnRight> */}</div>
											<div
												style={{
													display:
														this.state.trips.length > 1 ? 'flex' : 'none',
													alignItems: 'center',
													justifyContent: 'center',
													backgroundColor: 'inherit',
												}}
											>
												{this.state.trips.map((trip, index) => {
													const fontSize =
														index == this.state.currentLocationItemIndex
															? '6px'
															: '5px';
													const fontColor =
														index == this.state.currentLocationItemIndex
															? 'var(--accentColorLight)'
															: 'var(--primaryBackgroundColorLight)';
													return (
														<div
															key={index}
															style={{
																fontSize: fontSize,
																margin: '2px',
																cursor: 'pointer',
															}}
															onClick={(e) => this.scrollToLocation(index)}
														>
															<Circle
																style={{ fontSize: fontSize, color: fontColor }}
															></Circle>
														</div>
													);
												})}
											</div>
											<div>{/* <TurnRight></TurnRight> */}</div>
										</div>

										<br />
									</div>
								) : (
									<div
										style={{
											marginTop: '45px',
											marginBottom: '70px',
											textAlign: 'center',
										}}
									>
										{this.state.tripsFetched ? (
											<div>
												<br />
												When you add locations, you'll be able to see them here.
												<br />
											</div>
										) : (
											<CircularProgress></CircularProgress>
										)}
									</div>
								)}
								<div
									className="mainBtn addLocationBtnYassified"
									onClick={this.props.addLocation}
								>
									<Add></Add> Add Location
								</div>
							</div>
							<div className="TripArea TripAreaBasic adventureLocations">
								<div
									className="tripAreaDesc"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<div>✍️ Check-In</div>
									<div
										className="viewCheckInHistory"
										onClick={this.props.adventureCheckInHistory}
									>
										View History
									</div>
								</div>
								<hr />
								<div
									className="mainBtn addLocationBtn"
									onClick={this.props.adventureCheckIn}
								>
									Adventure Check-In
								</div>
							</div>
						</div>

						<div className="TripArea">
							<Grow in={true} timeout={700}>
								<div className="tripOthers" style={{ marginTop: '30px' }}>
									<div
										className="tripOthersItem"
										onClick={this.props.deleteAdventure}
									>
										<div>
											<DeleteIcon fontSize="small"></DeleteIcon>
										</div>
										<div>Delete</div>
									</div>
									<div className="tripOthersItem">
										<div>
											<SettingsIcon fontSize="small"></SettingsIcon>
										</div>
										<div>Settings</div>
									</div>
									<div className="tripOthersItem">
										<div>
											<IosShareIcon fontSize="small"></IosShareIcon>
										</div>
										<div>Share</div>
									</div>
								</div>
							</Grow>
						</div>
						<hr />
						<div className="mainBtn" onClick={this.props.goBack}>
							<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
							{
								{
									null: 'Upcoming Adventures',
									scheduled: 'Upcoming Adventures',
									main_menu: 'Back to Main Menu',
									past_adventures: 'Explore Past Adventures',
								}[this.props.adventureComingFrom]
							}
						</div>
						<hr />
					</div>
				</Grow>
			</div>
		);
	}
}
