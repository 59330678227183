import React, { Component } from 'react';
import TripTransportationAreaNewItemArrival from './TripTransportationAreaNewItemArrival';
import TripTransportationAreaNewItemDeparture from './TripTransportationAreaNewItemDeparture';
import TripTransportationAreaNewItemType from './TripTransportationAreaNewItemType';
import TripTransportationNewItemDetails from './TripTransportationNewItemDetails';
import {
	militaryToStandardTime,
	transportationCategories,
	dashesDateFormatToObject,
	fromFulltimestampToDate,
	normalToMilitary,
} from './../../../../utils';
import { Grow } from '@mui/material';
import {
	ArrowBackIos,
	NorthEast,
	SouthEast,
	Category,
	Note,
} from '@mui/icons-material';
import TripTransportationAreaLoadingFromScreenshot from './TripTransportationAreaLoadingFromScreenshot';

export default class TripTransportationNewItem extends Component {
	constructor(props) {
		super(props);

		let existingItem = this.props.existingItem;

		if (existingItem != null) {
			this.state = {
				selectingTransportationType: false,
				selectingDepatureDetails: false,
				selectingArrivalDetails: false,
				selectingDetails: false,

				transportationType: transportationCategories[existingItem.type],
				name: existingItem.name,
				comments: existingItem.comments,
				confirmationCode: existingItem.confirmation_code,
				arrivalDetails: existingItem.arrival_details,
				departureDetails: {
					address: existingItem.departure_details.address,
					date: dashesDateFormatToObject(
						fromFulltimestampToDate(existingItem.departure_details.date),
					),
					time: normalToMilitary(existingItem.departure_details.time),
				},
				arrivalDetails: {
					address: existingItem.arrival_details.address,
					date: dashesDateFormatToObject(
						fromFulltimestampToDate(existingItem.arrival_details.date),
					),
					time: normalToMilitary(existingItem.arrival_details.time),
				},
			};
		} else {
			this.state = {
				selectingTransportationType: true,
				selectingDepatureDetails: false,
				selectingArrivalDetails: false,
				selectingDetails: false,

				transportationType: null,
				departureDetails: null,
				arrivalDetails: null,
				name: '',
				confirmationCode: '',
				comments: '',
			};
		}
	}

	saveTransportationItem = (
		departureDetails,
		arrivalDetails,
		transportationType,
		confirmationCode,
		name,
		comments,
		shouldGoToItem,
	) => {
		let formattedDepartureDate = `${departureDetails.date.year}-${departureDetails.date.month}-${departureDetails.date.day}`;
		let formattedArrivalDate = `${arrivalDetails.date.year}-${arrivalDetails.date.month}-${arrivalDetails.date.day}`;
		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('transportation_type', transportationType);
		formData.append('confirmation_code', confirmationCode);
		formData.append('name', name);
		formData.append('comments', comments);
		formData.append('departure_date', formattedDepartureDate);
		formData.append(
			'departure_time',
			militaryToStandardTime(departureDetails.time),
		);
		formData.append('departure_address', departureDetails.address);
		formData.append('arrival_date', formattedArrivalDate);
		formData.append(
			'arrival_time',
			militaryToStandardTime(arrivalDetails.time),
		);
		formData.append('arrival_address', arrivalDetails.address);

		fetch(`https://api.gallivibe.com/addLocationTransportationItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.addToTransportationItems(data.item);
					// Call goToItem only if shouldGoToItem is true
					if (shouldGoToItem) {
						this.props.goToItem(data.item);
					}
				}
			})
			.catch((error) => console.error(error));
	};

	updateTransportationItem = () => {
		let departureDetails = this.state.departureDetails;
		let arrivalDetails = this.state.arrivalDetails;
		let formattedDepartureDate = `${departureDetails.date.year}-${departureDetails.date.month}-${departureDetails.date.day}`;
		let formattedArrivalDate = `${arrivalDetails.date.year}-${arrivalDetails.date.month}-${arrivalDetails.date.day}`;

		const formData = new FormData();
		formData.append('token', this.props.token);
		formData.append('adventure_id', this.props.adventure.id);
		formData.append('trip_id', this.props.tripData.id);
		formData.append('item_id', this.props.existingItem.transportation_item_id);
		formData.append('transportation_type', this.state.transportationType.id);
		formData.append('confirmation_code', this.state.confirmationCode);
		formData.append('name', this.state.name);
		formData.append('comments', this.state.comments);
		formData.append('departure_date', formattedDepartureDate);
		formData.append(
			'departure_time',
			militaryToStandardTime(departureDetails.time),
		);
		formData.append('departure_address', departureDetails.address);
		formData.append('arrival_date', formattedArrivalDate);
		formData.append(
			'arrival_time',
			militaryToStandardTime(arrivalDetails.time),
		);
		formData.append('arrival_address', arrivalDetails.address);

		fetch(`https://api.gallivibe.com/updateLocationTransportationItem`, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					this.props.removeTransportationItems(data.item, () => {
						this.props.addToTransportationItems(data.item);
					});
					this.props.goToItem(data.item);
				}
			})
			.catch((error) => console.error(error));
	};

	saveNewTransportationItem = () => {
		this.saveTransportationItem(
			this.state.departureDetails,
			this.state.arrivalDetails,
			this.state.transportationType.id,
			this.state.confirmationCode,
			this.state.name,
			this.state.comments,
			true,
		);
	};

	updateTitle = (newTitle) => {
		this.setState({ title: newTitle });
	};

	render() {
		let canSave =
			this.state.departureDetails != null &&
			this.state.arrivalDetails != null &&
			this.state.transportationType != null &&
			this.state.name != '';
		return (
			<Grow in={true}>
				<div>
					<br />
					<div className="addingLodgingInfoTitle">
						<div
							onClick={() => {
								if (this.state.loadingFromScreenshot) {
									this.setState({ loadingFromScreenshot: false });
								} else {
									this.props.goBack();
								}
							}}
						>
							<div className="backArrowBtn">
								<ArrowBackIos fontSize="x-small"></ArrowBackIos>
							</div>
							{this.props.existingItem == null
								? 'New Transportation Item'
								: 'Editing Item'}
						</div>
						<div>
							{this.state.transportationType != null &&
								this.state.transportationType.id == 0 && (
									<div
										className="mainBtn"
										style={{
											fontSize: 'small',
											paddingTop: '0px',
											paddingBottom: '0px',
											marginTop: '0px',
											marginBottom: '0px',
											marginRight: '4px',
										}}
										onClick={() =>
											this.setState({
												loadingFromScreenshot: true,
											})
										}
									>
										📷 From Screenshot
									</div>
								)}
						</div>
					</div>
					<hr />
					<div className="addLodgingArea">
						<Grow in={!this.state.loadingFromScreenshot}>
							<div
								style={{
									display: !this.state.loadingFromScreenshot ? 'block' : 'none',
								}}
							>
								<div
									className={`newActivitySection ${
										this.state.selectingTransportationType
											? 'sectionSelected'
											: ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												selectingTransportationType:
													!this.state.selectingTransportationType,
												selectingDepatureDetails: false,
												selectingArrivalDetails: false,
												selectingDetails: false,
											})
										}
									>
										<div>
											<div>
												<Category fontSize="x-small"></Category>
											</div>
											<div>Transportation Type</div>
										</div>
										<div>
											{this.state.transportationType != null
												? `${this.state.transportationType.emoji} ${this.state.transportationType.name}`
												: ``}
										</div>
									</div>
									<div>
										<TripTransportationAreaNewItemType
											selectingTransportationType={
												true
												// this.state.selectingTransportationType
											}
											selectTransportationType={(type) =>
												this.setState({
													transportationType: type,
													selectingTransportationType: false,
													selectingDepatureDetails: true,
												})
											}
										></TripTransportationAreaNewItemType>
									</div>
								</div>
								<div
									className={`newActivitySection ${
										this.state.selectingDepatureDetails ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												selectingTransportationType: false,
												selectingDepatureDetails:
													!this.state.selectingDepatureDetails,
												selectingArrivalDetails: false,
												selectingDetails: false,
											})
										}
									>
										<div>
											<div>
												<NorthEast fontSize="x-small"></NorthEast>
											</div>
											<div>Departure</div>
										</div>
										<div>
											{this.state.departureDetails != null
												? `${this.state.departureDetails.date.month}/${
														this.state.departureDetails.date.day
												  } - ${militaryToStandardTime(
														this.state.departureDetails.time,
												  )}`
												: ``}
										</div>
									</div>
									<div>
										<TripTransportationAreaNewItemDeparture
											tripData={this.props.tripData}
											selectingDepatureDetails={
												true
												// this.state.selectingDepatureDetails
											}
											defaultValue={this.state.departureDetails}
											next={(departureDetails) =>
												this.setState({
													departureDetails: departureDetails,
												})
											}
										></TripTransportationAreaNewItemDeparture>
									</div>
								</div>{' '}
								<div
									className={`newActivitySection ${
										this.state.selectingArrivalDetails ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												selectingTransportationType: false,
												selectingDepatureDetails: false,
												selectingArrivalDetails:
													!this.state.selectingArrivalDetails,
												selectingDetails: false,
											})
										}
									>
										<div>
											<div>
												<SouthEast fontSize="x-small"></SouthEast>
											</div>
											<div>Arrival</div>
										</div>
										<div>
											{this.state.arrivalDetails != null
												? `${this.state.arrivalDetails.date.month}/${
														this.state.arrivalDetails.date.day
												  } - ${militaryToStandardTime(
														this.state.arrivalDetails.time,
												  )}`
												: ``}
										</div>
									</div>
									<div>
										<TripTransportationAreaNewItemArrival
											tripData={this.props.tripData}
											selectingArrivalDetails={
												true
												// this.state.selectingArrivalDetails
											}
											defaultValue={this.state.arrivalDetails}
											goBack={() => {
												this.setState({
													selectingDepatureDetails: true,
													selectingArrivalDetails: false,
												});
											}}
											next={(arrivalDetails) =>
												this.setState({
													arrivalDetails: arrivalDetails,
												})
											}
										></TripTransportationAreaNewItemArrival>
									</div>
								</div>
								<div
									className={`newActivitySection ${
										this.state.selectingDetails ? 'sectionSelected' : ''
									}`}
								>
									<div
										onClick={() =>
											this.setState({
												selectingTransportationType: false,
												selectingDepatureDetails: false,
												selectingArrivalDetails: false,
												selectingDetails: !this.state.selectingDetails,
											})
										}
									>
										<div>
											<div>
												<Note fontSize="x-small"></Note>
											</div>
											<div>Details</div>
										</div>
										<div>{this.state.name}</div>
									</div>
									<div>
										<TripTransportationNewItemDetails
											tripData={this.props.tripData}
											token={this.props.token}
											name={this.state.name}
											confirmationCode={this.state.confirmationCode}
											comments={this.state.comments}
											updateName={(updatedName) => {
												this.setState({ name: updatedName });
											}}
											updateConfirmationCode={(updatedConfirmationCode) => {
												this.setState({
													confirmationCode: updatedConfirmationCode,
												});
											}}
											updateComments={(updatedComments) => {
												this.setState({ comments: updatedComments });
											}}
											selectingDetails={
												true
												// this.state.selectingDetails
											}
											saveNewTransportationItem={this.saveNewTransportationItem}
										></TripTransportationNewItemDetails>
									</div>
								</div>
								<div
									className={`${
										canSave ? 'mainSubmitBtn' : 'mainBtn'
									} SaveItemBtn`}
									onClick={() => {
										if (canSave) {
											if (this.props.existingItem) {
												this.updateTransportationItem();
											} else {
												this.saveNewTransportationItem();
											}
										}
									}}
								>
									Save Item
								</div>
							</div>
						</Grow>
						<TripTransportationAreaLoadingFromScreenshot
							token={this.props.token}
							adventure={this.props.adventure}
							tripData={this.props.tripData}
							isLoadingFromScreenshot={this.state.loadingFromScreenshot}
							saveTransportationItem={this.saveTransportationItem}
							goToItems={this.props.goBack}
						></TripTransportationAreaLoadingFromScreenshot>
					</div>
				</div>
			</Grow>
		);
	}
}
