import './ToSAndPrivacyPolicy.css';
import React, { Component } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="ToSOrPrivacyPolicy">
        <b>Gallivibe, Inc - Privacy Policy</b>
        <br />
        <span className="lastUpdate">Last Updated: August 7, 2023</span>
        <hr />
        <p>
          <div>
            Welcome to Gallivibe!
            <br />
            <br />
            Your trust is our utmost priority. This Privacy Policy elucidates
            how we manage your personal data, the precautions we implement to
            secure it, and your entitlements concerning the data you impart to
            us. We encourage you to peruse this policy comprehensively, and
            should any queries arise, our dedicated Data Protection Officer
            remains at your disposal.
          </div>
        </p>{' '}
        <p>
          <h4>1. Data Collection and Usage:</h4>
          <div>
            <b>1.1 What We Collect:</b> Gallivibe collects data voluntarily
            provided by you, which may encompass: - Personal details: Name,
            email address - Travel narratives: Destinations, itineraries, and
            experiences - Technical data: IP addresses, device and browser
            information, cookies for site optimization and functionality
            <br />
            <br />
            <b>1.2 Usage Objectives:</b> We utilize your data to:{' '}
            <ul style={{ textAlign: 'left' }}>
              <li>Enrich your travel planning endeavors</li>
              <li>Amplify the performance of our platform</li>
              <li>Offer bespoke customer support</li>
              <li>Undertake system enhancements and updates</li>
              <li>Augment user experience</li>
              <li>Engage in communication about our services</li>
            </ul>
            <br />
            <b>1.3 Third-Party Engagements:</b> We occasionally share data with
            third-party service providers, exclusively to bolster your user
            experience. A comprehensive list of these providers, along with
            their data practices, is available upon request. ---
          </div>
        </p>
        <p>
          <h4>2. Data Protection and Security:</h4>
          <div>
            <b>2.1 Guardianship Measures:</b> We deploy avant-garde security
            protocols, including encryption techniques, rigorous security
            audits, and contemporary firewall defenses.
            <br />
            <br />
            <b>2.2 No Selling of Data:</b> We vow never to monetize your
            personal data by selling it to third parties.
            <br />
            <br />
            <b>2.3 Data Tenure:</b> Data persistence aligns with your account's
            active status. Post account termination, retention occurs only when
            mandated by prevailing legal statutes.
          </div>
        </p>
        <p>
          <h4>3. Your Rights:</h4>
          <div>
            <b>3.1 Ownership:</b> You invariably retain the prerogative to
            access, amend, or expunge your information.
            <br />
            <br />
            <b>3.2 Data Portability:</b> We extend tools to streamline data
            migration to disparate platforms or services.
            <br />
            <br />
            <b>3.3 Account Termination:</b> Dissolving your Gallivibe account
            results in comprehensive erasure of your personal data, barring
            legally mandated retention. For specific data access or
            modifications, contact us via email.
          </div>
        </p>
        <p>
          <h4>4. Limitation of Liability:</h4>
          <div>
            While Gallivibe employs stringent measures to safeguard your data,
            no system is invulnerable. In case of a breach, affected users will
            be notified within the legally required timeframe. However,
            Gallivibe's liability may be limited in certain scenarios; consult
            our Terms of Service for details.
          </div>
        </p>
        <p>
          <h4>5. Policy Evolution:</h4>
          <div>
            Modifications to this Privacy Policy will be communicated either via
            the email affixed to your account or through our platform. Your
            continued engagement symbolizes concurrence with the updated policy.
          </div>
        </p>
        <p>
          <h4>6. Engage with Us:</h4>
          <div>
            {' '}
            For inquiries concerning our privacy undertakings or to reach our
            Data Protection Officer, connect with us at{' '}
            <a href="mailto:hello@gallivibe.com">hello@gallivibe.com</a>.
          </div>
        </p>
        <br />
        <hr />
        <div className="mainBtn" onClick={this.props.goBack}>
          <ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon> Go Back
        </div>
      </div>
    );
  }
}
