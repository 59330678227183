import { ArrowBackIos } from '@mui/icons-material';
import { Alert, Collapse, Grow } from '@mui/material';
import React, { Component } from 'react';
import EmojiPicker from '../TripPlanning/SmallerComponents/EmojiPicker';

export default class PlanAdventureEmojiAndName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEmojiPicker: false,
			showTitleRequirementsWarning: false,
			hasExited: true,
			shakeTitle: false,
			selectedEmoji: '⛰️',
			selectedTitle: '',
		};
	}

	continue = () => {
		if (
			this.state.selectedTitle.length < 2 ||
			this.state.selectedTitle.length > 100
		) {
			this.setState({
				showTitleRequirementsWarning: true,
				hasExited: false,
				shakeTitle: true,
			});
			setTimeout(
				function () {
					this.setState({ showTitleRequirementsWarning: false });
				}.bind(this),
				4000,
			);
			setTimeout(
				function () {
					this.setState({ shakeTitle: false });
				}.bind(this),
				500,
			);
			return;
		}

		this.props.goToSelectDates(
			this.state.selectedEmoji,
			this.state.selectedTitle,
		);
	};

	toggleEmojiPicker = () => {
		this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
	};

	render() {
		return (
			<Grow in={this.props.shouldShowSelectEmojiAndName}>
				<div
					style={{
						display: this.props.shouldShowSelectEmojiAndName ? 'block' : 'none',
					}}
				>
					<div className="mainPageHeader">
						<div onClick={this.props.goBack}>
							<ArrowBackIos></ArrowBackIos>{' '}
						</div>
						<div className="userAreaTitle">👨‍💻 Creating Adventure</div>
						<div></div>
					</div>

					<hr />
					<div className="userAreaContent">
						<center>Let's pick an emoji and name for your adventure</center>
						<br />
						<Collapse in={this.state.showTitleRequirementsWarning}>
							<div
								style={{
									display:
										this.state.showTitleRequirementsWarning ||
										!this.state.hasExited
											? 'block'
											: 'none',
								}}
							>
								<Alert severity="error" sx={{ borderRadius: 2 }}>
									The title should be between <b>2 and 100</b> characters long.
								</Alert>
							</div>
						</Collapse>
						<br />
						<div style={{ display: 'flex' }}>
							<button onClick={this.toggleEmojiPicker} className="emojiBtn">
								{this.state.selectedEmoji}
							</button>
							<input
								type="text"
								className={`mainTxtInput ${
									this.state.shakeTitle ? 'shake' : ''
								}`}
								placeholder="My New Adventure..."
								value={this.state.selectedTitle}
								onChange={(e) =>
									this.setState({ selectedTitle: e.target.value })
								}
								onKeyDown={(event) => {
									if (event.key === 'Enter' && this.state.password !== '')
										this.continue();
								}}
							/>
						</div>
						<Grow in={this.state.showEmojiPicker}>
							<div
								style={{
									display: this.state.showEmojiPicker ? 'block' : 'none',
								}}
							>
								<br />
								<center>
									<EmojiPicker
										onSelectEmoji={(emoji) => {
											this.setState({
												showEmojiPicker: false,
												selectedEmoji: emoji,
											});
										}}
									></EmojiPicker>
								</center>
							</div>
						</Grow>
						<br />
						<div
							className="mainSubmitBtn"
							style={{ textAlign: 'center', width: '95%' }}
							onClick={this.continue}
						>
							Continue
						</div>
					</div>
					<hr />
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIos fontSize="smaller"></ArrowBackIos> Back
					</div>
					<hr />
				</div>
			</Grow>
		);
	}
}
