import './AuthenticateUserFlow.css';
import React, { Component } from 'react';
import CodeVerification from './CodeVerification';
import CreateAccount from './CreateAccount';
import PhoneNumberNotInvited from './PhoneNumberNotInvited';
import LandingPhoneNumberCheck from './LandingPhoneNumberCheck';
import SigningIn from './SigningIn';
import { formatPhoneNumber } from './../utils.js';

export default class AuthenticatedUserFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Display state
      pageMessage: '🏝️ Welcome to Gallivibe',

      // Account state
      phoneNumber: '',
      verificationCode: '',

      // Flow Page State
      isLandingPhoneNumberCheck: true,
      isNotInvitedMessage: false,
      isVerifyingCode: false,
      isCreatingAccount: false,
      isSigningIn: false,
    };
  }

  updateMessage = (theMessage) => {
    this.setState({ pageMessage: theMessage });
  };

  updateVerificationCode = (verificationCode) => {
    this.setState({ verificationCode: verificationCode });
  };

  updatePhoneNumber = (number) => {
    this.setState({ phoneNumber: formatPhoneNumber(number) });
  };

  goToNotInvitedYet = () => {
    this.setState({
      isLandingPhoneNumberCheck: false,
      isNotInvitedMessage: true,
    });
  };

  goToCodeVerification = () => {
    this.setState({
      isLandingPhoneNumberCheck: false,
      isVerifyingCode: true,
    });
  };

  goToSigningIn = () => {
    this.setState({
      isCreatingAccount: false,
      isLandingPhoneNumberCheck: false,
      isSigningIn: true,
    });
  };

  goToAccountCreation = () => {
    this.setState({
      isVerifyingCode: false,
      isCreatingAccount: true,
    });
  };

  updateAuthStatusFuncLocal = (status, token) => {
    this.setState({
      phoneNumber: '',
      pageMessage: '🏝️ Welcome to Gallivibe',
      isLandingPhoneNumberCheck: true,
      isSigningIn: false,
    });
    this.props.updateAuthenticatedStatusFunc(status, token);
  };

  render() {
    return (
      <div
        style={{ display: this.props.isUserAuthenticated ? 'none' : 'block' }}
      >
        <PhoneNumberNotInvited
          isNotInvited={this.state.isNotInvitedMessage}
        ></PhoneNumberNotInvited>

        <div
          style={{ display: this.state.isNotInvitedMessage ? 'none' : 'block' }}
        >
          <div className="AuthPageMessage">{this.state.pageMessage}</div>

          <div className="authenticateUserFlowContent">
            <LandingPhoneNumberCheck
              isLandingPhoneNumberCheck={this.state.isLandingPhoneNumberCheck}
              phoneNumber={this.state.phoneNumber}
              updateMessage={this.updateMessage}
              updatePhoneNumber={this.updatePhoneNumber}
              goToCodeVerification={this.goToCodeVerification}
              goToSigningIn={this.goToSigningIn}
              goToNotInvitedYet={this.goToNotInvitedYet}
            ></LandingPhoneNumberCheck>

            <CodeVerification
              isVerifyingCode={this.state.isVerifyingCode}
              phoneNumber={this.state.phoneNumber}
              updateMessage={this.updateMessage}
              updateVerificationCode={this.updateVerificationCode}
              goToAccountCreation={this.goToAccountCreation}
            ></CodeVerification>

            <CreateAccount
              isCreatingAccount={this.state.isCreatingAccount}
              phoneNumber={this.state.phoneNumber}
              phoneCode={this.state.verificationCode}
              updateMessage={this.updateMessage}
              goToSigningIn={this.goToSigningIn}
            ></CreateAccount>

            <SigningIn
              isSigningIn={this.state.isSigningIn}
              phoneNumber={this.state.phoneNumber}
              updateMessage={this.updateMessage}
              updatePhoneNumber={this.updatePhoneNumber}
              updateAuthenticatedStatusFunc={this.updateAuthStatusFuncLocal}
              updateUserObject={this.props.updateUserObject}
            ></SigningIn>
          </div>
        </div>
      </div>
    );
  }
}
