import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { transformDateFormat } from './../../utils.js';
import DeleteTrip from './DeleteTrip.js';
import TripMainPage from './TripMainPage.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ArrowForwardIos } from '@mui/icons-material';
import TripSettings from './TripSettings.js';

export default class Trip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeletingTrip: false,
			isViewingSettings: false,

			setting_AINextSteps: this.props.tripData.settings.AINextSteps,
			setting_Transportation: this.props.tripData.settings.Transportation,
			setting_Lodging: this.props.tripData.settings.Lodging,
		};
	}

	backFromViewingSettings = () => {
		let storedTripSettings = this.props.tripData.settings;
		let updatedSettings = {};

		if (storedTripSettings.AINextSteps != this.state.setting_AINextSteps) {
			updatedSettings['AINextSteps'] = this.state.setting_AINextSteps;
		}

		if (storedTripSettings.Lodging != this.state.setting_Lodging) {
			updatedSettings['Lodging'] = this.state.setting_Lodging;
		}

		if (
			storedTripSettings.Transportation != this.state.setting_Transportation
		) {
			updatedSettings['Transportation'] = this.state.setting_Transportation;
		}

		if (Object.keys(updatedSettings).length > 0) {
			const formData = new FormData();
			formData.append('token', this.props.token);
			formData.append('adventure_id', this.props.adventure.id);
			formData.append('trip_id', this.props.tripData.id);

			formData.append('newSettings', JSON.stringify(updatedSettings));

			fetch('https://api.gallivibe.com/setLocationSettings', {
				method: 'POST',
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					//yas
				})
				.catch((error) => {
					console.error('Error occurred:', error);
				});
		}

		this.setState({ isViewingSettings: false });
	};

	render() {
		let settings = {
			AINextSteps: this.state.setting_AINextSteps,
			Lodging: this.state.setting_Lodging,
			Transportation: this.state.setting_Transportation,
		};
		let adventure = this.props.adventure;
		let destination = this.props.tripData.destination;
		let fromDate = transformDateFormat(this.props.tripData.from_date);
		let toDate = transformDateFormat(this.props.tripData.to_date);

		return (
			<div>
				<div className="mainPageHeader">
					<div
						onClick={
							!this.state.isViewingSettings
								? this.props.goBack
								: this.backFromViewingSettings
						}
					>
						<ArrowBackIosIcon></ArrowBackIosIcon>{' '}
					</div>
					<div className="userAreaTitle">
						{!this.state.isViewingSettings && (
							<div className="tripHeader">
								<div className="tripAdventureTitle" onClick={this.props.goBack}>
									{adventure.emoji} {adventure.title}
								</div>
								<div>
									<ArrowForwardIos
										style={{
											fontSize: 'small',
										}}
									></ArrowForwardIos>
								</div>
								<div className="tripHeaderLocation">
									{destination.emoji} {destination.name}
								</div>
							</div>
						)}

						{this.state.isViewingSettings && (
							<div className="tripHeader">
								<div
									className="tripAdventureTitle"
									onClick={this.backFromViewingSettings}
								>
									{destination.emoji} {destination.name}
								</div>
								<div>
									<ArrowForwardIos
										style={{
											fontSize: 'small',
										}}
									></ArrowForwardIos>
								</div>
								<div className="tripHeaderLocation">⚙️ Settings</div>
							</div>
						)}

						<div
							className="travelDates"
							onClick={this.props.goToSelectTravelDates}
						>
							{fromDate} {` - `}
							{toDate}
						</div>
					</div>
					<div></div>
				</div>
				<hr />
				<div className="userAreaContent">
					<TripMainPage
						isDeletingTrip={
							this.state.isDeletingTrip || this.state.isViewingSettings
						}
						tripData={this.props.tripData}
						settings={settings}
						token={this.props.token}
						adventure={this.props.adventure}
						deleteTrip={(e) => {
							this.setState({ isDeletingTrip: true });
						}}
						viewTripSettings={(e) => {
							this.setState({ isViewingSettings: true });
						}}
						user={this.props.user}
						itinerary={this.props.itinerary}
						lodgingItems={this.props.lodgingItems}
						transportationItems={this.props.transportationItems}
						aiSuggestion={this.props.aiSuggestion}
						addToItinerary={this.props.addToItinerary}
						removeFromItinerary={this.props.removeFromItinerary}
						addToLodgingItems={this.props.addToLodgingItems}
						removeFromLodgingItems={this.props.removeFromLodgingItems}
						addToTransportationItems={this.props.addToTransportationItems}
						removeTransportationItems={this.props.removeTransportationItems}
					></TripMainPage>

					{this.state.isDeletingTrip && (
						<Grow in={this.state.isDeletingTrip}>
							<div>
								<DeleteTrip
									tripData={this.props.tripData}
									token={this.props.token}
									adventure={this.props.adventure}
									goToScheduledTrips={this.props.goToScheduledTrips}
									goBack={this.props.goBack}
									doNotDeleteTrip={(e) => {
										this.setState({ isDeletingTrip: false });
									}}
								></DeleteTrip>
							</div>
						</Grow>
					)}
					{this.state.isViewingSettings && (
						<TripSettings
							settings={settings}
							toggleAINextSteps={(newVal) =>
								this.setState({
									setting_AINextSteps: !this.state.setting_AINextSteps,
								})
							}
							toggleTransportation={(newVal) =>
								this.setState({
									setting_Transportation: !this.state.setting_Transportation,
								})
							}
							toggleLodging={(newVal) =>
								this.setState({
									setting_Lodging: !this.state.setting_Lodging,
								})
							}
							isViewingSettings={this.state.isViewingSettings}
						></TripSettings>
					)}
				</div>
				<hr />
				{!this.state.isViewingSettings && (
					<div className="mainBtn" onClick={this.props.goBack}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
						{`${this.props.adventure.emoji} ${this.props.adventure.title}`}
					</div>
				)}
				{this.state.isViewingSettings && (
					<div className="mainBtn" onClick={this.backFromViewingSettings}>
						<ArrowBackIosIcon fontSize="smaller"></ArrowBackIosIcon>{' '}
						{destination.emoji} {destination.name}
					</div>
				)}

				<hr />
			</div>
		);
	}
}
