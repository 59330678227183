import { Grow } from '@mui/material';
import React, { Component } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NewActivityTimeSelectionSlider from './NewActivityTimeSelectionSlider';

export default class NewActivityTimeSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shake: false,
		};
	}

	setEndTime = (newEndTime) => {
		let startTime = new Date(`2000-01-01 ${this.props.startTime}`);
		let endTime = new Date(`2000-01-01 ${newEndTime}`);

		if (endTime <= startTime) {
			this.props.selectTime(newEndTime, newEndTime);
		} else {
			this.props.selectTime(this.props.startTime, newEndTime);
		}
	};

	setStartTime = (newStartTime) => {
		let startTime = new Date(`2000-01-01 ${newStartTime}`);
		let endTime = new Date(`2000-01-01 ${this.props.endTime}`);

		if (startTime >= endTime) {
			this.props.selectTime(newStartTime, newStartTime);
		} else {
			this.props.selectTime(newStartTime, this.props.endTime);
		}
	};

	render() {
		return (
			<div className="newItineraryItem">
				<div
					className={`newItineraryItemForm ${this.state.shake ? 'shake' : ''}`}
				>
					<input
						type="time"
						step="900"
						onChange={(e) => {
							this.setStartTime(e.target.value);
						}}
						value={this.props.startTime}
					/>
					<ArrowForwardIcon fontSize="smaller"></ArrowForwardIcon>
					<input
						type="time"
						step="900"
						onChange={(e) => {
							this.setEndTime(e.target.value);
						}}
						min={this.props.startTime}
						value={this.props.endTime}
					/>
				</div>
				<center>
					<div
						style={{
							width: '21.5em',
							maxWidth: '100%',
							textAlign: 'left',
							display: 'relative',
							zindex: '100',
						}}
					>
						<NewActivityTimeSelectionSlider
							startTime={this.props.startTime}
							endTime={this.props.endTime}
							onDurationChange={this.setEndTime}
						></NewActivityTimeSelectionSlider>
					</div>
				</center>
			</div>
		);
	}
}
