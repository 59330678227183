import './ScheduledTripsItem.css';
import { Grow } from '@mui/material';
import React, { Component } from 'react';
import { transformDateFormat, daysUntilDate } from './../../../utils.js';
import { ArrowForwardIos } from '@mui/icons-material';

export default class ScheduledTripsItem extends Component {
	constructor(props) {
		super(props);
		this.state = { isClicked: false };
	}

	render() {
		let destination = this.props.scheduledTripData.destination;
		let fromDate = transformDateFormat(this.props.scheduledTripData.from_date);
		let toDate = transformDateFormat(this.props.scheduledTripData.to_date);
		return (
			<Grow in={true} timeout={100 + this.props.itemIndex * 250}>
				<div id={`scheduledTripItemInRoll${this.props.itemIndex}`}>
					<div
						className={`scheduledTripsItem ${
							this.state.isClicked ? 'clicked' : ''
						}`}
						onMouseDown={(e) => {
							this.setState({ isClicked: true });
						}}
						onMouseOut={(e) => {
							this.setState({ isClicked: false });
						}}
						onTouchStart={(e) => {
							this.setState({ isClicked: true });
						}}
						onTouchEnd={(e) => {
							this.setState({ isClicked: false });
						}}
						onClick={(e) => {
							this.props.goToShowTrip(this.props.scheduledTripData);
						}}
					>
						<div className="scheduledTripsItemEmoji">{destination.emoji}</div>
						<div className="scheduledTripsItemText">
							<div className="scheduledTripsItemDestination">
								{destination.name}
							</div>
							<div className="scheduledTripsItemDates">{`${fromDate} - ${toDate}`}</div>
						</div>
						<div></div>
						<div className="scheduledTripsItemTimeLeft">
							{daysUntilDate(fromDate) > 0 ? (
								<div>T - {daysUntilDate(fromDate)} Days</div>
							) : (
								<div></div>
							)}
						</div>
						<div>
							<ArrowForwardIos fontSize="8px"></ArrowForwardIos>
						</div>
					</div>
				</div>
			</Grow>
		);
	}
}
