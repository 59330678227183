import React, { Component } from 'react';
import ItineraryDailyDisplayRightColumnDelete from './ItineraryDailyDisplayRightColumnDelete';
import ItineraryDailyDisplayRightColumnMain from './ItineraryDailyDisplayRightColumnMain';

export default class ItineraryDailyDisplayRightColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeleting: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.selected == null &&
				prevProps.selected != this.props.selected) ||
			prevProps.dayItems.length != this.props.dayItems.length
		) {
			this.setState({ isDeleting: false });
		}
	}

	render() {
		let viewingItem = this.props.viewingItem;

		if (this.props.dayItems.length == 0) {
			return (
				<div className="rightColumn">
					<div className="noItineraryItemsForDay">No itinerary items</div>
				</div>
			);
		}

		viewingItem =
			this.props.dayItems[
				this.props.viewingItem == null ? 0 : this.props.viewingItem
			];

		return (
			<div className="rightColumn">
				<div className="eventTitle">
					{viewingItem.activity.emoji} {viewingItem.activity.name}
				</div>
				<hr />
				<ItineraryDailyDisplayRightColumnMain
					dayItems={this.props.dayItems}
					viewingItem={viewingItem}
					isDeleting={this.state.isDeleting}
					deleteItem={(e) => {
						this.setState({ isDeleting: true });
					}}
					modifyActivity={this.props.modifyActivity}
				></ItineraryDailyDisplayRightColumnMain>
				{this.state.isDeleting && (
					<ItineraryDailyDisplayRightColumnDelete
						isDeleting={this.state.isDeleting}
						viewingItem={viewingItem}
						selected={this.props.selected}
						backToItems={(e) => {
							this.setState({ isDeleting: false });
						}}
						removeFromItinerary={this.props.removeFromItinerary}
					></ItineraryDailyDisplayRightColumnDelete>
				)}
			</div>
		);
	}
}
