// LinearProgressWithLabel.jsx
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

class LinearProgressWithLabel extends React.Component {
	render() {
		const { value } = this.props;
		return (
			<Box display="flex" alignItems="center">
				<Box width="100%" mr={1}>
					<LinearProgress
						variant="determinate"
						value={value}
						sx={{
							backgroundColor: 'var(--lowkeyBtnColorLight)',
							'& .MuiLinearProgress-bar': {
								backgroundColor: 'var(--accentColorLight)',
							},
						}}
					/>
				</Box>
				<Box minWidth={35}>
					<Typography variant="body2" color="textSecondary">
						{`${Math.round(value)}%`}
					</Typography>
				</Box>
			</Box>
		);
	}
}

export default LinearProgressWithLabel;
