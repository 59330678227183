import React, { Component } from 'react';
import './../../../ColorPalette.css';
import './EmojiPicker.css';
import Picker from '@emoji-mart/react';

export default class EmojiPicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedEmoji: null,
		};
	}

	handleSelect(emoji) {
		this.setState({ selectedEmoji: emoji.native });
		if (this.props.onSelectEmoji !== null) {
			this.props.onSelectEmoji(emoji.native);
		}
	}

	render() {
		return (
			<div className="emoji-picker">
				<Picker
					onEmojiSelect={(emoji) => this.handleSelect(emoji)}
					perLine="9"
					emojiSize="30"
					theme={this.props.themeMode}
				></Picker>
			</div>
		);
	}
}
