import React, { Component } from 'react';
import NewActivityTypeOption from './NewActivityTypeOption';
import { militaryToStandardTime } from './../../../../utils';
import { CircularProgress, Grow } from '@mui/material';
import { Add, ArrowBackIos } from '@mui/icons-material';
import EmojiPicker from '../../../TripPlanning/SmallerComponents/EmojiPicker';

export default class NewActivitySelectActivityType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: '',
			selectedActivity: null,
			shake: false,
			addingCustomActivity: false,
			customActivity: '',
			selectedEmoji: '🏃',
			showEmojiPicker: false,
		};
	}

	selectType = () => {
		if (this.state.selectedActivity === null) {
			this.setState({ shake: true });
			setTimeout(
				function () {
					this.setState({ shake: false });
				}.bind(this),
				500,
			);

			return;
		}

		let actualActivity = this.props.activities[this.state.selectedActivity];
		this.props.selectActivity(this.state.selectedActivity);
		this.props.setActivity(actualActivity.activity, actualActivity.emoji);
	};

	render() {
		let filteredOptions = this.props.activities.filter(
			(option) =>
				option.activity
					.toLowerCase()
					.includes(this.state.searchTerm.toLowerCase()) ||
				option.id == this.state.selectedActivity,
		);

		return this.state.addingCustomActivity ? (
			<Grow in={this.state.addingCustomActivity}>
				<div className="newItineraryItem">
					<div
						onClick={() => this.setState({ addingCustomActivity: false })}
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingTop: '5px',
							paddingBottom: '5px',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<ArrowBackIos fontSize="x-small"></ArrowBackIos>{' '}
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							Custom Activity Type
						</div>
					</div>
					<hr />
					<br />
					<div style={{ display: 'flex', height: '50px' }}>
						<button
							onClick={() =>
								this.setState({ showEmojiPicker: !this.state.showEmojiPicker })
							}
							className="emojiBtn"
							style={{ height: '100%' }}
						>
							{this.state.selectedEmoji}
						</button>
						<input
							type="text"
							className={`mainTxtInput ${this.state.shakeTitle ? 'shake' : ''}`}
							placeholder="Activity name..."
							value={this.state.customActivity}
							onChange={(e) =>
								this.setState({ customActivity: e.target.value }, () =>
									this.props.setActivity(
										this.state.customActivity,
										this.state.selectedEmoji,
									),
								)
							}
							onKeyDown={(event) => {
								if (event.key === 'Enter' && this.state.password !== '')
									this.continue();
							}}
							style={{ height: '25px' }}
						/>
					</div>
					<br />
					<Grow in={this.state.showEmojiPicker}>
						<div
							style={{
								display: this.state.showEmojiPicker ? 'block' : 'none',
							}}
						>
							<EmojiPicker
								onSelectEmoji={(emoji) => {
									this.setState(
										{
											showEmojiPicker: false,
											selectedEmoji: emoji,
										},
										() =>
											this.props.setActivity(
												this.state.customActivity,
												this.state.selectedEmoji,
											),
									);
								}}
							></EmojiPicker>
						</div>
					</Grow>
				</div>
			</Grow>
		) : (
			<div className="newItineraryItem">
				<div style={{ width: '100%' }}>
					<input
						type="text"
						placeholder="Search activity type..."
						className="mainTxtInput"
						value={this.state.searchTerm}
						onChange={(event) =>
							this.setState({ searchTerm: event.target.value })
						}
						style={{ marginBottom: '0px' }}
					/>
					<hr />
					<div
						className={`activitySelectionContainer ${
							this.state.shake ? 'shake' : ''
						}`}
					>
						{!this.props.activitiesFetched ? (
							<div style={{ width: '100%' }}>
								<br />
								<br />
								<center>
									<CircularProgress></CircularProgress>
								</center>
								<br />
								<br />
							</div>
						) : filteredOptions.length ? (
							<div className="activityContainer">
								<NewActivityTypeOption
									activityIndex={1}
									activity={{
										emoji: <Add fontSize="x-small"></Add>,
										activity: 'Custom',
										id: 99,
									}}
									selected={false}
									selectActivity={() => {
										this.setState({ addingCustomActivity: true });
									}}
								></NewActivityTypeOption>
								{filteredOptions.map((activity, index) => (
									<NewActivityTypeOption
										key={index}
										activityIndex={index}
										activity={activity}
										selected={this.props.activityName === activity.activity}
										selectActivity={(activityID) => {
											this.setState(
												{
													selectedActivity:
														activityID === this.state.selectedActivity
															? null
															: activityID,
												},
												() => this.selectType(),
											);
										}}
									></NewActivityTypeOption>
								))}
							</div>
						) : (
							<div
								style={{
									textAlign: 'center',
									margin: '40px',
									width: '100%',
								}}
							>
								😔 Didn't find that activity...
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
